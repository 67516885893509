@charset "UTF-8";@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap"); /*!
 * Bootstrap  v5.3.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,

[data-bs-theme=light] {
    --bs-blue: #5D87FF;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #FA896B;
    --bs-orange: #fd7e14;
    --bs-yellow: #FFAE1F;
    --bs-green: #13DEB9;
    --bs-teal: #20c997;
    --bs-cyan: #539BFF;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #2A3547;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #F6F9FC;
    --bs-gray-200: #EAEFF4;
    --bs-gray-300: #DFE5EF;
    --bs-gray-400: #7C8FAC;
    --bs-gray-500: #5A6A85;
    --bs-gray-600: #2A3547;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #5D87FF;
    --bs-secondary: #49BEFF;
    --bs-success: #13DEB9;
    --bs-info: #539BFF;
    --bs-warning: #FFAE1F;
    --bs-danger: #FA896B;
    --bs-light: #F6F9FC;
    --bs-dark: #2A3547;
    --bs-muted: #5A6A85;
    --bs-indigo: #6610f2;
    --bs-light-primary: #ECF2FF;
    --bs-light-secondary: #E8F7FF;
    --bs-light-info: #EBF3FE;
    --bs-light-success: #E6FFFA;
    --bs-light-warning: #FEF5E5;
    --bs-light-danger: #FBF2EF;
    --bs-light-indigo: #EBF3FE;
    --bs-dark-light: #2A3547;
    --bs-light-gray: #F6F9FC;
    --bs-primary-rgb: 93,135,255;
    --bs-secondary-rgb: 73,190,255;
    --bs-success-rgb: 19,222,185;
    --bs-info-rgb: 83,155,255;
    --bs-warning-rgb: 255,174,31;
    --bs-danger-rgb: 250,137,107;
    --bs-light-rgb: 246,249,252;
    --bs-dark-rgb: 42,53,71;
    --bs-muted-rgb: 90,106,133;
    --bs-indigo-rgb: 102,16,242;
    --bs-light-primary-rgb: 236,242,255;
    --bs-light-secondary-rgb: 232,247,255;
    --bs-light-info-rgb: 235,243,254;
    --bs-light-success-rgb: 230,255,250;
    --bs-light-warning-rgb: 254,245,229;
    --bs-light-danger-rgb: 251,242,239;
    --bs-light-indigo-rgb: 235,243,254;
    --bs-dark-light-rgb: 42,53,71;
    --bs-light-gray-rgb: 246,249,252;
    --bs-primary-text: #4a6ccc;
    --bs-secondary-text: #2A3547;
    --bs-success-text: #0fb294;
    --bs-info-text: #325d99;
    --bs-warning-text: #996813;
    --bs-danger-text: #c86e56;
    --bs-light-text: #2A3547;
    --bs-dark-text: #495057;
    --bs-primary-bg-subtle: #dfe7ff;
    --bs-secondary-bg-subtle: #F6F9FC;
    --bs-success-bg-subtle: #d0f8f1;
    --bs-info-bg-subtle: #ddebff;
    --bs-warning-bg-subtle: #ffefd2;
    --bs-danger-bg-subtle: #fee7e1;
    --bs-light-bg-subtle: #fbfcfe;
    --bs-dark-bg-subtle: #7C8FAC;
    --bs-primary-border-subtle: #becfff;
    --bs-secondary-border-subtle: #EAEFF4;
    --bs-success-border-subtle: #a1f2e3;
    --bs-info-border-subtle: #bad7ff;
    --bs-warning-border-subtle: #ffdfa5;
    --bs-danger-border-subtle: #fdd0c4;
    --bs-light-border-subtle: #EAEFF4;
    --bs-dark-border-subtle: #5A6A85;
    --bs-white-rgb: 255,255,255;
    --bs-black-rgb: 0,0,0;
    --bs-body-color-rgb: 90,106,133;
    --bs-body-bg-rgb: 255,255,255;
    --bs-font-sans-serif: "Plus Jakarta Sans",sans-serif;
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg,rgba(255,255,255,0.15),rgba(255,255,255,0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 0.875rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #5A6A85;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0,0,0;
    --bs-secondary-color: rgba(90,106,133,0.75);
    --bs-secondary-color-rgb: 90,106,133;
    --bs-secondary-bg: #EAEFF4;
    --bs-secondary-bg-rgb: 234,239,244;
    --bs-tertiary-color: rgba(90,106,133,0.5);
    --bs-tertiary-color-rgb: 90,106,133;
    --bs-tertiary-bg: #F6F9FC;
    --bs-tertiary-bg-rgb: 246,249,252;
    --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255,255,255;
    --bs-heading-color: #2A3547;
    --bs-link-color: #5D87FF;
    --bs-link-color-rgb: 93,135,255;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #4a6ccc;
    --bs-link-hover-color-rgb: 74,108,204;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #ffefd2;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #ebf1f6;
    --bs-border-color-translucent: rgba(0,0,0,0.175);
    --bs-border-radius: 7px;
    --bs-border-radius-sm: 5px;
    --bs-border-radius-lg: 15px;
    --bs-border-radius-xl: 12px;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb),0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb),0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb),0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb),0.075);
    --bs-emphasis-color: #000;
    --bs-form-control-bg: var(--bs-body-bg);
    --bs-form-control-disabled-bg: var(--bs-secondary-bg);
    --bs-highlight-bg: #ffefd2;
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px
}

[data-bs-theme=dark] {
    --bs-body-color: #5A6A85;
    --bs-body-color-rgb: 90,106,133;
    --bs-body-bg: #212529;
    --bs-body-bg-rgb: 33,37,41;
    --bs-emphasis-color: #F6F9FC;
    --bs-emphasis-color-rgb: 246,249,252;
    --bs-secondary-color: rgba(90,106,133,0.75);
    --bs-secondary-color-rgb: 90,106,133;
    --bs-secondary-bg: #343a40;
    --bs-secondary-bg-rgb: 52,58,64;
    --bs-tertiary-color: rgba(90,106,133,0.5);
    --bs-tertiary-color-rgb: 90,106,133;
    --bs-tertiary-bg: #2b3035;
    --bs-tertiary-bg-rgb: 43,48,53;
    --bs-emphasis-color: #fff;
    --bs-primary-text: #9eb7ff;
    --bs-secondary-text: #DFE5EF;
    --bs-success-text: #71ebd5;
    --bs-info-text: #98c3ff;
    --bs-warning-text: #ffce79;
    --bs-danger-text: #fcb8a6;
    --bs-light-text: #F6F9FC;
    --bs-dark-text: #DFE5EF;
    --bs-primary-bg-subtle: #131b33;
    --bs-secondary-bg-subtle: #212529;
    --bs-success-bg-subtle: #042c25;
    --bs-info-bg-subtle: #111f33;
    --bs-warning-bg-subtle: #332306;
    --bs-danger-bg-subtle: #321b15;
    --bs-light-bg-subtle: #343a40;
    --bs-dark-bg-subtle: #1a1d20;
    --bs-primary-border-subtle: #385199;
    --bs-secondary-border-subtle: #495057;
    --bs-success-border-subtle: #0b856f;
    --bs-info-border-subtle: #213e66;
    --bs-warning-border-subtle: #66460c;
    --bs-danger-border-subtle: #965240;
    --bs-light-border-subtle: #495057;
    --bs-dark-border-subtle: #343a40;
    --bs-heading-color: #fff;
    --bs-link-color: #9eb7ff;
    --bs-link-hover-color: #becfff;
    --bs-link-color-rgb: 158,183,255;
    --bs-link-hover-color-rgb: 190,207,255;
    --bs-code-color: #e685b5;
    --bs-border-color: #495057;
    --bs-border-color-translucent: rgba(255,255,255,0.15)
}

*,:after,:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: 0.25
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--bs-heading-color,inherit)
}

.h1,h1 {
    font-size: calc(1.34375rem + 1.125vw)
}

@media (min-width: 1200px) {
    .h1,h1 {
        font-size:2.1875rem
    }
}

.h2,h2 {
    font-size: calc(1.3rem + 0.6vw)
}

@media (min-width: 1200px) {
    .h2,h2 {
        font-size:1.75rem
    }
}

.h3,h3 {
    font-size: calc(1.278125rem + 0.3375vw)
}

@media (min-width: 1200px) {
    .h3,h3 {
        font-size:1.53125rem
    }
}

.h4,h4 {
    font-size: calc(1.25625rem + 0.075vw)
}

@media (min-width: 1200px) {
    .h4,h4 {
        font-size:1.3125rem
    }
}

.h5,h5 {
    font-size: 1.09375rem
}

.h6,h6 {
    font-size: 0.875rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,ul {
    padding-left: 2rem
}

dl,ol,ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,ol ul,ul ol,ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 500
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,strong {
    font-weight: 700
}

.small,small {
    font-size: 0.875em
}

.mark,mark {
    padding: 0.1875em;
    background-color: var(--bs-highlight-bg)
}

sub,sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

a {
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1));
    text-decoration: underline
}

a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb)
}

a:not([href]):not([class]),a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,kbd,pre,samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: 0.875em;
    color: var(--bs-code-color);
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: 0.1875rem 0.375rem;
    font-size: 0.875em;
    color: var(--bs-body-bg);
    background-color: var(--bs-body-color);
    border-radius: 5px
}

kbd kbd {
    padding: 0;
    font-size: 1em
}

figure {
    margin: 0 0 1rem
}

img,svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: 16px;
    padding-bottom: 16px;
    color: #5A6A85;
    text-align: left
}

th {
    font-weight: 600;
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,td,tfoot,th,thead,tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,input,optgroup,select,textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none!important
}

[type=button],[type=reset],[type=submit],button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit
}

@media (min-width: 1200px) {
    legend {
        font-size:1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-fields-wrapper,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-text,::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

::file-selector-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none!important
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-1 {
        font-size:5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-2 {
        font-size:4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-3 {
        font-size:4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-4 {
        font-size:3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-5 {
        font-size:3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-6 {
        font-size:2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem
}

.initialism {
    font-size: 0.875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.875em;
    color: #2A3547
}

.blockquote-footer:before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    -webkit-box-shadow: var(--bs-box-shadow-sm);
    box-shadow: var(--bs-box-shadow-sm);
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1
}

.figure-caption {
    font-size: 0.875em;
    color: var(--bs-secondary-color)
}

.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
    --bs-gutter-x: 24px;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container,.container-sm {
        max-width:540px
    }
}

@media (min-width: 768px) {
    .container,.container-md,.container-sm {
        max-width:720px
    }
}

@media (min-width: 992px) {
    .container,.container-lg,.container-md,.container-sm {
        max-width:960px
    }
}

@media (min-width: 1200px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl {
        max-width:1140px
    }
}

@media (min-width: 1400px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
        max-width:1320px
    }
}

.row {
    --bs-gutter-x: 24px;
    --bs-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x))
}

.row>* {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y)
}

.col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%
}

.row-cols-auto>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,.gx-0 {
    --bs-gutter-x: 0
}

.g-0,.gy-0 {
    --bs-gutter-y: 0
}

.g-1,.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,.gy-5 {
    --bs-gutter-y: 3rem
}

.g-6,.gx-6 {
    --bs-gutter-x: 12px
}

.g-6,.gy-6 {
    --bs-gutter-y: 12px
}

.g-7,.gx-7 {
    --bs-gutter-x: 30px
}

.g-7,.gy-7 {
    --bs-gutter-y: 30px
}

.g-8,.gx-8 {
    --bs-gutter-x: 10px
}

.g-8,.gy-8 {
    --bs-gutter-y: 10px
}

.g-9,.gx-9 {
    --bs-gutter-x: 20px
}

.g-9,.gy-9 {
    --bs-gutter-y: 20px
}

@media (min-width: 576px) {
    .col-sm {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,.gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,.gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,.gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,.gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,.gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,.gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,.gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,.gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,.gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,.gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,.gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5,.gy-sm-5 {
        --bs-gutter-y: 3rem
    }

    .g-sm-6,.gx-sm-6 {
        --bs-gutter-x: 12px
    }

    .g-sm-6,.gy-sm-6 {
        --bs-gutter-y: 12px
    }

    .g-sm-7,.gx-sm-7 {
        --bs-gutter-x: 30px
    }

    .g-sm-7,.gy-sm-7 {
        --bs-gutter-y: 30px
    }

    .g-sm-8,.gx-sm-8 {
        --bs-gutter-x: 10px
    }

    .g-sm-8,.gy-sm-8 {
        --bs-gutter-y: 10px
    }

    .g-sm-9,.gx-sm-9 {
        --bs-gutter-x: 20px
    }

    .g-sm-9,.gy-sm-9 {
        --bs-gutter-y: 20px
    }
}

@media (min-width: 768px) {
    .col-md {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,.gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,.gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,.gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,.gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,.gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,.gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,.gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,.gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,.gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,.gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,.gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,.gy-md-5 {
        --bs-gutter-y: 3rem
    }

    .g-md-6,.gx-md-6 {
        --bs-gutter-x: 12px
    }

    .g-md-6,.gy-md-6 {
        --bs-gutter-y: 12px
    }

    .g-md-7,.gx-md-7 {
        --bs-gutter-x: 30px
    }

    .g-md-7,.gy-md-7 {
        --bs-gutter-y: 30px
    }

    .g-md-8,.gx-md-8 {
        --bs-gutter-x: 10px
    }

    .g-md-8,.gy-md-8 {
        --bs-gutter-y: 10px
    }

    .g-md-9,.gx-md-9 {
        --bs-gutter-x: 20px
    }

    .g-md-9,.gy-md-9 {
        --bs-gutter-y: 20px
    }
}

@media (min-width: 992px) {
    .col-lg {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,.gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,.gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,.gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,.gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,.gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,.gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,.gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,.gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,.gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,.gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,.gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,.gy-lg-5 {
        --bs-gutter-y: 3rem
    }

    .g-lg-6,.gx-lg-6 {
        --bs-gutter-x: 12px
    }

    .g-lg-6,.gy-lg-6 {
        --bs-gutter-y: 12px
    }

    .g-lg-7,.gx-lg-7 {
        --bs-gutter-x: 30px
    }

    .g-lg-7,.gy-lg-7 {
        --bs-gutter-y: 30px
    }

    .g-lg-8,.gx-lg-8 {
        --bs-gutter-x: 10px
    }

    .g-lg-8,.gy-lg-8 {
        --bs-gutter-y: 10px
    }

    .g-lg-9,.gx-lg-9 {
        --bs-gutter-x: 20px
    }

    .g-lg-9,.gy-lg-9 {
        --bs-gutter-y: 20px
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,.gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,.gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,.gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,.gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,.gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,.gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,.gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,.gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,.gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,.gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,.gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,.gy-xl-5 {
        --bs-gutter-y: 3rem
    }

    .g-xl-6,.gx-xl-6 {
        --bs-gutter-x: 12px
    }

    .g-xl-6,.gy-xl-6 {
        --bs-gutter-y: 12px
    }

    .g-xl-7,.gx-xl-7 {
        --bs-gutter-x: 30px
    }

    .g-xl-7,.gy-xl-7 {
        --bs-gutter-y: 30px
    }

    .g-xl-8,.gx-xl-8 {
        --bs-gutter-x: 10px
    }

    .g-xl-8,.gy-xl-8 {
        --bs-gutter-y: 10px
    }

    .g-xl-9,.gx-xl-9 {
        --bs-gutter-x: 20px
    }

    .g-xl-9,.gy-xl-9 {
        --bs-gutter-y: 20px
    }
}

@media (min-width: 1400px) {
    .col-xxl {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,.gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,.gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,.gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,.gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,.gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,.gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,.gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,.gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,.gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,.gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,.gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5,.gy-xxl-5 {
        --bs-gutter-y: 3rem
    }

    .g-xxl-6,.gx-xxl-6 {
        --bs-gutter-x: 12px
    }

    .g-xxl-6,.gy-xxl-6 {
        --bs-gutter-y: 12px
    }

    .g-xxl-7,.gx-xxl-7 {
        --bs-gutter-x: 30px
    }

    .g-xxl-7,.gy-xxl-7 {
        --bs-gutter-y: 30px
    }

    .g-xxl-8,.gx-xxl-8 {
        --bs-gutter-x: 10px
    }

    .g-xxl-8,.gy-xxl-8 {
        --bs-gutter-y: 10px
    }

    .g-xxl-9,.gx-xxl-9 {
        --bs-gutter-x: 20px
    }

    .g-xxl-9,.gy-xxl-9 {
        --bs-gutter-y: 20px
    }
}

.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: #ebf1f6;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: #EAEFF4;
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0,0,0,0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: #F6F9FC;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color)
}

.table>:not(caption)>*>* {
    padding: 16px 16px;
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table-group-divider {
    border-top: calc(var(--bs-border-width) * 2) solid currentcolor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: 0.25rem 0.25rem
}

.table-bordered>:not(caption)>* {
    border-width: var(--bs-border-width) 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 var(--bs-border-width)
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-striped-columns>:not(caption)>tr>:nth-child(2n) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-color: #000;
    --bs-table-bg: #dfe7ff;
    --bs-table-border-color: #c9d0e6;
    --bs-table-striped-bg: #d4dbf2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c9d0e6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ced6ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-secondary {
    --bs-table-color: #000;
    --bs-table-bg: #dbf2ff;
    --bs-table-border-color: #c5dae6;
    --bs-table-striped-bg: #d0e6f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c5dae6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #cbe0ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-success {
    --bs-table-color: #000;
    --bs-table-bg: #d0f8f1;
    --bs-table-border-color: #bbdfd9;
    --bs-table-striped-bg: #c6ece5;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bbdfd9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c0e5df;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-info {
    --bs-table-color: #000;
    --bs-table-bg: #ddebff;
    --bs-table-border-color: #c7d4e6;
    --bs-table-striped-bg: #d2dff2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c7d4e6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ccd9ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-warning {
    --bs-table-color: #000;
    --bs-table-bg: #ffefd2;
    --bs-table-border-color: #e6d7bd;
    --bs-table-striped-bg: #f2e3c8;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6d7bd;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ecddc2;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-danger {
    --bs-table-color: #000;
    --bs-table-bg: #fee7e1;
    --bs-table-border-color: #e5d0cb;
    --bs-table-striped-bg: #f1dbd6;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e5d0cb;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ebd6d0;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-light {
    --bs-table-color: #000;
    --bs-table-bg: #F6F9FC;
    --bs-table-border-color: #dde0e3;
    --bs-table-striped-bg: #eaedef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dde0e3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e4e6e9;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-dark {
    --bs-table-color: #fff;
    --bs-table-bg: #2A3547;
    --bs-table-border-color: #3f4959;
    --bs-table-striped-bg: #353f50;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #3f4959;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #3a4455;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #2A3547
}

.col-form-label {
    padding-top: calc(8px + var(--bs-border-width));
    padding-bottom: calc(8px + var(--bs-border-width));
    margin-bottom: 0;
    font-size: inherit;
    font-weight: 600;
    line-height: 1.5;
    color: #2A3547
}

.col-form-label-lg {
    padding-top: calc(0.5rem + var(--bs-border-width));
    padding-bottom: calc(0.5rem + var(--bs-border-width));
    font-size: 1.09375rem
}

.col-form-label-sm {
    padding-top: calc(0.25rem + var(--bs-border-width));
    padding-bottom: calc(0.25rem + var(--bs-border-width));
    font-size: 0.765625rem
}

.form-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #5A6A85
}

.form-control {
    display: block;
    width: 100%;
    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5A6A85;
    background-color: transparent;
    background-clip: padding-box;
    border: var(--bs-border-width) solid #DFE5EF;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 7px;
    -webkit-box-shadow: inset 0 1px 2px rgba(var(--bs-body-color-rgb),0.075);
    box-shadow: inset 0 1px 2px rgba(var(--bs-body-color-rgb),0.075);
    -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #5A6A85;
    background-color: transparent;
    border-color: #aec3ff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 2px rgba(var(--bs-body-color-rgb),0.075),0 0 0 0.25rem rgba(93,135,255,0.25);
    box-shadow: inset 0 1px 2px rgba(var(--bs-body-color-rgb),0.075),0 0 0 0.25rem rgba(93,135,255,0.25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-webkit-datetime-edit {
    display: block;
    padding: 0
}

.form-control::-webkit-input-placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control::-moz-placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1
}

.form-control:disabled {
    background-color: var(--bs-form-control-disabled-bg);
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    padding: 8px 16px;
    margin: -8px -16px;
    -webkit-margin-end: 16px;
    margin-inline-end:16px;color: #5A6A85;
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:var(--bs-border-width);border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out
}

.form-control::file-selector-button {
    padding: 8px 16px;
    margin: -8px -16px;
    -webkit-margin-end: 16px;
    margin-inline-end:16px;color: #5A6A85;
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:var(--bs-border-width);border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }

    .form-control::file-selector-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: var(--bs-secondary-bg)
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg)
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 8px 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: transparent;
    border: solid transparent;
    border-width: var(--bs-border-width) 0
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-lg,.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
    border-radius: 5px
}

.form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end:0.5rem}

.form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end:0.5rem}

.form-control-lg {
    min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    border-radius: 15px
}

.form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end:1rem}

.form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end:1rem}

textarea.form-control {
    min-height: calc(1.5em + 16px + calc(var(--bs-border-width) * 2))
}

textarea.form-control-sm {
    min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2))
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2))
}

.form-control-color {
    width: 3rem;
    height: calc(1.5em + 16px + calc(var(--bs-border-width) * 2));
    padding: 8px
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    border: 0!important;
    border-radius: 7px
}

.form-control-color::-webkit-color-swatch {
    border-radius: 7px
}

.form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2))
}

.form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2))
}

.form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    display: block;
    width: 100%;
    padding: 8px 38px 8px 16px;
    -moz-padding-start: 13px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5A6A85;
    background-color: transparent;
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid #DFE5EF;
    border-radius: 7px;
    -webkit-box-shadow: unset;
    box-shadow: unset;
    -webkit-transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        -webkit-transition: none;
        transition: none
    }
}

.form-select:focus {
    border-color: #aec3ff;
    outline: 0;
    -webkit-box-shadow: unset,0 0 0 0.25rem rgba(93,135,255,0.25);
    box-shadow: unset,0 0 0 0.25rem rgba(93,135,255,0.25)
}

.form-select[multiple],.form-select[size]:not([size="1"]) {
    padding-right: 16px;
    background-image: none
}

.form-select:disabled {
    background-color: var(--bs-form-control-disabled-bg)
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #5A6A85
}

.form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.765625rem;
    border-radius: 5px
}

.form-select-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.09375rem;
    border-radius: 15px
}

[data-bs-theme=dark] .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235A6A85' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
}

.form-check {
    display: block;
    min-height: 1.188em;
    padding-left: 1.688em;
    margin-bottom: 0.125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.688em
}

.form-check-reverse {
    padding-right: 1.688em;
    padding-left: 0;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.688em;
    margin-left: 0
}

.form-check-input {
    --bs-form-check-bg: transparent;
    width: 1.188em;
    height: 1.188em;
    margin-top: 0.156em;
    vertical-align: top;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1.25px solid #dfe5ef;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: 0.25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    -webkit-filter: brightness(90%);
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #aec3ff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(93,135,255,0.25);
    box-shadow: 0 0 0 0.25rem rgba(93,135,255,0.25)
}

.form-check-input:checked {
    background-color: #5D87FF;
    border-color: #5D87FF
}

.form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #5D87FF;
    border-color: #5D87FF;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.5
}

.form-check-input:disabled~.form-check-label,.form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: 0.5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    -webkit-transition: background-position 0.15s ease-in-out;
    transition: background-position 0.15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        -webkit-transition: none;
        transition: none
    }
}

.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23aec3ff'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0
}

.form-switch.form-check-reverse .form-check-input {
    margin-right: -2.5em;
    margin-left: 0
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none
}

.btn-check:disabled+.btn,.btn-check[disabled]+.btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: 0.65
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e")
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff,0 0 0 0.25rem rgba(93,135,255,0.25);
    box-shadow: 0 0 0 1px #fff,0 0 0 0.25rem rgba(93,135,255,0.25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff,0 0 0 0.25rem rgba(93,135,255,0.25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #5D87FF;
    border: 0;
    border-radius: 1rem;
    -webkit-box-shadow: 0 0.1rem 0.25rem rgba(0,0,0,0.1);
    box-shadow: 0 0.1rem 0.25rem rgba(0,0,0,0.1);
    -webkit-transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #cedbff
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem;
    -webkit-box-shadow: inset 0 1px 2px rgba(var(--bs-body-color-rgb),0.075);
    box-shadow: inset 0 1px 2px rgba(var(--bs-body-color-rgb),0.075)
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #5D87FF;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0,0,0,0.1);
    -moz-transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #cedbff
}

.form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(var(--bs-body-color-rgb),0.075)
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: var(--bs-secondary-color)
}

.form-range:disabled::-moz-range-thumb {
    background-color: var(--bs-secondary-color)
}

.form-floating {
    position: relative
}

.form-floating:before:not(.form-control:disabled) {
    position: absolute;
    top: var(--bs-border-width);
    left: var(--bs-border-width);
    width: calc(100% - (calc(calc(0.375em + 4px) + calc(0.75em + 8px))));
    height: 1.875em;
    content: "";
    background-color: transparent;
    border-radius: 7px
}

.form-floating>.form-control,.form-floating>.form-control-plaintext,.form-floating>.form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 16px;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: opacity 0.1s ease-in-out,-webkit-transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out,-webkit-transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out,transform 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out,transform 0.1s ease-in-out,-webkit-transform 0.1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        -webkit-transition: none;
        transition: none
    }
}

.form-floating>.form-control,.form-floating>.form-control-plaintext {
    padding: 1rem 16px
}

.form-floating>.form-control-plaintext::-webkit-input-placeholder,.form-floating>.form-control::-webkit-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::-moz-placeholder,.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:-ms-input-placeholder,.form-floating>.form-control:-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::-ms-input-placeholder,.form-floating>.form-control::-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::placeholder,.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown),.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem
}

.form-floating>.form-control-plaintext:not(:-ms-input-placeholder),.form-floating>.form-control:not(:-ms-input-placeholder) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem
}

.form-floating>.form-control-plaintext:focus,.form-floating>.form-control-plaintext:not(:placeholder-shown),.form-floating>.form-control:focus,.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem
}

.form-floating>.form-control-plaintext:-webkit-autofill,.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control-plaintext~label,.form-floating>.form-control:focus~label,.form-floating>.form-control:not(:placeholder-shown)~label,.form-floating>.form-select~label {
    opacity: 0.65;
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: 0.65;
    -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: var(--bs-border-width) 0
}

.form-floating>.form-control:disabled~label {
    color: #2A3547
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,.input-group>.form-floating,.input-group>.form-select {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,.input-group>.form-floating:focus-within,.input-group>.form-select:focus {
    z-index: 5
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 5
}

.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5A6A85;
    text-align: center;
    white-space: nowrap;
    background-color: #EAEFF4;
    border: var(--bs-border-width) solid #F6F9FC;
    border-radius: 7px
}

.input-group-lg>.btn,.input-group-lg>.form-control,.input-group-lg>.form-select,.input-group-lg>.input-group-text {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    border-radius: 15px
}

.input-group-sm>.btn,.input-group-sm>.form-control,.input-group-sm>.form-select,.input-group-sm>.input-group-text {
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
    border-radius: 5px
}

.input-group-lg>.form-select,.input-group-sm>.form-select {
    padding-right: 54px
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: calc(var(--bs-border-width) * -1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.form-floating:not(:first-child)>.form-control,.input-group>.form-floating:not(:first-child)>.form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-success-text)
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.765625rem;
    color: #fff;
    background-color: var(--bs-success);
    border-radius: var(--bs-border-radius)
}

.is-valid~.valid-feedback,.is-valid~.valid-tooltip,.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,.was-validated .form-control:valid {
    border-color: var(--bs-success);
    padding-right: calc(1.5em + 16px);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2313DEB9' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 4px) center;
    background-size: calc(0.75em + 8px) calc(0.75em + 8px)
}

.form-control.is-valid:focus,.was-validated .form-control:valid:focus {
    border-color: var(--bs-success);
    -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb),0.25);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb),0.25)
}

.was-validated textarea.form-control:valid,textarea.form-control.is-valid {
    padding-right: calc(1.5em + 16px);
    background-position: top calc(0.375em + 4px) right calc(0.375em + 4px)
}

.form-select.is-valid,.was-validated .form-select:valid {
    border-color: var(--bs-success)
}

.form-select.is-valid:not([multiple]):not([size]),.form-select.is-valid:not([multiple])[size="1"],.was-validated .form-select:valid:not([multiple]):not([size]),.was-validated .form-select:valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2313DEB9' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 78px;
    background-position: right 16px center,center right 38px;
    background-size: 16px 12px,calc(0.75em + 8px) calc(0.75em + 8px)
}

.form-select.is-valid:focus,.was-validated .form-select:valid:focus {
    border-color: var(--bs-success);
    -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb),0.25);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb),0.25)
}

.form-control-color.is-valid,.was-validated .form-control-color:valid {
    width: calc(3rem + calc(1.5em + 16px))
}

.form-check-input.is-valid,.was-validated .form-check-input:valid {
    border-color: var(--bs-success)
}

.form-check-input.is-valid:checked,.was-validated .form-check-input:valid:checked {
    background-color: var(--bs-success-text)
}

.form-check-input.is-valid:focus,.was-validated .form-check-input:valid:focus {
    -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb),0.25);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb),0.25)
}

.form-check-input.is-valid~.form-check-label,.was-validated .form-check-input:valid~.form-check-label {
    color: var(--bs-success-text)
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: 0.5em
}

.input-group>.form-control:not(:focus).is-valid,.input-group>.form-floating:not(:focus-within).is-valid,.input-group>.form-select:not(:focus).is-valid,.was-validated .input-group>.form-control:not(:focus):valid,.was-validated .input-group>.form-floating:not(:focus-within):valid,.was-validated .input-group>.form-select:not(:focus):valid {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-danger-text)
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.765625rem;
    color: #fff;
    background-color: var(--bs-danger);
    border-radius: var(--bs-border-radius)
}

.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip,.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,.was-validated .form-control:invalid {
    border-color: var(--bs-danger);
    padding-right: calc(1.5em + 16px);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FA896B'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA896B' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 4px) center;
    background-size: calc(0.75em + 8px) calc(0.75em + 8px)
}

.form-control.is-invalid:focus,.was-validated .form-control:invalid:focus {
    border-color: var(--bs-danger);
    -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb),0.25);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb),0.25)
}

.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 16px);
    background-position: top calc(0.375em + 4px) right calc(0.375em + 4px)
}

.form-select.is-invalid,.was-validated .form-select:invalid {
    border-color: var(--bs-danger)
}

.form-select.is-invalid:not([multiple]):not([size]),.form-select.is-invalid:not([multiple])[size="1"],.was-validated .form-select:invalid:not([multiple]):not([size]),.was-validated .form-select:invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FA896B'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA896B' stroke='none'/%3e%3c/svg%3e");
    padding-right: 78px;
    background-position: right 16px center,center right 38px;
    background-size: 16px 12px,calc(0.75em + 8px) calc(0.75em + 8px)
}

.form-select.is-invalid:focus,.was-validated .form-select:invalid:focus {
    border-color: var(--bs-danger);
    -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb),0.25);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb),0.25)
}

.form-control-color.is-invalid,.was-validated .form-control-color:invalid {
    width: calc(3rem + calc(1.5em + 16px))
}

.form-check-input.is-invalid,.was-validated .form-check-input:invalid {
    border-color: var(--bs-danger)
}

.form-check-input.is-invalid:checked,.was-validated .form-check-input:invalid:checked {
    background-color: var(--bs-danger-text)
}

.form-check-input.is-invalid:focus,.was-validated .form-check-input:invalid:focus {
    -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb),0.25);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb),0.25)
}

.form-check-input.is-invalid~.form-check-label,.was-validated .form-check-input:invalid~.form-check-label {
    color: var(--bs-danger-text)
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: 0.5em
}

.input-group>.form-control:not(:focus).is-invalid,.input-group>.form-floating:not(:focus-within).is-invalid,.input-group>.form-select:not(:focus).is-invalid,.was-validated .input-group>.form-control:not(:focus):invalid,.was-validated .input-group>.form-floating:not(:focus-within):invalid,.was-validated .input-group>.form-select:not(:focus):invalid {
    z-index: 4
}

.btn {
    --bs-btn-padding-x: 16px;
    --bs-btn-padding-y: 7px;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 14;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #5A6A85;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 7px;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: unset;
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb),.5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    -webkit-box-shadow: var(--bs-btn-box-shadow);
    box-shadow: var(--bs-btn-box-shadow);
    -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        -webkit-transition: none;
        transition: none
    }
}

.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color)
}

.btn-check+.btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color)
}

.btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    -webkit-box-shadow: var(--bs-btn-box-shadow),var(--bs-btn-focus-box-shadow);
    box-shadow: var(--bs-btn-box-shadow),var(--bs-btn-focus-box-shadow)
}

.btn-check:focus-visible+.btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    -webkit-box-shadow: var(--bs-btn-box-shadow),var(--bs-btn-focus-box-shadow);
    box-shadow: var(--bs-btn-box-shadow),var(--bs-btn-focus-box-shadow)
}

.btn-check:checked+.btn,.btn.active,.btn.show,.btn:first-child:active,:not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    -webkit-box-shadow: var(--bs-btn-active-shadow);
    box-shadow: var(--bs-btn-active-shadow)
}

.btn-check:checked+.btn:focus-visible,.btn.active:focus-visible,.btn.show:focus-visible,.btn:first-child:active:focus-visible,:not(.btn-check)+.btn:active:focus-visible {
    -webkit-box-shadow: var(--bs-btn-active-shadow),var(--bs-btn-focus-box-shadow);
    box-shadow: var(--bs-btn-active-shadow),var(--bs-btn-focus-box-shadow)
}

.btn.disabled,.btn:disabled,fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #5D87FF;
    --bs-btn-border-color: #5D87FF;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4f73d9;
    --bs-btn-hover-border-color: #4a6ccc;
    --bs-btn-focus-shadow-rgb: 117,153,255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4a6ccc;
    --bs-btn-active-border-color: #4665bf;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5D87FF;
    --bs-btn-disabled-border-color: #5D87FF
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #49BEFF;
    --bs-btn-border-color: #49BEFF;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3ea2d9;
    --bs-btn-hover-border-color: #3a98cc;
    --bs-btn-focus-shadow-rgb: 100,200,255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3a98cc;
    --bs-btn-active-border-color: #378fbf;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #49BEFF;
    --bs-btn-disabled-border-color: #49BEFF
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #13DEB9;
    --bs-btn-border-color: #13DEB9;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #10bd9d;
    --bs-btn-hover-border-color: #0fb294;
    --bs-btn-focus-shadow-rgb: 54,227,196;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0fb294;
    --bs-btn-active-border-color: #0ea78b;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #13DEB9;
    --bs-btn-disabled-border-color: #13DEB9
}

.btn-info {
    --bs-btn-color: #fff;
    --bs-btn-bg: #539BFF;
    --bs-btn-border-color: #539BFF;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4784d9;
    --bs-btn-hover-border-color: #427ccc;
    --bs-btn-focus-shadow-rgb: 109,170,255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #427ccc;
    --bs-btn-active-border-color: #3e74bf;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #539BFF;
    --bs-btn-disabled-border-color: #539BFF
}

.btn-warning {
    --bs-btn-color: #fff;
    --bs-btn-bg: #FFAE1F;
    --bs-btn-border-color: #FFAE1F;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #d9941a;
    --bs-btn-hover-border-color: #cc8b19;
    --bs-btn-focus-shadow-rgb: 255,186,65;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #cc8b19;
    --bs-btn-active-border-color: #bf8317;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #FFAE1F;
    --bs-btn-disabled-border-color: #FFAE1F
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #FA896B;
    --bs-btn-border-color: #FA896B;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #d5745b;
    --bs-btn-hover-border-color: #c86e56;
    --bs-btn-focus-shadow-rgb: 251,155,129;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #c86e56;
    --bs-btn-active-border-color: #bc6750;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #FA896B;
    --bs-btn-disabled-border-color: #FA896B
}

.btn-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #F6F9FC;
    --bs-btn-border-color: #F6F9FC;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d1d4d6;
    --bs-btn-hover-border-color: #c5c7ca;
    --bs-btn-focus-shadow-rgb: 209,212,214;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #c5c7ca;
    --bs-btn-active-border-color: #b9bbbd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #F6F9FC;
    --bs-btn-disabled-border-color: #F6F9FC
}

.btn-dark {
    --bs-btn-color: #fff;
    --bs-btn-bg: #2A3547;
    --bs-btn-border-color: #2A3547;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4a5363;
    --bs-btn-hover-border-color: #3f4959;
    --bs-btn-focus-shadow-rgb: 74,83,99;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #555d6c;
    --bs-btn-active-border-color: #3f4959;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #2A3547;
    --bs-btn-disabled-border-color: #2A3547
}

.btn-muted {
    --bs-btn-color: #fff;
    --bs-btn-bg: #5A6A85;
    --bs-btn-border-color: #5A6A85;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4d5a71;
    --bs-btn-hover-border-color: #48556a;
    --bs-btn-focus-shadow-rgb: 115,128,151;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #48556a;
    --bs-btn-active-border-color: #445064;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5A6A85;
    --bs-btn-disabled-border-color: #5A6A85
}

.btn-indigo {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6610f2;
    --bs-btn-border-color: #6610f2;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #570ece;
    --bs-btn-hover-border-color: #520dc2;
    --bs-btn-focus-shadow-rgb: 125,52,244;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #520dc2;
    --bs-btn-active-border-color: #4d0cb6;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6610f2;
    --bs-btn-disabled-border-color: #6610f2
}

.btn-light-primary {
    --bs-btn-color: #000;
    --bs-btn-bg: #ECF2FF;
    --bs-btn-border-color: #ECF2FF;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #eff4ff;
    --bs-btn-hover-border-color: #eef3ff;
    --bs-btn-focus-shadow-rgb: 201,206,217;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f0f5ff;
    --bs-btn-active-border-color: #eef3ff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #ECF2FF;
    --bs-btn-disabled-border-color: #ECF2FF
}

.btn-light-secondary {
    --bs-btn-color: #000;
    --bs-btn-bg: #E8F7FF;
    --bs-btn-border-color: #E8F7FF;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ebf8ff;
    --bs-btn-hover-border-color: #eaf8ff;
    --bs-btn-focus-shadow-rgb: 197,210,217;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #edf9ff;
    --bs-btn-active-border-color: #eaf8ff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #E8F7FF;
    --bs-btn-disabled-border-color: #E8F7FF
}

.btn-light-info {
    --bs-btn-color: #000;
    --bs-btn-bg: #EBF3FE;
    --bs-btn-border-color: #EBF3FE;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #eef5fe;
    --bs-btn-hover-border-color: #edf4fe;
    --bs-btn-focus-shadow-rgb: 200,207,216;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #eff5fe;
    --bs-btn-active-border-color: #edf4fe;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #EBF3FE;
    --bs-btn-disabled-border-color: #EBF3FE
}

.btn-light-success {
    --bs-btn-color: #000;
    --bs-btn-bg: #E6FFFA;
    --bs-btn-border-color: #E6FFFA;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #eafffb;
    --bs-btn-hover-border-color: #e9fffb;
    --bs-btn-focus-shadow-rgb: 196,217,213;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ebfffb;
    --bs-btn-active-border-color: #e9fffb;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #E6FFFA;
    --bs-btn-disabled-border-color: #E6FFFA
}

.btn-light-warning {
    --bs-btn-color: #000;
    --bs-btn-bg: #FEF5E5;
    --bs-btn-border-color: #FEF5E5;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fef7e9;
    --bs-btn-hover-border-color: #fef6e8;
    --bs-btn-focus-shadow-rgb: 216,208,195;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fef7ea;
    --bs-btn-active-border-color: #fef6e8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #FEF5E5;
    --bs-btn-disabled-border-color: #FEF5E5
}

.btn-light-danger {
    --bs-btn-color: #000;
    --bs-btn-bg: #FBF2EF;
    --bs-btn-border-color: #FBF2EF;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fcf4f1;
    --bs-btn-hover-border-color: #fbf3f1;
    --bs-btn-focus-shadow-rgb: 213,206,203;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fcf5f2;
    --bs-btn-active-border-color: #fbf3f1;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #FBF2EF;
    --bs-btn-disabled-border-color: #FBF2EF
}

.btn-light-indigo {
    --bs-btn-color: #000;
    --bs-btn-bg: #EBF3FE;
    --bs-btn-border-color: #EBF3FE;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #eef5fe;
    --bs-btn-hover-border-color: #edf4fe;
    --bs-btn-focus-shadow-rgb: 200,207,216;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #eff5fe;
    --bs-btn-active-border-color: #edf4fe;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #EBF3FE;
    --bs-btn-disabled-border-color: #EBF3FE
}

.btn-dark-light {
    --bs-btn-color: #fff;
    --bs-btn-bg: #2A3547;
    --bs-btn-border-color: #2A3547;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #242d3c;
    --bs-btn-hover-border-color: #222a39;
    --bs-btn-focus-shadow-rgb: 74,83,99;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #222a39;
    --bs-btn-active-border-color: #202835;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #2A3547;
    --bs-btn-disabled-border-color: #2A3547
}

.btn-light-gray {
    --bs-btn-color: #000;
    --bs-btn-bg: #F6F9FC;
    --bs-btn-border-color: #F6F9FC;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #f7fafc;
    --bs-btn-hover-border-color: #f7fafc;
    --bs-btn-focus-shadow-rgb: 209,212,214;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f8fafd;
    --bs-btn-active-border-color: #f7fafc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #F6F9FC;
    --bs-btn-disabled-border-color: #F6F9FC
}

.btn-outline-primary {
    --bs-btn-color: #5D87FF;
    --bs-btn-border-color: #5D87FF;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5D87FF;
    --bs-btn-hover-border-color: #5D87FF;
    --bs-btn-focus-shadow-rgb: 93,135,255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #5D87FF;
    --bs-btn-active-border-color: #5D87FF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #5D87FF;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #5D87FF;
    --bs-gradient: none
}

.btn-outline-secondary {
    --bs-btn-color: #49BEFF;
    --bs-btn-border-color: #49BEFF;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #49BEFF;
    --bs-btn-hover-border-color: #49BEFF;
    --bs-btn-focus-shadow-rgb: 73,190,255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #49BEFF;
    --bs-btn-active-border-color: #49BEFF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #49BEFF;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #49BEFF;
    --bs-gradient: none
}

.btn-outline-success {
    --bs-btn-color: #13DEB9;
    --bs-btn-border-color: #13DEB9;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #13DEB9;
    --bs-btn-hover-border-color: #13DEB9;
    --bs-btn-focus-shadow-rgb: 19,222,185;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #13DEB9;
    --bs-btn-active-border-color: #13DEB9;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #13DEB9;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #13DEB9;
    --bs-gradient: none
}

.btn-outline-info {
    --bs-btn-color: #539BFF;
    --bs-btn-border-color: #539BFF;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #539BFF;
    --bs-btn-hover-border-color: #539BFF;
    --bs-btn-focus-shadow-rgb: 83,155,255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #539BFF;
    --bs-btn-active-border-color: #539BFF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #539BFF;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #539BFF;
    --bs-gradient: none
}

.btn-outline-warning {
    --bs-btn-color: #FFAE1F;
    --bs-btn-border-color: #FFAE1F;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #FFAE1F;
    --bs-btn-hover-border-color: #FFAE1F;
    --bs-btn-focus-shadow-rgb: 255,174,31;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #FFAE1F;
    --bs-btn-active-border-color: #FFAE1F;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #FFAE1F;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #FFAE1F;
    --bs-gradient: none
}

.btn-outline-danger {
    --bs-btn-color: #FA896B;
    --bs-btn-border-color: #FA896B;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #FA896B;
    --bs-btn-hover-border-color: #FA896B;
    --bs-btn-focus-shadow-rgb: 250,137,107;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #FA896B;
    --bs-btn-active-border-color: #FA896B;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #FA896B;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #FA896B;
    --bs-gradient: none
}

.btn-outline-light {
    --bs-btn-color: #F6F9FC;
    --bs-btn-border-color: #F6F9FC;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #F6F9FC;
    --bs-btn-hover-border-color: #F6F9FC;
    --bs-btn-focus-shadow-rgb: 246,249,252;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #F6F9FC;
    --bs-btn-active-border-color: #F6F9FC;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #F6F9FC;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #F6F9FC;
    --bs-gradient: none
}

.btn-outline-dark {
    --bs-btn-color: #2A3547;
    --bs-btn-border-color: #2A3547;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #2A3547;
    --bs-btn-hover-border-color: #2A3547;
    --bs-btn-focus-shadow-rgb: 42,53,71;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2A3547;
    --bs-btn-active-border-color: #2A3547;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #2A3547;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #2A3547;
    --bs-gradient: none
}

.btn-outline-muted {
    --bs-btn-color: #5A6A85;
    --bs-btn-border-color: #5A6A85;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5A6A85;
    --bs-btn-hover-border-color: #5A6A85;
    --bs-btn-focus-shadow-rgb: 90,106,133;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #5A6A85;
    --bs-btn-active-border-color: #5A6A85;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #5A6A85;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #5A6A85;
    --bs-gradient: none
}

.btn-outline-indigo {
    --bs-btn-color: #6610f2;
    --bs-btn-border-color: #6610f2;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6610f2;
    --bs-btn-hover-border-color: #6610f2;
    --bs-btn-focus-shadow-rgb: 102,16,242;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6610f2;
    --bs-btn-active-border-color: #6610f2;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #6610f2;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #6610f2;
    --bs-gradient: none
}

.btn-outline-light-primary {
    --bs-btn-color: #ECF2FF;
    --bs-btn-border-color: #ECF2FF;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ECF2FF;
    --bs-btn-hover-border-color: #ECF2FF;
    --bs-btn-focus-shadow-rgb: 236,242,255;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ECF2FF;
    --bs-btn-active-border-color: #ECF2FF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #ECF2FF;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ECF2FF;
    --bs-gradient: none
}

.btn-outline-light-secondary {
    --bs-btn-color: #E8F7FF;
    --bs-btn-border-color: #E8F7FF;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #E8F7FF;
    --bs-btn-hover-border-color: #E8F7FF;
    --bs-btn-focus-shadow-rgb: 232,247,255;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #E8F7FF;
    --bs-btn-active-border-color: #E8F7FF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #E8F7FF;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #E8F7FF;
    --bs-gradient: none
}

.btn-outline-light-info {
    --bs-btn-color: #EBF3FE;
    --bs-btn-border-color: #EBF3FE;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #EBF3FE;
    --bs-btn-hover-border-color: #EBF3FE;
    --bs-btn-focus-shadow-rgb: 235,243,254;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #EBF3FE;
    --bs-btn-active-border-color: #EBF3FE;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #EBF3FE;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #EBF3FE;
    --bs-gradient: none
}

.btn-outline-light-success {
    --bs-btn-color: #E6FFFA;
    --bs-btn-border-color: #E6FFFA;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #E6FFFA;
    --bs-btn-hover-border-color: #E6FFFA;
    --bs-btn-focus-shadow-rgb: 230,255,250;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #E6FFFA;
    --bs-btn-active-border-color: #E6FFFA;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #E6FFFA;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #E6FFFA;
    --bs-gradient: none
}

.btn-outline-light-warning {
    --bs-btn-color: #FEF5E5;
    --bs-btn-border-color: #FEF5E5;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #FEF5E5;
    --bs-btn-hover-border-color: #FEF5E5;
    --bs-btn-focus-shadow-rgb: 254,245,229;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #FEF5E5;
    --bs-btn-active-border-color: #FEF5E5;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #FEF5E5;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #FEF5E5;
    --bs-gradient: none
}

.btn-outline-light-danger {
    --bs-btn-color: #FBF2EF;
    --bs-btn-border-color: #FBF2EF;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #FBF2EF;
    --bs-btn-hover-border-color: #FBF2EF;
    --bs-btn-focus-shadow-rgb: 251,242,239;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #FBF2EF;
    --bs-btn-active-border-color: #FBF2EF;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #FBF2EF;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #FBF2EF;
    --bs-gradient: none
}

.btn-outline-light-indigo {
    --bs-btn-color: #EBF3FE;
    --bs-btn-border-color: #EBF3FE;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #EBF3FE;
    --bs-btn-hover-border-color: #EBF3FE;
    --bs-btn-focus-shadow-rgb: 235,243,254;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #EBF3FE;
    --bs-btn-active-border-color: #EBF3FE;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #EBF3FE;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #EBF3FE;
    --bs-gradient: none
}

.btn-outline-dark-light {
    --bs-btn-color: #2A3547;
    --bs-btn-border-color: #2A3547;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #2A3547;
    --bs-btn-hover-border-color: #2A3547;
    --bs-btn-focus-shadow-rgb: 42,53,71;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2A3547;
    --bs-btn-active-border-color: #2A3547;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #2A3547;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #2A3547;
    --bs-gradient: none
}

.btn-outline-light-gray {
    --bs-btn-color: #F6F9FC;
    --bs-btn-border-color: #F6F9FC;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #F6F9FC;
    --bs-btn-hover-border-color: #F6F9FC;
    --bs-btn-focus-shadow-rgb: 246,249,252;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #F6F9FC;
    --bs-btn-active-border-color: #F6F9FC;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #F6F9FC;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #F6F9FC;
    --bs-gradient: none
}

.btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #2A3547;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 117,153,255;
    text-decoration: underline
}

.btn-link:focus-visible {
    color: var(--bs-btn-color)
}

.btn-link:hover {
    color: var(--bs-btn-hover-color)
}

.btn-group-lg>.btn,.btn-lg {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.09375rem;
    --bs-btn-border-radius: 9px
}

.btn-group-sm>.btn,.btn-sm {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.765625rem;
    --bs-btn-border-radius: 5px
}

.fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        -webkit-transition: none;
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        -webkit-transition: none;
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    -webkit-transition: width 0.35s ease;
    transition: width 0.35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        -webkit-transition: none;
        transition: none
    }
}

.dropdown,.dropdown-center,.dropend,.dropstart,.dropup,.dropup-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent
}

.dropdown-toggle:empty:after {
    margin-left: 0
}

.dropdown-menu {
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 0.875rem;
    --bs-dropdown-color: var(--bs-body-color);
    --bs-dropdown-bg: var(--bs-body-bg);
    --bs-dropdown-border-color: transparent;
    --bs-dropdown-border-radius: 7px;
    --bs-dropdown-border-width: var(--bs-border-width);
    --bs-dropdown-inner-border-radius: calc(7px - var(--bs-border-width));
    --bs-dropdown-divider-bg: transparent;
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: rgba(145,158,171,0.2) 0px 0px 2px 0px,rgba(145,158,171,0.12) 0px 12px 24px -4px;
    --bs-dropdown-link-color: var(--bs-body-color);
    --bs-dropdown-link-hover-color: var(--bs-body-color);
    --bs-dropdown-link-hover-bg: #F6F9FC;
    --bs-dropdown-link-active-color: #2A3547;
    --bs-dropdown-link-active-bg: #F6F9FC;
    --bs-dropdown-link-disabled-color: #5A6A85;
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 10px;
    --bs-dropdown-header-color: #2A3547;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
    -webkit-box-shadow: var(--bs-dropdown-box-shadow);
    box-shadow: var(--bs-dropdown-box-shadow)
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer)
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position:start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position:start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position:start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position:start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position:start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--bs-dropdown-spacer)
}

.dropup .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent
}

.dropup .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer)
}

.dropend .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid
}

.dropend .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropend .dropdown-toggle:after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--bs-dropdown-spacer)
}

.dropstart .dropdown-toggle:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""
}

.dropstart .dropdown-toggle:after {
    display: none
}

.dropstart .dropdown-toggle:before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent
}

.dropstart .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropstart .dropdown-toggle:before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    opacity: 1
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: var(--bs-dropdown-item-border-radius,0)
}

.dropdown-item:focus,.dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg)
}

.dropdown-item.active,.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg)
}

.dropdown-item.disabled,.dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: 0.765625rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-dropdown-link-color)
}

.dropdown-menu-dark {
    --bs-dropdown-color: #DFE5EF;
    --bs-dropdown-bg: #343a40;
    --bs-dropdown-border-color: transparent;
    --bs-dropdown-box-shadow: ;
    --bs-dropdown-link-color: #DFE5EF;
    --bs-dropdown-link-hover-color: #fff;
    --bs-dropdown-divider-bg: transparent;
    --bs-dropdown-link-hover-bg: rgba(255,255,255,0.15);
    --bs-dropdown-link-active-color: #2A3547;
    --bs-dropdown-link-active-bg: #F6F9FC;
    --bs-dropdown-link-disabled-color: #5A6A85;
    --bs-dropdown-header-color: #5A6A85
}

.btn-group,.btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,.btn-group>.btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,.btn-group-vertical>.btn-check:focus+.btn,.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:hover,.btn-group>.btn-check:checked+.btn,.btn-group>.btn-check:focus+.btn,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus,.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group {
    border-radius: 7px
}

.btn-group>.btn-group:not(:first-child),.btn-group>:not(.btn-check:first-child)+.btn {
    margin-left: calc(var(--bs-border-width) * -1)
}

.btn-group>.btn-group:not(:last-child)>.btn,.btn-group>.btn.dropdown-toggle-split:first-child,.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,.btn-group>.btn:nth-child(n+3),.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: 12px;
    padding-left: 12px
}

.dropdown-toggle-split:after,.dropend .dropdown-toggle-split:after,.dropup .dropdown-toggle-split:after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split:before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,.btn-sm+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,.btn-lg+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem
}

.btn-group.show .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,0.125)
}

.btn-group.show .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),.btn-group-vertical>.btn:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1)
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        -webkit-transition: none;
        transition: none
    }
}

.nav-link:focus,.nav-link:hover {
    color: var(--bs-nav-link-hover-color)
}

.nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    --bs-nav-tabs-border-width: var(--bs-border-width);
    --bs-nav-tabs-border-color: var(--bs-border-color);
    --bs-nav-tabs-border-radius: var(--bs-border-radius);
    --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
    --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
    --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
    --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color)
}

.nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius)
}

.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: var(--bs-nav-tabs-link-hover-border-color)
}

.nav-tabs .nav-link.disabled,.nav-tabs .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color)
}

.nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills {
    --bs-nav-pills-border-radius: 7px;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #5D87FF
}

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius)
}

.nav-pills .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg)
}

.nav-fill .nav-item,.nav-fill>.nav-link {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,.nav-justified>.nav-link {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0.5rem;
    --bs-navbar-color: #2a3547;
    --bs-navbar-hover-color: #5d87ff;
    --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb),0.3);
    --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb),1);
    --bs-navbar-brand-padding-y: 0.3359375rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1.09375rem;
    --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb),1);
    --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb),1);
    --bs-navbar-nav-link-padding-x: 0.5rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --bs-navbar-toggler-padding-x: 0.75rem;
    --bs-navbar-toggler-font-size: 1.09375rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2890, 106, 133, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb),0.15);
    --bs-navbar-toggler-border-radius: 7px;
    --bs-navbar-toggler-focus-width: 0.25rem;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x)
}

.navbar>.container,.navbar>.container-fluid,.navbar>.container-lg,.navbar>.container-md,.navbar>.container-sm,.navbar>.container-xl,.navbar>.container-xxl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    padding-top: var(--bs-navbar-brand-padding-y);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    margin-right: var(--bs-navbar-brand-margin-end);
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap
}

.navbar-brand:focus,.navbar-brand:hover {
    color: var(--bs-navbar-brand-hover-color)
}

.navbar-nav {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link.active,.navbar-nav .show>.nav-link {
    color: var(--bs-navbar-active-color)
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: var(--bs-navbar-color)
}

.navbar-text a,.navbar-text a:focus,.navbar-text a:hover {
    color: var(--bs-navbar-active-color)
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    -webkit-transition: var(--bs-navbar-toggler-transition);
    transition: var(--bs-navbar-toggler-transition)
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        -webkit-transition: none;
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width)
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height,75vh);
    overflow-y: auto
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-md .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width: 1400px) {
    .navbar-expand-xxl {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x)
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x)
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: auto!important;
    height: auto!important;
    visibility: visible!important;
    background-color: transparent!important;
    border: 0!important;
    -webkit-transform: none!important;
    transform: none!important;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    transition: none
}

.navbar-expand .offcanvas .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-dark {
    --bs-navbar-color: #dfe5ef;
    --bs-navbar-hover-color: #5d87ff;
    --bs-navbar-disabled-color: rgba(255,255,255,0.25);
    --bs-navbar-active-color: #fff;
    --bs-navbar-brand-color: #fff;
    --bs-navbar-brand-hover-color: #fff;
    --bs-navbar-toggler-border-color: rgba(255,255,255,0.1);
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23dfe5ef' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

[data-bs-theme=dark] .navbar {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23dfe5ef' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.card {
    --bs-card-spacer-y: 30px;
    --bs-card-spacer-x: 30px;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: #2A3547;
    --bs-card-subtitle-color: #2A3547;
    --bs-card-border-width: 0px;
    --bs-card-border-color: #ebf1f6;
    --bs-card-border-radius: 7px;
    --bs-card-box-shadow: rgba(145,158,171,0.2) 0px 0px 2px 0px,rgba(145,158,171,0.12) 0px 12px 24px -4px;
    --bs-card-inner-border-radius: 7px;
    --bs-card-cap-padding-y: 15px;
    --bs-card-cap-padding-x: 30px;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb),0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 12px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    -webkit-box-shadow: var(--bs-card-box-shadow);
    box-shadow: var(--bs-card-box-shadow)
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.card>.card-header+.list-group,.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color)
}

.card-title {
    margin-bottom: var(--bs-card-title-spacer-y);
    color: var(--bs-card-title-color)
}

.card-subtitle {
    margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
    margin-bottom: 0;
    color: var(--bs-card-subtitle-color)
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: var(--bs-card-spacer-x)
}

.card-header {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color)
}

.card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0
}

.card-footer {
    padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
    color: var(--bs-card-cap-color);
    background-color: var(--bs-card-cap-bg);
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color)
}

.card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)
}

.card-header-tabs {
    margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
    margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
    margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
    border-bottom: 0
}

.card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg)
}

.card-header-pills {
    margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
    margin-left: calc(-0.5 * var(--bs-card-cap-padding-x))
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--bs-card-img-overlay-padding);
    border-radius: var(--bs-card-inner-border-radius)
}

.card-img,.card-img-bottom,.card-img-top {
    width: 100%
}

.card-img,.card-img-top {
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius)
}

.card-img,.card-img-bottom {
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius)
}

.card-group>.card {
    margin-bottom: var(--bs-card-group-margin)
}

@media (min-width: 576px) {
    .card-group {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group>.card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,.card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,.card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,.card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,.card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: var(--bs-body-bg);
    --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: var(--bs-border-width);
    --bs-accordion-border-radius: var(--bs-border-radius);
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235A6A85'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234a6ccc'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: #aec3ff;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(93,135,255,0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--bs-primary-text);
    --bs-accordion-active-bg: var(--bs-primary-bg-subtle)
}

.accordion-button {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 0.875rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    -webkit-transition: var(--bs-accordion-transition);
    transition: var(--bs-accordion-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        -webkit-transition: none;
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    -webkit-box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)
}

.accordion-button:not(.collapsed):after {
    background-image: var(--bs-accordion-btn-active-icon);
    -webkit-transform: var(--bs-accordion-btn-icon-transform);
    transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-button:after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    -webkit-transition: var(--bs-accordion-btn-icon-transition);
    transition: var(--bs-accordion-btn-icon-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button:after {
        -webkit-transition: none;
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    -webkit-box-shadow: var(--bs-accordion-btn-focus-box-shadow);
    box-shadow: var(--bs-accordion-btn-focus-box-shadow)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button,.accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0
}

[data-bs-theme=dark] .accordion-button:after {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239eb7ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239eb7ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-bg: ;
    --bs-breadcrumb-border-radius: ;
    --bs-breadcrumb-divider-color: var(--bs-secondary-color);
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius)
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: var(--bs-breadcrumb-item-padding-x)
}

.breadcrumb-item+.breadcrumb-item:before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider,"/")
}

.breadcrumb-item.active {
    color: var(--bs-breadcrumb-item-active-color)
}

.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: var(--bs-body-bg);
    --bs-pagination-border-width: var(--bs-border-width);
    --bs-pagination-border-color: var(--bs-border-color);
    --bs-pagination-border-radius: var(--bs-border-radius);
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: var(--bs-tertiary-bg);
    --bs-pagination-hover-border-color: var(--bs-border-color);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bs-secondary-bg);
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(93,135,255,0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #5D87FF;
    --bs-pagination-active-border-color: #5D87FF;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg);
    --bs-pagination-disabled-border-color: var(--bs-border-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    text-decoration: none;
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    -webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        -webkit-transition: none;
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color)
}

.page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    -webkit-box-shadow: var(--bs-pagination-focus-box-shadow);
    box-shadow: var(--bs-pagination-focus-box-shadow)
}

.active>.page-link,.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color)
}

.disabled>.page-link,.page-link.disabled {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color)
}

.page-item:not(:first-child) .page-link {
    margin-left: calc(var(--bs-border-width) * -1)
}

.page-item:first-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius)
}

.page-item:last-child .page-link {
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius)
}

.pagination-lg {
    --bs-pagination-padding-x: 1.5rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: 1.09375rem;
    --bs-pagination-border-radius: 15px
}

.pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.765625rem;
    --bs-pagination-border-radius: 5px
}

.badge {
    --bs-badge-padding-x: 10px;
    --bs-badge-padding-y: 5px;
    --bs-badge-font-size: 0.875rem;
    --bs-badge-font-weight: 400;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: 4px;
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius)
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 1rem;
    --bs-alert-padding-y: 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 7px;
    --bs-alert-link-color: inherit;
    position: relative;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius)
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 500;
    color: var(--bs-alert-link-color)
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    --bs-alert-color: var(--bs-primary-text);
    --bs-alert-bg: var(--bs-primary-bg-subtle);
    --bs-alert-border-color: var(--bs-primary-border-subtle);
    --bs-alert-link-color: var(--bs-primary-text)
}

.alert-secondary {
    --bs-alert-color: var(--bs-secondary-text);
    --bs-alert-bg: var(--bs-secondary-bg-subtle);
    --bs-alert-border-color: var(--bs-secondary-border-subtle);
    --bs-alert-link-color: var(--bs-secondary-text)
}

.alert-success {
    --bs-alert-color: var(--bs-success-text);
    --bs-alert-bg: var(--bs-success-bg-subtle);
    --bs-alert-border-color: var(--bs-success-border-subtle);
    --bs-alert-link-color: var(--bs-success-text)
}

.alert-info {
    --bs-alert-color: var(--bs-info-text);
    --bs-alert-bg: var(--bs-info-bg-subtle);
    --bs-alert-border-color: var(--bs-info-border-subtle);
    --bs-alert-link-color: var(--bs-info-text)
}

.alert-warning {
    --bs-alert-color: var(--bs-warning-text);
    --bs-alert-bg: var(--bs-warning-bg-subtle);
    --bs-alert-border-color: var(--bs-warning-border-subtle);
    --bs-alert-link-color: var(--bs-warning-text)
}

.alert-danger {
    --bs-alert-color: var(--bs-danger-text);
    --bs-alert-bg: var(--bs-danger-bg-subtle);
    --bs-alert-border-color: var(--bs-danger-border-subtle);
    --bs-alert-link-color: var(--bs-danger-text)
}

.alert-light {
    --bs-alert-color: var(--bs-light-text);
    --bs-alert-bg: var(--bs-light-bg-subtle);
    --bs-alert-border-color: var(--bs-light-border-subtle);
    --bs-alert-link-color: var(--bs-light-text)
}

.alert-dark {
    --bs-alert-color: var(--bs-dark-text);
    --bs-alert-bg: var(--bs-dark-bg-subtle);
    --bs-alert-border-color: var(--bs-dark-border-subtle);
    --bs-alert-link-color: var(--bs-dark-text)
}

.alert-muted {
    --bs-alert-color: var(--bs-muted-text);
    --bs-alert-bg: var(--bs-muted-bg-subtle);
    --bs-alert-border-color: var(--bs-muted-border-subtle);
    --bs-alert-link-color: var(--bs-muted-text)
}

.alert-indigo {
    --bs-alert-color: var(--bs-indigo-text);
    --bs-alert-bg: var(--bs-indigo-bg-subtle);
    --bs-alert-border-color: var(--bs-indigo-border-subtle);
    --bs-alert-link-color: var(--bs-indigo-text)
}

.alert-light-primary {
    --bs-alert-color: var(--bs-light-primary-text);
    --bs-alert-bg: var(--bs-light-primary-bg-subtle);
    --bs-alert-border-color: var(--bs-light-primary-border-subtle);
    --bs-alert-link-color: var(--bs-light-primary-text)
}

.alert-light-secondary {
    --bs-alert-color: var(--bs-light-secondary-text);
    --bs-alert-bg: var(--bs-light-secondary-bg-subtle);
    --bs-alert-border-color: var(--bs-light-secondary-border-subtle);
    --bs-alert-link-color: var(--bs-light-secondary-text)
}

.alert-light-info {
    --bs-alert-color: var(--bs-light-info-text);
    --bs-alert-bg: var(--bs-light-info-bg-subtle);
    --bs-alert-border-color: var(--bs-light-info-border-subtle);
    --bs-alert-link-color: var(--bs-light-info-text)
}

.alert-light-success {
    --bs-alert-color: var(--bs-light-success-text);
    --bs-alert-bg: var(--bs-light-success-bg-subtle);
    --bs-alert-border-color: var(--bs-light-success-border-subtle);
    --bs-alert-link-color: var(--bs-light-success-text)
}

.alert-light-warning {
    --bs-alert-color: var(--bs-light-warning-text);
    --bs-alert-bg: var(--bs-light-warning-bg-subtle);
    --bs-alert-border-color: var(--bs-light-warning-border-subtle);
    --bs-alert-link-color: var(--bs-light-warning-text)
}

.alert-light-danger {
    --bs-alert-color: var(--bs-light-danger-text);
    --bs-alert-bg: var(--bs-light-danger-bg-subtle);
    --bs-alert-border-color: var(--bs-light-danger-border-subtle);
    --bs-alert-link-color: var(--bs-light-danger-text)
}

.alert-light-indigo {
    --bs-alert-color: var(--bs-light-indigo-text);
    --bs-alert-bg: var(--bs-light-indigo-bg-subtle);
    --bs-alert-border-color: var(--bs-light-indigo-border-subtle);
    --bs-alert-link-color: var(--bs-light-indigo-text)
}

.alert-dark-light {
    --bs-alert-color: var(--bs-dark-light-text);
    --bs-alert-bg: var(--bs-dark-light-bg-subtle);
    --bs-alert-border-color: var(--bs-dark-light-border-subtle);
    --bs-alert-link-color: var(--bs-dark-light-text)
}

.alert-light-gray {
    --bs-alert-color: var(--bs-light-gray-text);
    --bs-alert-bg: var(--bs-light-gray-bg-subtle);
    --bs-alert-border-color: var(--bs-light-gray-border-subtle);
    --bs-alert-link-color: var(--bs-light-gray-text)
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress,.progress-stacked {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.65625rem;
    --bs-progress-bg: var(--bs-secondary-bg);
    --bs-progress-border-radius: var(--bs-border-radius);
    --bs-progress-box-shadow: var(--bs-box-shadow-inset);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #5D87FF;
    --bs-progress-bar-transition: width 0.6s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
    -webkit-box-shadow: var(--bs-progress-box-shadow);
    box-shadow: var(--bs-progress-box-shadow)
}

.progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    -webkit-transition: var(--bs-progress-bar-transition);
    transition: var(--bs-progress-bar-transition)
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        -webkit-transition: none;
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(255,255,255,0.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,0.15) 50%,rgba(255,255,255,0.15) 75%,transparent 75%,transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
}

.progress-stacked>.progress {
    overflow: visible
}

.progress-stacked>.progress>.progress-bar {
    width: 100%
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.list-group {
    --bs-list-group-color: var(--bs-body-color);
    --bs-list-group-bg: var(--bs-body-bg);
    --bs-list-group-border-color: var(--bs-border-color);
    --bs-list-group-border-width: var(--bs-border-width);
    --bs-list-group-border-radius: var(--bs-border-radius);
    --bs-list-group-item-padding-x: 1rem;
    --bs-list-group-item-padding-y: 0.5rem;
    --bs-list-group-action-color: var(--bs-secondary-color);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
    --bs-list-group-action-active-color: var(--bs-body-color);
    --bs-list-group-action-active-bg: var(--bs-secondary-bg);
    --bs-list-group-disabled-color: var(--bs-secondary-color);
    --bs-list-group-disabled-bg: var(--bs-body-bg);
    --bs-list-group-active-color: #fff;
    --bs-list-group-active-bg: #5D87FF;
    --bs-list-group-active-border-color: #5D87FF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--bs-list-group-border-radius)
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>.list-group-item:before {
    content: counters(section,".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: var(--bs-list-group-action-color);
    text-align: inherit
}

.list-group-item-action:focus,.list-group-item-action:hover {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg)
}

.list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg)
}

.list-group-item {
    position: relative;
    display: block;
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,.list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg)
}

.list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color)
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: calc(-1 * var(--bs-list-group-border-width));
    border-top-width: var(--bs-list-group-border-width)
}

.list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width)
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--bs-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--bs-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: var(--bs-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width)
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width)
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    --bs-list-group-color: var(--bs-primary-text);
    --bs-list-group-bg: var(--bs-primary-bg-subtle);
    --bs-list-group-border-color: var(--bs-primary-border-subtle)
}

.list-group-item-primary.list-group-item-action:focus,.list-group-item-primary.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle)
}

.list-group-item-primary.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-primary-text);
    --bs-list-group-active-border-color: var(--bs-primary-text)
}

.list-group-item-secondary {
    --bs-list-group-color: var(--bs-secondary-text);
    --bs-list-group-bg: var(--bs-secondary-bg-subtle);
    --bs-list-group-border-color: var(--bs-secondary-border-subtle)
}

.list-group-item-secondary.list-group-item-action:focus,.list-group-item-secondary.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle)
}

.list-group-item-secondary.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-secondary-text);
    --bs-list-group-active-border-color: var(--bs-secondary-text)
}

.list-group-item-success {
    --bs-list-group-color: var(--bs-success-text);
    --bs-list-group-bg: var(--bs-success-bg-subtle);
    --bs-list-group-border-color: var(--bs-success-border-subtle)
}

.list-group-item-success.list-group-item-action:focus,.list-group-item-success.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-success-border-subtle)
}

.list-group-item-success.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-success-text);
    --bs-list-group-active-border-color: var(--bs-success-text)
}

.list-group-item-info {
    --bs-list-group-color: var(--bs-info-text);
    --bs-list-group-bg: var(--bs-info-bg-subtle);
    --bs-list-group-border-color: var(--bs-info-border-subtle)
}

.list-group-item-info.list-group-item-action:focus,.list-group-item-info.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-info-border-subtle)
}

.list-group-item-info.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-info-text);
    --bs-list-group-active-border-color: var(--bs-info-text)
}

.list-group-item-warning {
    --bs-list-group-color: var(--bs-warning-text);
    --bs-list-group-bg: var(--bs-warning-bg-subtle);
    --bs-list-group-border-color: var(--bs-warning-border-subtle)
}

.list-group-item-warning.list-group-item-action:focus,.list-group-item-warning.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle)
}

.list-group-item-warning.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-warning-text);
    --bs-list-group-active-border-color: var(--bs-warning-text)
}

.list-group-item-danger {
    --bs-list-group-color: var(--bs-danger-text);
    --bs-list-group-bg: var(--bs-danger-bg-subtle);
    --bs-list-group-border-color: var(--bs-danger-border-subtle)
}

.list-group-item-danger.list-group-item-action:focus,.list-group-item-danger.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle)
}

.list-group-item-danger.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-danger-text);
    --bs-list-group-active-border-color: var(--bs-danger-text)
}

.list-group-item-light {
    --bs-list-group-color: var(--bs-light-text);
    --bs-list-group-bg: var(--bs-light-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-border-subtle)
}

.list-group-item-light.list-group-item-action:focus,.list-group-item-light.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-border-subtle)
}

.list-group-item-light.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-text);
    --bs-list-group-active-border-color: var(--bs-light-text)
}

.list-group-item-dark {
    --bs-list-group-color: var(--bs-dark-text);
    --bs-list-group-bg: var(--bs-dark-bg-subtle);
    --bs-list-group-border-color: var(--bs-dark-border-subtle)
}

.list-group-item-dark.list-group-item-action:focus,.list-group-item-dark.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle)
}

.list-group-item-dark.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-dark-text);
    --bs-list-group-active-border-color: var(--bs-dark-text)
}

.list-group-item-muted {
    --bs-list-group-color: var(--bs-muted-text);
    --bs-list-group-bg: var(--bs-muted-bg-subtle);
    --bs-list-group-border-color: var(--bs-muted-border-subtle)
}

.list-group-item-muted.list-group-item-action:focus,.list-group-item-muted.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-muted-border-subtle)
}

.list-group-item-muted.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-muted-text);
    --bs-list-group-active-border-color: var(--bs-muted-text)
}

.list-group-item-indigo {
    --bs-list-group-color: var(--bs-indigo-text);
    --bs-list-group-bg: var(--bs-indigo-bg-subtle);
    --bs-list-group-border-color: var(--bs-indigo-border-subtle)
}

.list-group-item-indigo.list-group-item-action:focus,.list-group-item-indigo.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-indigo-border-subtle)
}

.list-group-item-indigo.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-indigo-text);
    --bs-list-group-active-border-color: var(--bs-indigo-text)
}

.list-group-item-light-primary {
    --bs-list-group-color: var(--bs-light-primary-text);
    --bs-list-group-bg: var(--bs-light-primary-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-primary-border-subtle)
}

.list-group-item-light-primary.list-group-item-action:focus,.list-group-item-light-primary.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-primary-border-subtle)
}

.list-group-item-light-primary.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-primary-text);
    --bs-list-group-active-border-color: var(--bs-light-primary-text)
}

.list-group-item-light-secondary {
    --bs-list-group-color: var(--bs-light-secondary-text);
    --bs-list-group-bg: var(--bs-light-secondary-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-secondary-border-subtle)
}

.list-group-item-light-secondary.list-group-item-action:focus,.list-group-item-light-secondary.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-secondary-border-subtle)
}

.list-group-item-light-secondary.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-secondary-text);
    --bs-list-group-active-border-color: var(--bs-light-secondary-text)
}

.list-group-item-light-info {
    --bs-list-group-color: var(--bs-light-info-text);
    --bs-list-group-bg: var(--bs-light-info-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-info-border-subtle)
}

.list-group-item-light-info.list-group-item-action:focus,.list-group-item-light-info.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-info-border-subtle)
}

.list-group-item-light-info.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-info-text);
    --bs-list-group-active-border-color: var(--bs-light-info-text)
}

.list-group-item-light-success {
    --bs-list-group-color: var(--bs-light-success-text);
    --bs-list-group-bg: var(--bs-light-success-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-success-border-subtle)
}

.list-group-item-light-success.list-group-item-action:focus,.list-group-item-light-success.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-success-border-subtle)
}

.list-group-item-light-success.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-success-text);
    --bs-list-group-active-border-color: var(--bs-light-success-text)
}

.list-group-item-light-warning {
    --bs-list-group-color: var(--bs-light-warning-text);
    --bs-list-group-bg: var(--bs-light-warning-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-warning-border-subtle)
}

.list-group-item-light-warning.list-group-item-action:focus,.list-group-item-light-warning.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-warning-border-subtle)
}

.list-group-item-light-warning.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-warning-text);
    --bs-list-group-active-border-color: var(--bs-light-warning-text)
}

.list-group-item-light-danger {
    --bs-list-group-color: var(--bs-light-danger-text);
    --bs-list-group-bg: var(--bs-light-danger-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-danger-border-subtle)
}

.list-group-item-light-danger.list-group-item-action:focus,.list-group-item-light-danger.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-danger-border-subtle)
}

.list-group-item-light-danger.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-danger-text);
    --bs-list-group-active-border-color: var(--bs-light-danger-text)
}

.list-group-item-light-indigo {
    --bs-list-group-color: var(--bs-light-indigo-text);
    --bs-list-group-bg: var(--bs-light-indigo-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-indigo-border-subtle)
}

.list-group-item-light-indigo.list-group-item-action:focus,.list-group-item-light-indigo.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-indigo-border-subtle)
}

.list-group-item-light-indigo.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-indigo-text);
    --bs-list-group-active-border-color: var(--bs-light-indigo-text)
}

.list-group-item-dark-light {
    --bs-list-group-color: var(--bs-dark-light-text);
    --bs-list-group-bg: var(--bs-dark-light-bg-subtle);
    --bs-list-group-border-color: var(--bs-dark-light-border-subtle)
}

.list-group-item-dark-light.list-group-item-action:focus,.list-group-item-dark-light.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-dark-light-border-subtle)
}

.list-group-item-dark-light.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-dark-light-text);
    --bs-list-group-active-border-color: var(--bs-dark-light-text)
}

.list-group-item-light-gray {
    --bs-list-group-color: var(--bs-light-gray-text);
    --bs-list-group-bg: var(--bs-light-gray-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-gray-border-subtle)
}

.list-group-item-light-gray.list-group-item-action:focus,.list-group-item-light-gray.list-group-item-action:hover {
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-gray-border-subtle)
}

.list-group-item-light-gray.list-group-item-action:active {
    --bs-list-group-active-color: var(--bs-emphasis-color);
    --bs-list-group-active-bg: var(--bs-light-gray-text);
    --bs-list-group-active-border-color: var(--bs-light-gray-text)
}

.btn-close {
    --bs-btn-close-color: #000;
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(93,135,255,0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: 7px;
    opacity: var(--bs-btn-close-opacity)
}

.btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity)
}

.btn-close:focus {
    outline: 0;
    -webkit-box-shadow: var(--bs-btn-close-focus-shadow);
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity)
}

.btn-close.disabled,.btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity)
}

.btn-close-white {
    -webkit-filter: var(--bs-btn-close-white-filter);
    filter: var(--bs-btn-close-white-filter)
}

[data-bs-theme=dark] .btn-close {
    -webkit-filter: var(--bs-btn-close-white-filter);
    filter: var(--bs-btn-close-white-filter)
}

.toast {
    --bs-toast-zindex: 1090;
    --bs-toast-padding-x: 0.75rem;
    --bs-toast-padding-y: 0.5rem;
    --bs-toast-spacing: 24px;
    --bs-toast-max-width: 350px;
    --bs-toast-font-size: 0.875rem;
    --bs-toast-color: ;
    --bs-toast-bg: rgba(var(--bs-body-bg-rgb),0.85);
    --bs-toast-border-width: var(--bs-border-width);
    --bs-toast-border-color: var(--bs-border-color-translucent);
    --bs-toast-border-radius: var(--bs-border-radius);
    --bs-toast-box-shadow: var(--bs-box-shadow);
    --bs-toast-header-color: var(--bs-secondary-color);
    --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb),0.85);
    --bs-toast-header-border-color: var(--bs-border-color-translucent);
    width: var(--bs-toast-max-width);
    max-width: 100%;
    font-size: var(--bs-toast-font-size);
    color: var(--bs-toast-color);
    pointer-events: auto;
    background-color: var(--bs-toast-bg);
    background-clip: padding-box;
    border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
    -webkit-box-shadow: var(--bs-toast-box-shadow);
    box-shadow: var(--bs-toast-box-shadow);
    border-radius: var(--bs-toast-border-radius)
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    --bs-toast-zindex: 1090;
    position: absolute;
    z-index: var(--bs-toast-zindex);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: var(--bs-toast-spacing)
}

.toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
    color: var(--bs-toast-header-color);
    background-color: var(--bs-toast-header-bg);
    background-clip: padding-box;
    border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
    border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
    border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width))
}

.toast-header .btn-close {
    margin-right: calc(-0.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x)
}

.toast-body {
    padding: var(--bs-toast-padding-x);
    word-wrap: break-word
}

.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 600px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: var(--bs-body-bg);
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 0;
    --bs-modal-border-radius: var(--bs-border-radius-lg);
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb),0.075);
    --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - 0);
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 0;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out,-webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2)
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    -webkit-box-shadow: var(--bs-modal-box-shadow);
    box-shadow: var(--bs-modal-box-shadow);
    outline: 0
}

.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity)
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius)
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height)
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding)
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius)
}

.modal-footer>* {
    margin: calc(var(--bs-modal-footer-gap) * 0.5)
}

@media (min-width: 576px) {
    .modal {
        --bs-modal-margin:1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb),0.15)
    }

    .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto
    }

    .modal-sm {
        --bs-modal-width: 300px
    }
}

@media (min-width: 992px) {
    .modal-lg,.modal-xl {
        --bs-modal-width:800px
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        --bs-modal-width:1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-footer,.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-footer,.modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-footer,.modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-footer,.modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-footer,.modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-footer,.modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }
}

.tooltip {
    --bs-tooltip-zindex: 1080;
    --bs-tooltip-max-width: 200px;
    --bs-tooltip-padding-x: 0.5rem;
    --bs-tooltip-padding-y: 0.25rem;
    --bs-tooltip-margin: ;
    --bs-tooltip-font-size: 0.765625rem;
    --bs-tooltip-color: var(--bs-body-bg);
    --bs-tooltip-bg: var(--bs-emphasis-color);
    --bs-tooltip-border-radius: var(--bs-border-radius);
    --bs-tooltip-opacity: 0.9;
    --bs-tooltip-arrow-width: 0.8rem;
    --bs-tooltip-arrow-height: 0.4rem;
    z-index: var(--bs-tooltip-zindex);
    display: block;
    padding: var(--bs-tooltip-arrow-height);
    margin: var(--bs-tooltip-margin);
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--bs-tooltip-font-size);
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: var(--bs-tooltip-opacity)
}

.tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height)
}

.tooltip .tooltip-arrow:before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,.bs-tooltip-top .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before,.bs-tooltip-top .tooltip-arrow:before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
    border-top-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,.bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow:before,.bs-tooltip-end .tooltip-arrow:before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
    border-right-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,.bs-tooltip-bottom .tooltip-arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before,.bs-tooltip-bottom .tooltip-arrow:before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,.bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: var(--bs-tooltip-arrow-height);
    height: var(--bs-tooltip-arrow-width)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow:before,.bs-tooltip-start .tooltip-arrow:before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg)
}

.tooltip-inner {
    max-width: var(--bs-tooltip-max-width);
    padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
    color: var(--bs-tooltip-color);
    text-align: center;
    background-color: var(--bs-tooltip-bg);
    border-radius: var(--bs-tooltip-border-radius)
}

.popover {
    --bs-popover-zindex: 1070;
    --bs-popover-max-width: 276px;
    --bs-popover-font-size: 0.765625rem;
    --bs-popover-bg: var(--bs-body-bg);
    --bs-popover-border-width: var(--bs-border-width);
    --bs-popover-border-color: var(--bs-border-color-translucent);
    --bs-popover-border-radius: var(--bs-border-radius-lg);
    --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
    --bs-popover-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb),0.15);
    --bs-popover-header-padding-x: 1rem;
    --bs-popover-header-padding-y: 0.5rem;
    --bs-popover-header-font-size: 0.875rem;
    --bs-popover-header-color: #2A3547;
    --bs-popover-header-bg: var(--bs-secondary-bg);
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: 1rem;
    --bs-popover-body-color: var(--bs-body-color);
    --bs-popover-arrow-width: 1rem;
    --bs-popover-arrow-height: 0.5rem;
    --bs-popover-arrow-border: var(--bs-popover-border-color);
    z-index: var(--bs-popover-zindex);
    display: block;
    max-width: var(--bs-popover-max-width);
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--bs-popover-font-size);
    word-wrap: break-word;
    background-color: var(--bs-popover-bg);
    background-clip: padding-box;
    border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-radius: var(--bs-popover-border-radius);
    -webkit-box-shadow: var(--bs-popover-box-shadow);
    box-shadow: var(--bs-popover-box-shadow)
}

.popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height)
}

.popover .popover-arrow:after,.popover .popover-arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,.bs-popover-top>.popover-arrow {
    bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:after,.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:before,.bs-popover-top>.popover-arrow:after,.bs-popover-top>.popover-arrow:before {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:before,.bs-popover-top>.popover-arrow:before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:after,.bs-popover-top>.popover-arrow:after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,.bs-popover-end>.popover-arrow {
    left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:after,.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:before,.bs-popover-end>.popover-arrow:after,.bs-popover-end>.popover-arrow:before {
    border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:before,.bs-popover-end>.popover-arrow:before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:after,.bs-popover-end>.popover-arrow:after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,.bs-popover-bottom>.popover-arrow {
    top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:after,.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:before,.bs-popover-bottom>.popover-arrow:after,.bs-popover-bottom>.popover-arrow:before {
    border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:before,.bs-popover-bottom>.popover-arrow:before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:after,.bs-popover-bottom>.popover-arrow:after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg)
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header:before,.bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--bs-popover-arrow-width);
    margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
    content: "";
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,.bs-popover-start>.popover-arrow {
    right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
    width: var(--bs-popover-arrow-height);
    height: var(--bs-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:after,.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:before,.bs-popover-start>.popover-arrow:after,.bs-popover-start>.popover-arrow:before {
    border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:before,.bs-popover-start>.popover-arrow:before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:after,.bs-popover-start>.popover-arrow:after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg)
}

.popover-header {
    padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
    margin-bottom: 0;
    font-size: var(--bs-popover-header-font-size);
    color: var(--bs-popover-header-color);
    background-color: var(--bs-popover-header-bg);
    border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
    border-top-left-radius: var(--bs-popover-inner-border-radius);
    border-top-right-radius: var(--bs-popover-inner-border-radius)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
    color: var(--bs-popover-body-color)
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner:after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out,-webkit-transform 0.6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-item-next,.carousel-item-prev,.carousel-item.active {
    display: block
}

.active.carousel-item-end,.carousel-item-next:not(.carousel-item-start) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.active.carousel-item-start,.carousel-item-prev:not(.carousel-item-end) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,.carousel-fade .carousel-item-prev.carousel-item-end,.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s 0.6s;
    transition: opacity 0s 0.6s
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-control-next,.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-control-next,.carousel-control-prev {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-control-next:focus,.carousel-control-next:hover,.carousel-control-prev:focus,.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,.carousel-dark .carousel-control-prev-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

[data-bs-theme=dark] .carousel .carousel-control-next-icon,[data-bs-theme=dark] .carousel .carousel-control-prev-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100)
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target] {
    background-color: #000
}

[data-bs-theme=dark] .carousel .carousel-caption {
    color: #000
}

.spinner-border,.spinner-grow {
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    -webkit-animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-border;
    border: var(--bs-spinner-border-width) solid currentcolor;
    border-right-color: transparent
}

.spinner-border-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    --bs-spinner-border-width: 0.2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .spinner-border,.spinner-grow {
        --bs-spinner-animation-speed: 1.5s
    }
}

.offcanvas,.offcanvas-lg,.offcanvas-md,.offcanvas-sm,.offcanvas-xl,.offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 400px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: var(--bs-body-color);
    --bs-offcanvas-bg: var(--bs-body-bg);
    --bs-offcanvas-border-width: 0;
    --bs-offcanvas-border-color: var(--bs-border-color-translucent);
    --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb),0.075);
    --bs-offcanvas-transition: transform 0.3s ease-in-out;
    --bs-offcanvas-title-line-height: 1.5
}

@media (max-width: 575.98px) {
    .offcanvas-sm {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-box-shadow: var(--bs-offcanvas-box-shadow);
        box-shadow: var(--bs-offcanvas-box-shadow);
        -webkit-transition: var(--bs-offcanvas-transition);
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 575.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-sm {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.show:not(.hiding),.offcanvas-sm.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.hiding,.offcanvas-sm.show,.offcanvas-sm.showing {
        visibility:visible
    }
}

@media (min-width: 576px) {
    .offcanvas-sm {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-sm .offcanvas-header {
        display: none
    }

    .offcanvas-sm .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-box-shadow: var(--bs-offcanvas-box-shadow);
        box-shadow: var(--bs-offcanvas-box-shadow);
        -webkit-transition: var(--bs-offcanvas-transition);
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 767.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-md {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.show:not(.hiding),.offcanvas-md.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.hiding,.offcanvas-md.show,.offcanvas-md.showing {
        visibility:visible
    }
}

@media (min-width: 768px) {
    .offcanvas-md {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-md .offcanvas-header {
        display: none
    }

    .offcanvas-md .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-box-shadow: var(--bs-offcanvas-box-shadow);
        box-shadow: var(--bs-offcanvas-box-shadow);
        -webkit-transition: var(--bs-offcanvas-transition);
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 991.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-lg {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.show:not(.hiding),.offcanvas-lg.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.hiding,.offcanvas-lg.show,.offcanvas-lg.showing {
        visibility:visible
    }
}

@media (min-width: 992px) {
    .offcanvas-lg {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-lg .offcanvas-header {
        display: none
    }

    .offcanvas-lg .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-box-shadow: var(--bs-offcanvas-box-shadow);
        box-shadow: var(--bs-offcanvas-box-shadow);
        -webkit-transition: var(--bs-offcanvas-transition);
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xl {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.show:not(.hiding),.offcanvas-xl.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.hiding,.offcanvas-xl.show,.offcanvas-xl.showing {
        visibility:visible
    }
}

@media (min-width: 1200px) {
    .offcanvas-xl {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-xl .offcanvas-header {
        display: none
    }

    .offcanvas-xl .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl {
        position:fixed;
        bottom: 0;
        z-index: var(--bs-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--bs-offcanvas-color);
        visibility: hidden;
        background-color: var(--bs-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-box-shadow: var(--bs-offcanvas-box-shadow);
        box-shadow: var(--bs-offcanvas-box-shadow);
        -webkit-transition: var(--bs-offcanvas-transition);
        transition: var(--bs-offcanvas-transition)
    }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xxl {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
        top:0;
        left: 0;
        width: var(--bs-offcanvas-width);
        border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-end {
        top:0;
        right: 0;
        width: var(--bs-offcanvas-width);
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--bs-offcanvas-height);
        max-height: 100%;
        border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.show:not(.hiding),.offcanvas-xxl.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.hiding,.offcanvas-xxl.show,.offcanvas-xxl.showing {
        visibility:visible
    }
}

@media (min-width: 1400px) {
    .offcanvas-xxl {
        --bs-offcanvas-height:auto;
        --bs-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-xxl .offcanvas-header {
        display: none
    }

    .offcanvas-xxl .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    -webkit-box-shadow: var(--bs-offcanvas-box-shadow);
    box-shadow: var(--bs-offcanvas-box-shadow);
    -webkit-transition: var(--bs-offcanvas-transition);
    transition: var(--bs-offcanvas-transition)
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        -webkit-transition: none;
        transition: none
    }
}

.offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.offcanvas.show:not(.hiding),.offcanvas.showing {
    -webkit-transform: none;
    transform: none
}

.offcanvas.hiding,.offcanvas.show,.offcanvas.showing {
    visibility: visible
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: 0.5
}

.offcanvas-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
}

.offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
    margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y))
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: var(--bs-offcanvas-title-line-height)
}

.offcanvas-body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    overflow-y: auto
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.5
}

.placeholder.btn:before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: 0.6em
}

.placeholder-sm {
    min-height: 0.8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: 0.2
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.2
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,0.8) 75%,#000 95%);
    mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,0.8) 75%,#000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite
}

@-webkit-keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}

@keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%
    }
}

.clearfix:after {
    display: block;
    clear: both;
    content: ""
}

.text-bg-primary {
    color: #fff!important;
    background-color: RGBA(93,135,255,var(--bs-bg-opacity,1))!important
}

.text-bg-secondary {
    color: #fff!important;
    background-color: RGBA(73,190,255,var(--bs-bg-opacity,1))!important
}

.text-bg-success {
    color: #fff!important;
    background-color: RGBA(19,222,185,var(--bs-bg-opacity,1))!important
}

.text-bg-info {
    color: #fff!important;
    background-color: RGBA(83,155,255,var(--bs-bg-opacity,1))!important
}

.text-bg-warning {
    color: #fff!important;
    background-color: RGBA(255,174,31,var(--bs-bg-opacity,1))!important
}

.text-bg-danger {
    color: #fff!important;
    background-color: RGBA(250,137,107,var(--bs-bg-opacity,1))!important
}

.text-bg-light {
    color: #000!important;
    background-color: RGBA(246,249,252,var(--bs-bg-opacity,1))!important
}

.text-bg-dark {
    color: #fff!important;
    background-color: RGBA(42,53,71,var(--bs-bg-opacity,1))!important
}

.text-bg-muted {
    color: #fff!important;
    background-color: RGBA(90,106,133,var(--bs-bg-opacity,1))!important
}

.text-bg-indigo {
    color: #fff!important;
    background-color: RGBA(102,16,242,var(--bs-bg-opacity,1))!important
}

.text-bg-light-primary {
    color: #000!important;
    background-color: RGBA(236,242,255,var(--bs-bg-opacity,1))!important
}

.text-bg-light-secondary {
    color: #000!important;
    background-color: RGBA(232,247,255,var(--bs-bg-opacity,1))!important
}

.text-bg-light-info {
    color: #000!important;
    background-color: RGBA(235,243,254,var(--bs-bg-opacity,1))!important
}

.text-bg-light-success {
    color: #000!important;
    background-color: RGBA(230,255,250,var(--bs-bg-opacity,1))!important
}

.text-bg-light-warning {
    color: #000!important;
    background-color: RGBA(254,245,229,var(--bs-bg-opacity,1))!important
}

.text-bg-light-danger {
    color: #000!important;
    background-color: RGBA(251,242,239,var(--bs-bg-opacity,1))!important
}

.text-bg-light-indigo {
    color: #000!important;
    background-color: RGBA(235,243,254,var(--bs-bg-opacity,1))!important
}

.text-bg-dark-light {
    color: #fff!important;
    background-color: RGBA(42,53,71,var(--bs-bg-opacity,1))!important
}

.text-bg-light-gray {
    color: #000!important;
    background-color: RGBA(246,249,252,var(--bs-bg-opacity,1))!important
}

.link-primary {
    color: #5D87FF!important
}

.link-primary:focus,.link-primary:hover {
    color: #4a6ccc!important
}

.link-secondary {
    color: #49BEFF!important
}

.link-secondary:focus,.link-secondary:hover {
    color: #3a98cc!important
}

.link-success {
    color: #13DEB9!important
}

.link-success:focus,.link-success:hover {
    color: #0fb294!important
}

.link-info {
    color: #539BFF!important
}

.link-info:focus,.link-info:hover {
    color: #427ccc!important
}

.link-warning {
    color: #FFAE1F!important
}

.link-warning:focus,.link-warning:hover {
    color: #cc8b19!important
}

.link-danger {
    color: #FA896B!important
}

.link-danger:focus,.link-danger:hover {
    color: #c86e56!important
}

.link-light {
    color: #F6F9FC!important
}

.link-light:focus,.link-light:hover {
    color: #f8fafd!important
}

.link-dark {
    color: #2A3547!important
}

.link-dark:focus,.link-dark:hover {
    color: #222a39!important
}

.link-muted {
    color: #5A6A85!important
}

.link-muted:focus,.link-muted:hover {
    color: #48556a!important
}

.link-indigo {
    color: #6610f2!important
}

.link-indigo:focus,.link-indigo:hover {
    color: #520dc2!important
}

.link-light-primary {
    color: #ECF2FF!important
}

.link-light-primary:focus,.link-light-primary:hover {
    color: #f0f5ff!important
}

.link-light-secondary {
    color: #E8F7FF!important
}

.link-light-secondary:focus,.link-light-secondary:hover {
    color: #edf9ff!important
}

.link-light-info {
    color: #EBF3FE!important
}

.link-light-info:focus,.link-light-info:hover {
    color: #eff5fe!important
}

.link-light-success {
    color: #E6FFFA!important
}

.link-light-success:focus,.link-light-success:hover {
    color: #ebfffb!important
}

.link-light-warning {
    color: #FEF5E5!important
}

.link-light-warning:focus,.link-light-warning:hover {
    color: #fef7ea!important
}

.link-light-danger {
    color: #FBF2EF!important
}

.link-light-danger:focus,.link-light-danger:hover {
    color: #fcf5f2!important
}

.link-light-indigo {
    color: #EBF3FE!important
}

.link-light-indigo:focus,.link-light-indigo:hover {
    color: #eff5fe!important
}

.link-dark-light {
    color: #2A3547!important
}

.link-dark-light:focus,.link-dark-light:hover {
    color: #222a39!important
}

.link-light-gray {
    color: #F6F9FC!important
}

.link-light-gray:focus,.link-light-gray:hover {
    color: #f8fafd!important
}

.ratio {
    position: relative;
    width: 100%
}

.ratio:before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: 75%
}

.ratio-16x9 {
    --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020
}

@media (min-width: 576px) {
    .sticky-sm-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-sm-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 768px) {
    .sticky-md-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-md-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 992px) {
    .sticky-lg-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-lg-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 1200px) {
    .sticky-xl-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 1400px) {
    .sticky-xxl-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xxl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

.hstack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch
}

.vstack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch
}

.visually-hidden,.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important
}

.stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: 0.25
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.float-start {
    float: left!important
}

.float-end {
    float: right!important
}

.float-none {
    float: none!important
}

.object-fit-contain {
    -o-object-fit: contain!important;
    object-fit: contain!important
}

.object-fit-cover {
    -o-object-fit: cover!important;
    object-fit: cover!important
}

.object-fit-fill {
    -o-object-fit: fill!important;
    object-fit: fill!important
}

.object-fit-scale {
    -o-object-fit: scale-down!important;
    object-fit: scale-down!important
}

.object-fit-none {
    -o-object-fit: none!important;
    object-fit: none!important
}

.opacity-0 {
    opacity: 0!important
}

.opacity-25 {
    opacity: 0.25!important
}

.opacity-50 {
    opacity: 0.5!important
}

.opacity-75 {
    opacity: 0.75!important
}

.opacity-100 {
    opacity: 1!important
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.overflow-visible {
    overflow: visible!important
}

.overflow-scroll {
    overflow: scroll!important
}

.overflow-x-auto {
    overflow-x: auto!important
}

.overflow-x-hidden {
    overflow-x: hidden!important
}

.overflow-x-visible {
    overflow-x: visible!important
}

.overflow-x-scroll {
    overflow-x: scroll!important
}

.overflow-y-auto {
    overflow-y: auto!important
}

.overflow-y-hidden {
    overflow-y: hidden!important
}

.overflow-y-visible {
    overflow-y: visible!important
}

.overflow-y-scroll {
    overflow-y: scroll!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-grid {
    display: -ms-grid!important;
    display: grid!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important
}

.d-inline-flex {
    display: -webkit-inline-box!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
}

.d-none {
    display: none!important
}

.shadow {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb),0.15)!important;
    box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb),0.15)!important
}

.shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb),0.075)!important;
    box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb),0.075)!important
}

.shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(var(--bs-body-color-rgb),0.175)!important;
    box-shadow: 0 1rem 3rem rgba(var(--bs-body-color-rgb),0.175)!important
}

.shadow-none {
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: sticky!important
}

.top-0 {
    top: 0!important
}

.top-50 {
    top: 50%!important
}

.top-100 {
    top: 100%!important
}

.bottom-0 {
    bottom: 0!important
}

.bottom-50 {
    bottom: 50%!important
}

.bottom-100 {
    bottom: 100%!important
}

.start-0 {
    left: 0!important
}

.start-50 {
    left: 50%!important
}

.start-100 {
    left: 100%!important
}

.end-0 {
    right: 0!important
}

.end-50 {
    right: 50%!important
}

.end-100 {
    right: 100%!important
}

.translate-middle {
    -webkit-transform: translate(-50%,-50%)!important;
    transform: translate(-50%,-50%)!important
}

.translate-middle-x {
    -webkit-transform: translateX(-50%)!important;
    transform: translateX(-50%)!important
}

.translate-middle-y {
    -webkit-transform: translateY(-50%)!important;
    transform: translateY(-50%)!important
}

.border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-0 {
    border: 0!important
}

.border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-top-0 {
    border-top: 0!important
}

.border-end {
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-end-0 {
    border-right: 0!important
}

.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
}

.border-start-0 {
    border-left: 0!important
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-primary-rgb),var(--bs-border-opacity))!important
}

.border-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-secondary-rgb),var(--bs-border-opacity))!important
}

.border-success {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-success-rgb),var(--bs-border-opacity))!important
}

.border-info {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-info-rgb),var(--bs-border-opacity))!important
}

.border-warning {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-warning-rgb),var(--bs-border-opacity))!important
}

.border-danger {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb),var(--bs-border-opacity))!important
}

.border-light {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-rgb),var(--bs-border-opacity))!important
}

.border-dark {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb),var(--bs-border-opacity))!important
}

.border-muted {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-muted-rgb),var(--bs-border-opacity))!important
}

.border-indigo {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-indigo-rgb),var(--bs-border-opacity))!important
}

.border-light-primary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-primary-rgb),var(--bs-border-opacity))!important
}

.border-light-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-secondary-rgb),var(--bs-border-opacity))!important
}

.border-light-info {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-info-rgb),var(--bs-border-opacity))!important
}

.border-light-success {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-success-rgb),var(--bs-border-opacity))!important
}

.border-light-warning {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-warning-rgb),var(--bs-border-opacity))!important
}

.border-light-danger {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-danger-rgb),var(--bs-border-opacity))!important
}

.border-light-indigo {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-indigo-rgb),var(--bs-border-opacity))!important
}

.border-dark-light {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-light-rgb),var(--bs-border-opacity))!important
}

.border-light-gray {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-gray-rgb),var(--bs-border-opacity))!important
}

.border-white {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-white-rgb),var(--bs-border-opacity))!important
}

.border-primary-subtle {
    border-color: var(--bs-primary-border-subtle)!important
}

.border-secondary-subtle {
    border-color: var(--bs-secondary-border-subtle)!important
}

.border-success-subtle {
    border-color: var(--bs-success-border-subtle)!important
}

.border-info-subtle {
    border-color: var(--bs-info-border-subtle)!important
}

.border-warning-subtle {
    border-color: var(--bs-warning-border-subtle)!important
}

.border-danger-subtle {
    border-color: var(--bs-danger-border-subtle)!important
}

.border-light-subtle {
    border-color: var(--bs-light-border-subtle)!important
}

.border-dark-subtle {
    border-color: var(--bs-dark-border-subtle)!important
}

.border-1 {
    --bs-border-width: 1px
}

.border-2 {
    --bs-border-width: 2px
}

.border-3 {
    --bs-border-width: 3px
}

.border-4 {
    --bs-border-width: 4px
}

.border-5 {
    --bs-border-width: 5px
}

.border-opacity-10 {
    --bs-border-opacity: 0.1
}

.border-opacity-25 {
    --bs-border-opacity: 0.25
}

.border-opacity-50 {
    --bs-border-opacity: 0.5
}

.border-opacity-75 {
    --bs-border-opacity: 0.75
}

.border-opacity-100 {
    --bs-border-opacity: 1
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.mw-100 {
    max-width: 100%!important
}

.vw-100 {
    width: 100vw!important
}

.min-vw-100 {
    min-width: 100vw!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.mh-100 {
    max-height: 100%!important
}

.vh-100 {
    height: 100vh!important
}

.min-vh-100 {
    min-height: 100vh!important
}

.flex-fill {
    -webkit-box-flex: 1!important;
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important
}

.flex-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
}

.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
}

.flex-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
}

.flex-grow-0 {
    -webkit-box-flex: 0!important;
    -ms-flex-positive: 0!important;
    flex-grow: 0!important
}

.flex-grow-1 {
    -webkit-box-flex: 1!important;
    -ms-flex-positive: 1!important;
    flex-grow: 1!important
}

.flex-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0!important
}

.flex-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1!important
}

.flex-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
}

.justify-content-start {
    -webkit-box-pack: start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
}

.justify-content-end {
    -webkit-box-pack: end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
}

.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
}

.justify-content-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
}

.justify-content-evenly {
    -webkit-box-pack: space-evenly!important;
    -ms-flex-pack: space-evenly!important;
    justify-content: space-evenly!important
}

.align-items-start {
    -webkit-box-align: start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
}

.align-items-end {
    -webkit-box-align: end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
}

.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
}

.align-items-baseline {
    -webkit-box-align: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
}

.align-items-stretch {
    -webkit-box-align: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
}

.align-content-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
}

.align-content-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
}

.align-content-center {
    -ms-flex-line-pack: center!important;
    align-content: center!important
}

.align-content-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
}

.align-content-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
}

.align-self-auto {
    -ms-flex-item-align: auto!important;
    -ms-grid-row-align: auto!important;
    align-self: auto!important
}

.align-self-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
}

.align-self-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
}

.align-self-center {
    -ms-flex-item-align: center!important;
    -ms-grid-row-align: center!important;
    align-self: center!important
}

.align-self-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
}

.align-self-stretch {
    -ms-flex-item-align: stretch!important;
    -ms-grid-row-align: stretch!important;
    align-self: stretch!important
}

.order-first {
    -webkit-box-ordinal-group: 0!important;
    -ms-flex-order: -1!important;
    order: -1!important
}

.order-0 {
    -webkit-box-ordinal-group: 1!important;
    -ms-flex-order: 0!important;
    order: 0!important
}

.order-1 {
    -webkit-box-ordinal-group: 2!important;
    -ms-flex-order: 1!important;
    order: 1!important
}

.order-2 {
    -webkit-box-ordinal-group: 3!important;
    -ms-flex-order: 2!important;
    order: 2!important
}

.order-3 {
    -webkit-box-ordinal-group: 4!important;
    -ms-flex-order: 3!important;
    order: 3!important
}

.order-4 {
    -webkit-box-ordinal-group: 5!important;
    -ms-flex-order: 4!important;
    order: 4!important
}

.order-5 {
    -webkit-box-ordinal-group: 6!important;
    -ms-flex-order: 5!important;
    order: 5!important
}

.order-last {
    -webkit-box-ordinal-group: 7!important;
    -ms-flex-order: 6!important;
    order: 6!important
}

.m-0 {
    margin: 0!important
}

.m-1 {
    margin: 0.25rem!important
}

.m-2 {
    margin: 0.5rem!important
}

.m-3 {
    margin: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.m-6 {
    margin: 12px!important
}

.m-7 {
    margin: 30px!important
}

.m-8 {
    margin: 10px!important
}

.m-9 {
    margin: 20px!important
}

.m-auto {
    margin: auto!important
}

.mx-0 {
    margin-right: 0!important;
    margin-left: 0!important
}

.mx-1 {
    margin-right: 0.25rem!important;
    margin-left: 0.25rem!important
}

.mx-2 {
    margin-right: 0.5rem!important;
    margin-left: 0.5rem!important
}

.mx-3 {
    margin-right: 1rem!important;
    margin-left: 1rem!important
}

.mx-4 {
    margin-right: 1.5rem!important;
    margin-left: 1.5rem!important
}

.mx-5 {
    margin-right: 3rem!important;
    margin-left: 3rem!important
}

.mx-6 {
    margin-right: 12px!important;
    margin-left: 12px!important
}

.mx-7 {
    margin-right: 30px!important;
    margin-left: 30px!important
}

.mx-8 {
    margin-right: 10px!important;
    margin-left: 10px!important
}

.mx-9 {
    margin-right: 20px!important;
    margin-left: 20px!important
}

.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important
}

.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important
}

.my-1 {
    margin-top: 0.25rem!important;
    margin-bottom: 0.25rem!important
}

.my-2 {
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important
}

.my-3 {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important
}

.my-4 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important
}

.my-5 {
    margin-top: 3rem!important;
    margin-bottom: 3rem!important
}

.my-6 {
    margin-top: 12px!important;
    margin-bottom: 12px!important
}

.my-7 {
    margin-top: 30px!important;
    margin-bottom: 30px!important
}

.my-8 {
    margin-top: 10px!important;
    margin-bottom: 10px!important
}

.my-9 {
    margin-top: 20px!important;
    margin-bottom: 20px!important
}

.my-auto {
    margin-top: auto!important;
    margin-bottom: auto!important
}

.mt-0 {
    margin-top: 0!important
}

.mt-1 {
    margin-top: 0.25rem!important
}

.mt-2 {
    margin-top: 0.5rem!important
}

.mt-3 {
    margin-top: 1rem!important
}

.mt-4 {
    margin-top: 1.5rem!important
}

.mt-5 {
    margin-top: 3rem!important
}

.mt-6 {
    margin-top: 12px!important
}

.mt-7 {
    margin-top: 30px!important
}

.mt-8 {
    margin-top: 10px!important
}

.mt-9 {
    margin-top: 20px!important
}

.mt-auto {
    margin-top: auto!important
}

.me-0 {
    margin-right: 0!important
}

.me-1 {
    margin-right: 0.25rem!important
}

.me-2 {
    margin-right: 0.5rem!important
}

.me-3 {
    margin-right: 1rem!important
}

.me-4 {
    margin-right: 1.5rem!important
}

.me-5 {
    margin-right: 3rem!important
}

.me-6 {
    margin-right: 12px!important
}

.me-7 {
    margin-right: 30px!important
}

.me-8 {
    margin-right: 10px!important
}

.me-9 {
    margin-right: 20px!important
}

.me-auto {
    margin-right: auto!important
}

.mb-0 {
    margin-bottom: 0!important
}

.mb-1 {
    margin-bottom: 0.25rem!important
}

.mb-2 {
    margin-bottom: 0.5rem!important
}

.mb-3 {
    margin-bottom: 1rem!important
}

.mb-4 {
    margin-bottom: 1.5rem!important
}

.mb-5 {
    margin-bottom: 3rem!important
}

.mb-6 {
    margin-bottom: 12px!important
}

.mb-7 {
    margin-bottom: 30px!important
}

.mb-8 {
    margin-bottom: 10px!important
}

.mb-9 {
    margin-bottom: 20px!important
}

.mb-auto {
    margin-bottom: auto!important
}

.ms-0 {
    margin-left: 0!important
}

.ms-1 {
    margin-left: 0.25rem!important
}

.ms-2 {
    margin-left: 0.5rem!important
}

.ms-3 {
    margin-left: 1rem!important
}

.ms-4 {
    margin-left: 1.5rem!important
}

.ms-5 {
    margin-left: 3rem!important
}

.ms-6 {
    margin-left: 12px!important
}

.ms-7 {
    margin-left: 30px!important
}

.ms-8 {
    margin-left: 10px!important
}

.ms-9 {
    margin-left: 20px!important
}

.ms-auto {
    margin-left: auto!important
}

.m-n1 {
    margin: -0.25rem!important
}

.m-n2 {
    margin: -0.5rem!important
}

.m-n3 {
    margin: -1rem!important
}

.m-n4 {
    margin: -1.5rem!important
}

.m-n5 {
    margin: -3rem!important
}

.m-n6 {
    margin: -12px!important
}

.m-n7 {
    margin: -30px!important
}

.m-n8 {
    margin: -10px!important
}

.m-n9 {
    margin: -20px!important
}

.mx-n1 {
    margin-right: -0.25rem!important;
    margin-left: -0.25rem!important
}

.mx-n2 {
    margin-right: -0.5rem!important;
    margin-left: -0.5rem!important
}

.mx-n3 {
    margin-right: -1rem!important;
    margin-left: -1rem!important
}

.mx-n4 {
    margin-right: -1.5rem!important;
    margin-left: -1.5rem!important
}

.mx-n5 {
    margin-right: -3rem!important;
    margin-left: -3rem!important
}

.mx-n6 {
    margin-right: -12px!important;
    margin-left: -12px!important
}

.mx-n7 {
    margin-right: -30px!important;
    margin-left: -30px!important
}

.mx-n8 {
    margin-right: -10px!important;
    margin-left: -10px!important
}

.mx-n9 {
    margin-right: -20px!important;
    margin-left: -20px!important
}

.my-n1 {
    margin-top: -0.25rem!important;
    margin-bottom: -0.25rem!important
}

.my-n2 {
    margin-top: -0.5rem!important;
    margin-bottom: -0.5rem!important
}

.my-n3 {
    margin-top: -1rem!important;
    margin-bottom: -1rem!important
}

.my-n4 {
    margin-top: -1.5rem!important;
    margin-bottom: -1.5rem!important
}

.my-n5 {
    margin-top: -3rem!important;
    margin-bottom: -3rem!important
}

.my-n6 {
    margin-top: -12px!important;
    margin-bottom: -12px!important
}

.my-n7 {
    margin-top: -30px!important;
    margin-bottom: -30px!important
}

.my-n8 {
    margin-top: -10px!important;
    margin-bottom: -10px!important
}

.my-n9 {
    margin-top: -20px!important;
    margin-bottom: -20px!important
}

.mt-n1 {
    margin-top: -0.25rem!important
}

.mt-n2 {
    margin-top: -0.5rem!important
}

.mt-n3 {
    margin-top: -1rem!important
}

.mt-n4 {
    margin-top: -1.5rem!important
}

.mt-n5 {
    margin-top: -3rem!important
}

.mt-n6 {
    margin-top: -12px!important
}

.mt-n7 {
    margin-top: -30px!important
}

.mt-n8 {
    margin-top: -10px!important
}

.mt-n9 {
    margin-top: -20px!important
}

.me-n1 {
    margin-right: -0.25rem!important
}

.me-n2 {
    margin-right: -0.5rem!important
}

.me-n3 {
    margin-right: -1rem!important
}

.me-n4 {
    margin-right: -1.5rem!important
}

.me-n5 {
    margin-right: -3rem!important
}

.me-n6 {
    margin-right: -12px!important
}

.me-n7 {
    margin-right: -30px!important
}

.me-n8 {
    margin-right: -10px!important
}

.me-n9 {
    margin-right: -20px!important
}

.mb-n1 {
    margin-bottom: -0.25rem!important
}

.mb-n2 {
    margin-bottom: -0.5rem!important
}

.mb-n3 {
    margin-bottom: -1rem!important
}

.mb-n4 {
    margin-bottom: -1.5rem!important
}

.mb-n5 {
    margin-bottom: -3rem!important
}

.mb-n6 {
    margin-bottom: -12px!important
}

.mb-n7 {
    margin-bottom: -30px!important
}

.mb-n8 {
    margin-bottom: -10px!important
}

.mb-n9 {
    margin-bottom: -20px!important
}

.ms-n1 {
    margin-left: -0.25rem!important
}

.ms-n2 {
    margin-left: -0.5rem!important
}

.ms-n3 {
    margin-left: -1rem!important
}

.ms-n4 {
    margin-left: -1.5rem!important
}

.ms-n5 {
    margin-left: -3rem!important
}

.ms-n6 {
    margin-left: -12px!important
}

.ms-n7 {
    margin-left: -30px!important
}

.ms-n8 {
    margin-left: -10px!important
}

.ms-n9 {
    margin-left: -20px!important
}

.p-0 {
    padding: 0!important
}

.p-1 {
    padding: 0.25rem!important
}

.p-2 {
    padding: 0.5rem!important
}

.p-3 {
    padding: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.p-6 {
    padding: 12px!important
}

.p-7 {
    padding: 30px!important
}

.p-8 {
    padding: 10px!important
}

.p-9 {
    padding: 20px!important
}

.px-0 {
    padding-right: 0!important;
    padding-left: 0!important
}

.px-1 {
    padding-right: 0.25rem!important;
    padding-left: 0.25rem!important
}

.px-2 {
    padding-right: 0.5rem!important;
    padding-left: 0.5rem!important
}

.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important
}

.px-4 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important
}

.px-5 {
    padding-right: 3rem!important;
    padding-left: 3rem!important
}

.px-6 {
    padding-right: 12px!important;
    padding-left: 12px!important
}

.px-7 {
    padding-right: 30px!important;
    padding-left: 30px!important
}

.px-8 {
    padding-right: 10px!important;
    padding-left: 10px!important
}

.px-9 {
    padding-right: 20px!important;
    padding-left: 20px!important
}

.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.py-1 {
    padding-top: 0.25rem!important;
    padding-bottom: 0.25rem!important
}

.py-2 {
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important
}

.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important
}

.py-4 {
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important
}

.py-5 {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important
}

.py-6 {
    padding-top: 12px!important;
    padding-bottom: 12px!important
}

.py-7 {
    padding-top: 30px!important;
    padding-bottom: 30px!important
}

.py-8 {
    padding-top: 10px!important;
    padding-bottom: 10px!important
}

.py-9 {
    padding-top: 20px!important;
    padding-bottom: 20px!important
}

.pt-0 {
    padding-top: 0!important
}

.pt-1 {
    padding-top: 0.25rem!important
}

.pt-2 {
    padding-top: 0.5rem!important
}

.pt-3 {
    padding-top: 1rem!important
}

.pt-4 {
    padding-top: 1.5rem!important
}

.pt-5 {
    padding-top: 3rem!important
}

.pt-6 {
    padding-top: 12px!important
}

.pt-7 {
    padding-top: 30px!important
}

.pt-8 {
    padding-top: 10px!important
}

.pt-9 {
    padding-top: 20px!important
}

.pe-0 {
    padding-right: 0!important
}

.pe-1 {
    padding-right: 0.25rem!important
}

.pe-2 {
    padding-right: 0.5rem!important
}

.pe-3 {
    padding-right: 1rem!important
}

.pe-4 {
    padding-right: 1.5rem!important
}

.pe-5 {
    padding-right: 3rem!important
}

.pe-6 {
    padding-right: 12px!important
}

.pe-7 {
    padding-right: 30px!important
}

.pe-8 {
    padding-right: 10px!important
}

.pe-9 {
    padding-right: 20px!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pb-1 {
    padding-bottom: 0.25rem!important
}

.pb-2 {
    padding-bottom: 0.5rem!important
}

.pb-3 {
    padding-bottom: 1rem!important
}

.pb-4 {
    padding-bottom: 1.5rem!important
}

.pb-5 {
    padding-bottom: 3rem!important
}

.pb-6 {
    padding-bottom: 12px!important
}

.pb-7 {
    padding-bottom: 30px!important
}

.pb-8 {
    padding-bottom: 10px!important
}

.pb-9 {
    padding-bottom: 20px!important
}

.ps-0 {
    padding-left: 0!important
}

.ps-1 {
    padding-left: 0.25rem!important
}

.ps-2 {
    padding-left: 0.5rem!important
}

.ps-3 {
    padding-left: 1rem!important
}

.ps-4 {
    padding-left: 1.5rem!important
}

.ps-5 {
    padding-left: 3rem!important
}

.ps-6 {
    padding-left: 12px!important
}

.ps-7 {
    padding-left: 30px!important
}

.ps-8 {
    padding-left: 10px!important
}

.ps-9 {
    padding-left: 20px!important
}

.gap-0 {
    gap: 0!important
}

.gap-1 {
    gap: 0.25rem!important
}

.gap-2 {
    gap: 0.5rem!important
}

.gap-3 {
    gap: 1rem!important
}

.gap-4 {
    gap: 1.5rem!important
}

.gap-5 {
    gap: 3rem!important
}

.gap-6 {
    gap: 12px!important
}

.gap-7 {
    gap: 30px!important
}

.gap-8 {
    gap: 10px!important
}

.gap-9 {
    gap: 20px!important
}

.row-gap-0 {
    row-gap: 0!important
}

.row-gap-1 {
    row-gap: 0.25rem!important
}

.row-gap-2 {
    row-gap: 0.5rem!important
}

.row-gap-3 {
    row-gap: 1rem!important
}

.row-gap-4 {
    row-gap: 1.5rem!important
}

.row-gap-5 {
    row-gap: 3rem!important
}

.row-gap-6 {
    row-gap: 12px!important
}

.row-gap-7 {
    row-gap: 30px!important
}

.row-gap-8 {
    row-gap: 10px!important
}

.row-gap-9 {
    row-gap: 20px!important
}

.column-gap-0 {
    -webkit-column-gap: 0!important;
    -moz-column-gap: 0!important;
    column-gap: 0!important
}

.column-gap-1 {
    -webkit-column-gap: 0.25rem!important;
    -moz-column-gap: 0.25rem!important;
    column-gap: 0.25rem!important
}

.column-gap-2 {
    -webkit-column-gap: 0.5rem!important;
    -moz-column-gap: 0.5rem!important;
    column-gap: 0.5rem!important
}

.column-gap-3 {
    -webkit-column-gap: 1rem!important;
    -moz-column-gap: 1rem!important;
    column-gap: 1rem!important
}

.column-gap-4 {
    -webkit-column-gap: 1.5rem!important;
    -moz-column-gap: 1.5rem!important;
    column-gap: 1.5rem!important
}

.column-gap-5 {
    -webkit-column-gap: 3rem!important;
    -moz-column-gap: 3rem!important;
    column-gap: 3rem!important
}

.column-gap-6 {
    -webkit-column-gap: 12px!important;
    -moz-column-gap: 12px!important;
    column-gap: 12px!important
}

.column-gap-7 {
    -webkit-column-gap: 30px!important;
    -moz-column-gap: 30px!important;
    column-gap: 30px!important
}

.column-gap-8 {
    -webkit-column-gap: 10px!important;
    -moz-column-gap: 10px!important;
    column-gap: 10px!important
}

.column-gap-9 {
    -webkit-column-gap: 20px!important;
    -moz-column-gap: 20px!important;
    column-gap: 20px!important
}

.font-monospace {
    font-family: var(--bs-font-monospace)!important
}

.fs-1 {
    font-size: 0.625rem!important
}

.fs-2 {
    font-size: 0.75rem!important
}

.fs-3 {
    font-size: 0.875rem!important
}

.fs-4 {
    font-size: 1rem!important
}

.fs-5 {
    font-size: 1.125rem!important
}

.fs-6 {
    font-size: 1.25rem!important
}

.fs-8 {
    font-size: calc(1.3125rem + 0.75vw)!important
}

.fst-italic {
    font-style: italic!important
}

.fst-normal {
    font-style: normal!important
}

.fw-lighter {
    font-weight: 300!important
}

.fw-light {
    font-weight: 300!important
}

.fw-normal {
    font-weight: 400!important
}

.fw-medium {
    font-weight: 500!important
}

.fw-semibold {
    font-weight: 600!important
}

.fw-bold {
    font-weight: 500!important
}

.fw-bolder {
    font-weight: 700!important
}

.lh-1 {
    line-height: 1!important
}

.lh-sm {
    line-height: 1.25!important
}

.lh-base {
    line-height: 1.5!important
}

.lh-lg {
    line-height: 2!important
}

.text-start {
    text-align: left!important
}

.text-end {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

.text-decoration-none {
    text-decoration: none!important
}

.text-decoration-underline {
    text-decoration: underline!important
}

.text-decoration-line-through {
    text-decoration: line-through!important
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-break {
    word-wrap: break-word!important;
    word-break: break-word!important
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb),var(--bs-text-opacity))!important
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb),var(--bs-text-opacity))!important
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb),var(--bs-text-opacity))!important
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb),var(--bs-text-opacity))!important
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb),var(--bs-text-opacity))!important
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb),var(--bs-text-opacity))!important
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb),var(--bs-text-opacity))!important
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb),var(--bs-text-opacity))!important
}

.text-muted {
    --bs-text-opacity: 1;
    color: #5A6A85!important
}

.text-indigo {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-indigo-rgb),var(--bs-text-opacity))!important
}

.text-light-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-primary-rgb),var(--bs-text-opacity))!important
}

.text-light-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-secondary-rgb),var(--bs-text-opacity))!important
}

.text-light-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-info-rgb),var(--bs-text-opacity))!important
}

.text-light-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-success-rgb),var(--bs-text-opacity))!important
}

.text-light-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-warning-rgb),var(--bs-text-opacity))!important
}

.text-light-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-danger-rgb),var(--bs-text-opacity))!important
}

.text-light-indigo {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-indigo-rgb),var(--bs-text-opacity))!important
}

.text-dark-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-light-rgb),var(--bs-text-opacity))!important
}

.text-light-gray {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-gray-rgb),var(--bs-text-opacity))!important
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb),var(--bs-text-opacity))!important
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb),var(--bs-text-opacity))!important
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb),var(--bs-text-opacity))!important
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0,0,0,0.5)!important
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255,255,255,0.5)!important
}

.text-body-secondary {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color)!important
}

.text-body-tertiary {
    --bs-text-opacity: 1;
    color: var(--bs-tertiary-color)!important
}

.text-body-emphasis {
    --bs-text-opacity: 1;
    color: var(--bs-emphasis-color)!important
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit!important
}

.text-opacity-25 {
    --bs-text-opacity: 0.25
}

.text-opacity-50 {
    --bs-text-opacity: 0.5
}

.text-opacity-75 {
    --bs-text-opacity: 0.75
}

.text-opacity-100 {
    --bs-text-opacity: 1
}

.text-primary-emphasis {
    color: var(--bs-primary-text)!important
}

.text-secondary-emphasis {
    color: var(--bs-secondary-text)!important
}

.text-success-emphasis {
    color: var(--bs-success-text)!important
}

.text-info-emphasis {
    color: var(--bs-info-text)!important
}

.text-warning-emphasis {
    color: var(--bs-warning-text)!important
}

.text-danger-emphasis {
    color: var(--bs-danger-text)!important
}

.text-light-emphasis {
    color: var(--bs-light-text)!important
}

.text-dark-emphasis {
    color: var(--bs-dark-text)!important
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb),var(--bs-bg-opacity))!important
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity))!important
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb),var(--bs-bg-opacity))!important
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb),var(--bs-bg-opacity))!important
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb),var(--bs-bg-opacity))!important
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb),var(--bs-bg-opacity))!important
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important
}

.bg-muted {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-muted-rgb),var(--bs-bg-opacity))!important
}

.bg-indigo {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-indigo-rgb),var(--bs-bg-opacity))!important
}

.bg-light-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-primary-rgb),var(--bs-bg-opacity))!important
}

.bg-light-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-secondary-rgb),var(--bs-bg-opacity))!important
}

.bg-light-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-info-rgb),var(--bs-bg-opacity))!important
}

.bg-light-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-success-rgb),var(--bs-bg-opacity))!important
}

.bg-light-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-warning-rgb),var(--bs-bg-opacity))!important
}

.bg-light-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-danger-rgb),var(--bs-bg-opacity))!important
}

.bg-light-indigo {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-indigo-rgb),var(--bs-bg-opacity))!important
}

.bg-dark-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-light-rgb),var(--bs-bg-opacity))!important
}

.bg-light-gray {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-gray-rgb),var(--bs-bg-opacity))!important
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb),var(--bs-bg-opacity))!important
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb),var(--bs-bg-opacity))!important
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb),var(--bs-bg-opacity))!important
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent!important
}

.bg-body-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-bg-rgb),var(--bs-bg-opacity))!important
}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-tertiary-bg-rgb),var(--bs-bg-opacity))!important
}

.bg-body-emphasis {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-emphasis-bg-rgb),var(--bs-bg-opacity))!important
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75
}

.bg-opacity-100 {
    --bs-bg-opacity: 1
}

.bg-primary-subtle {
    background-color: var(--bs-primary-bg-subtle)!important
}

.bg-secondary-subtle {
    background-color: var(--bs-secondary-bg-subtle)!important
}

.bg-success-subtle {
    background-color: var(--bs-success-bg-subtle)!important
}

.bg-info-subtle {
    background-color: var(--bs-info-bg-subtle)!important
}

.bg-warning-subtle {
    background-color: var(--bs-warning-bg-subtle)!important
}

.bg-danger-subtle {
    background-color: var(--bs-danger-bg-subtle)!important
}

.bg-light-subtle {
    background-color: var(--bs-light-bg-subtle)!important
}

.bg-dark-subtle {
    background-color: var(--bs-dark-bg-subtle)!important
}

.bg-gradient {
    background-image: var(--bs-gradient)!important
}

.user-select-all {
    -webkit-user-select: all!important;
    -moz-user-select: all!important;
    user-select: all!important
}

.user-select-auto {
    -webkit-user-select: auto!important;
    -moz-user-select: auto!important;
    -ms-user-select: auto!important;
    user-select: auto!important
}

.user-select-none {
    -webkit-user-select: none!important;
    -moz-user-select: none!important;
    -ms-user-select: none!important;
    user-select: none!important
}

.pe-none {
    pointer-events: none!important
}

.pe-auto {
    pointer-events: auto!important
}

.rounded {
    border-radius: var(--bs-border-radius)!important
}

.rounded-0 {
    border-radius: 0!important
}

.rounded-1 {
    border-radius: var(--bs-border-radius-sm)!important
}

.rounded-2 {
    border-radius: var(--bs-border-radius)!important
}

.rounded-3 {
    border-radius: var(--bs-border-radius-lg)!important
}

.rounded-4 {
    border-radius: var(--bs-border-radius-xl)!important
}

.rounded-5 {
    border-radius: var(--bs-border-radius-2xl)!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-pill {
    border-radius: var(--bs-border-radius-pill)!important
}

.rounded-top {
    border-top-left-radius: var(--bs-border-radius)!important;
    border-top-right-radius: var(--bs-border-radius)!important
}

.rounded-top-0 {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important
}

.rounded-top-1 {
    border-top-left-radius: var(--bs-border-radius-sm)!important;
    border-top-right-radius: var(--bs-border-radius-sm)!important
}

.rounded-top-2 {
    border-top-left-radius: var(--bs-border-radius)!important;
    border-top-right-radius: var(--bs-border-radius)!important
}

.rounded-top-3 {
    border-top-left-radius: var(--bs-border-radius-lg)!important;
    border-top-right-radius: var(--bs-border-radius-lg)!important
}

.rounded-top-4 {
    border-top-left-radius: var(--bs-border-radius-xl)!important;
    border-top-right-radius: var(--bs-border-radius-xl)!important
}

.rounded-top-5 {
    border-top-left-radius: var(--bs-border-radius-2xl)!important;
    border-top-right-radius: var(--bs-border-radius-2xl)!important
}

.rounded-top-circle {
    border-top-left-radius: 50%!important;
    border-top-right-radius: 50%!important
}

.rounded-top-pill {
    border-top-left-radius: var(--bs-border-radius-pill)!important;
    border-top-right-radius: var(--bs-border-radius-pill)!important
}

.rounded-end {
    border-top-right-radius: var(--bs-border-radius)!important;
    border-bottom-right-radius: var(--bs-border-radius)!important
}

.rounded-end-0 {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important
}

.rounded-end-1 {
    border-top-right-radius: var(--bs-border-radius-sm)!important;
    border-bottom-right-radius: var(--bs-border-radius-sm)!important
}

.rounded-end-2 {
    border-top-right-radius: var(--bs-border-radius)!important;
    border-bottom-right-radius: var(--bs-border-radius)!important
}

.rounded-end-3 {
    border-top-right-radius: var(--bs-border-radius-lg)!important;
    border-bottom-right-radius: var(--bs-border-radius-lg)!important
}

.rounded-end-4 {
    border-top-right-radius: var(--bs-border-radius-xl)!important;
    border-bottom-right-radius: var(--bs-border-radius-xl)!important
}

.rounded-end-5 {
    border-top-right-radius: var(--bs-border-radius-2xl)!important;
    border-bottom-right-radius: var(--bs-border-radius-2xl)!important
}

.rounded-end-circle {
    border-top-right-radius: 50%!important;
    border-bottom-right-radius: 50%!important
}

.rounded-end-pill {
    border-top-right-radius: var(--bs-border-radius-pill)!important;
    border-bottom-right-radius: var(--bs-border-radius-pill)!important
}

.rounded-bottom {
    border-bottom-right-radius: var(--bs-border-radius)!important;
    border-bottom-left-radius: var(--bs-border-radius)!important
}

.rounded-bottom-0 {
    border-bottom-right-radius: 0!important;
    border-bottom-left-radius: 0!important
}

.rounded-bottom-1 {
    border-bottom-right-radius: var(--bs-border-radius-sm)!important;
    border-bottom-left-radius: var(--bs-border-radius-sm)!important
}

.rounded-bottom-2 {
    border-bottom-right-radius: var(--bs-border-radius)!important;
    border-bottom-left-radius: var(--bs-border-radius)!important
}

.rounded-bottom-3 {
    border-bottom-right-radius: var(--bs-border-radius-lg)!important;
    border-bottom-left-radius: var(--bs-border-radius-lg)!important
}

.rounded-bottom-4 {
    border-bottom-right-radius: var(--bs-border-radius-xl)!important;
    border-bottom-left-radius: var(--bs-border-radius-xl)!important
}

.rounded-bottom-5 {
    border-bottom-right-radius: var(--bs-border-radius-2xl)!important;
    border-bottom-left-radius: var(--bs-border-radius-2xl)!important
}

.rounded-bottom-circle {
    border-bottom-right-radius: 50%!important;
    border-bottom-left-radius: 50%!important
}

.rounded-bottom-pill {
    border-bottom-right-radius: var(--bs-border-radius-pill)!important;
    border-bottom-left-radius: var(--bs-border-radius-pill)!important
}

.rounded-start {
    border-bottom-left-radius: var(--bs-border-radius)!important;
    border-top-left-radius: var(--bs-border-radius)!important
}

.rounded-start-0 {
    border-bottom-left-radius: 0!important;
    border-top-left-radius: 0!important
}

.rounded-start-1 {
    border-bottom-left-radius: var(--bs-border-radius-sm)!important;
    border-top-left-radius: var(--bs-border-radius-sm)!important
}

.rounded-start-2 {
    border-bottom-left-radius: var(--bs-border-radius)!important;
    border-top-left-radius: var(--bs-border-radius)!important
}

.rounded-start-3 {
    border-bottom-left-radius: var(--bs-border-radius-lg)!important;
    border-top-left-radius: var(--bs-border-radius-lg)!important
}

.rounded-start-4 {
    border-bottom-left-radius: var(--bs-border-radius-xl)!important;
    border-top-left-radius: var(--bs-border-radius-xl)!important
}

.rounded-start-5 {
    border-bottom-left-radius: var(--bs-border-radius-2xl)!important;
    border-top-left-radius: var(--bs-border-radius-2xl)!important
}

.rounded-start-circle {
    border-bottom-left-radius: 50%!important;
    border-top-left-radius: 50%!important
}

.rounded-start-pill {
    border-bottom-left-radius: var(--bs-border-radius-pill)!important;
    border-top-left-radius: var(--bs-border-radius-pill)!important
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

.z-n1 {
    z-index: -1!important
}

.z-0 {
    z-index: 0!important
}

.z-1 {
    z-index: 1!important
}

.z-2 {
    z-index: 2!important
}

.z-3 {
    z-index: 3!important
}

@media (min-width: 576px) {
    .float-sm-start {
        float:left!important
    }

    .float-sm-end {
        float: right!important
    }

    .float-sm-none {
        float: none!important
    }

    .object-fit-sm-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-sm-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-sm-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-sm-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-sm-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-grid {
        display: -ms-grid!important;
        display: grid!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-sm-none {
        display: none!important
    }

    .flex-sm-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-sm-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-sm-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-sm-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-sm-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-sm-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-sm-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-sm-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-sm-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-sm-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto!important;
        -ms-grid-row-align: auto!important;
        align-self: auto!important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center!important;
        -ms-grid-row-align: center!important;
        align-self: center!important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch!important;
        -ms-grid-row-align: stretch!important;
        align-self: stretch!important
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-sm-0 {
        margin: 0!important
    }

    .m-sm-1 {
        margin: 0.25rem!important
    }

    .m-sm-2 {
        margin: 0.5rem!important
    }

    .m-sm-3 {
        margin: 1rem!important
    }

    .m-sm-4 {
        margin: 1.5rem!important
    }

    .m-sm-5 {
        margin: 3rem!important
    }

    .m-sm-6 {
        margin: 12px!important
    }

    .m-sm-7 {
        margin: 30px!important
    }

    .m-sm-8 {
        margin: 10px!important
    }

    .m-sm-9 {
        margin: 20px!important
    }

    .m-sm-auto {
        margin: auto!important
    }

    .mx-sm-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-sm-1 {
        margin-right: 0.25rem!important;
        margin-left: 0.25rem!important
    }

    .mx-sm-2 {
        margin-right: 0.5rem!important;
        margin-left: 0.5rem!important
    }

    .mx-sm-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-sm-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-sm-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-sm-6 {
        margin-right: 12px!important;
        margin-left: 12px!important
    }

    .mx-sm-7 {
        margin-right: 30px!important;
        margin-left: 30px!important
    }

    .mx-sm-8 {
        margin-right: 10px!important;
        margin-left: 10px!important
    }

    .mx-sm-9 {
        margin-right: 20px!important;
        margin-left: 20px!important
    }

    .mx-sm-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-sm-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-sm-1 {
        margin-top: 0.25rem!important;
        margin-bottom: 0.25rem!important
    }

    .my-sm-2 {
        margin-top: 0.5rem!important;
        margin-bottom: 0.5rem!important
    }

    .my-sm-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-sm-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-sm-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-sm-6 {
        margin-top: 12px!important;
        margin-bottom: 12px!important
    }

    .my-sm-7 {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }

    .my-sm-8 {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }

    .my-sm-9 {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }

    .my-sm-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-sm-0 {
        margin-top: 0!important
    }

    .mt-sm-1 {
        margin-top: 0.25rem!important
    }

    .mt-sm-2 {
        margin-top: 0.5rem!important
    }

    .mt-sm-3 {
        margin-top: 1rem!important
    }

    .mt-sm-4 {
        margin-top: 1.5rem!important
    }

    .mt-sm-5 {
        margin-top: 3rem!important
    }

    .mt-sm-6 {
        margin-top: 12px!important
    }

    .mt-sm-7 {
        margin-top: 30px!important
    }

    .mt-sm-8 {
        margin-top: 10px!important
    }

    .mt-sm-9 {
        margin-top: 20px!important
    }

    .mt-sm-auto {
        margin-top: auto!important
    }

    .me-sm-0 {
        margin-right: 0!important
    }

    .me-sm-1 {
        margin-right: 0.25rem!important
    }

    .me-sm-2 {
        margin-right: 0.5rem!important
    }

    .me-sm-3 {
        margin-right: 1rem!important
    }

    .me-sm-4 {
        margin-right: 1.5rem!important
    }

    .me-sm-5 {
        margin-right: 3rem!important
    }

    .me-sm-6 {
        margin-right: 12px!important
    }

    .me-sm-7 {
        margin-right: 30px!important
    }

    .me-sm-8 {
        margin-right: 10px!important
    }

    .me-sm-9 {
        margin-right: 20px!important
    }

    .me-sm-auto {
        margin-right: auto!important
    }

    .mb-sm-0 {
        margin-bottom: 0!important
    }

    .mb-sm-1 {
        margin-bottom: 0.25rem!important
    }

    .mb-sm-2 {
        margin-bottom: 0.5rem!important
    }

    .mb-sm-3 {
        margin-bottom: 1rem!important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-sm-5 {
        margin-bottom: 3rem!important
    }

    .mb-sm-6 {
        margin-bottom: 12px!important
    }

    .mb-sm-7 {
        margin-bottom: 30px!important
    }

    .mb-sm-8 {
        margin-bottom: 10px!important
    }

    .mb-sm-9 {
        margin-bottom: 20px!important
    }

    .mb-sm-auto {
        margin-bottom: auto!important
    }

    .ms-sm-0 {
        margin-left: 0!important
    }

    .ms-sm-1 {
        margin-left: 0.25rem!important
    }

    .ms-sm-2 {
        margin-left: 0.5rem!important
    }

    .ms-sm-3 {
        margin-left: 1rem!important
    }

    .ms-sm-4 {
        margin-left: 1.5rem!important
    }

    .ms-sm-5 {
        margin-left: 3rem!important
    }

    .ms-sm-6 {
        margin-left: 12px!important
    }

    .ms-sm-7 {
        margin-left: 30px!important
    }

    .ms-sm-8 {
        margin-left: 10px!important
    }

    .ms-sm-9 {
        margin-left: 20px!important
    }

    .ms-sm-auto {
        margin-left: auto!important
    }

    .m-sm-n1 {
        margin: -0.25rem!important
    }

    .m-sm-n2 {
        margin: -0.5rem!important
    }

    .m-sm-n3 {
        margin: -1rem!important
    }

    .m-sm-n4 {
        margin: -1.5rem!important
    }

    .m-sm-n5 {
        margin: -3rem!important
    }

    .m-sm-n6 {
        margin: -12px!important
    }

    .m-sm-n7 {
        margin: -30px!important
    }

    .m-sm-n8 {
        margin: -10px!important
    }

    .m-sm-n9 {
        margin: -20px!important
    }

    .mx-sm-n1 {
        margin-right: -0.25rem!important;
        margin-left: -0.25rem!important
    }

    .mx-sm-n2 {
        margin-right: -0.5rem!important;
        margin-left: -0.5rem!important
    }

    .mx-sm-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-sm-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-sm-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-sm-n6 {
        margin-right: -12px!important;
        margin-left: -12px!important
    }

    .mx-sm-n7 {
        margin-right: -30px!important;
        margin-left: -30px!important
    }

    .mx-sm-n8 {
        margin-right: -10px!important;
        margin-left: -10px!important
    }

    .mx-sm-n9 {
        margin-right: -20px!important;
        margin-left: -20px!important
    }

    .my-sm-n1 {
        margin-top: -0.25rem!important;
        margin-bottom: -0.25rem!important
    }

    .my-sm-n2 {
        margin-top: -0.5rem!important;
        margin-bottom: -0.5rem!important
    }

    .my-sm-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-sm-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-sm-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-sm-n6 {
        margin-top: -12px!important;
        margin-bottom: -12px!important
    }

    .my-sm-n7 {
        margin-top: -30px!important;
        margin-bottom: -30px!important
    }

    .my-sm-n8 {
        margin-top: -10px!important;
        margin-bottom: -10px!important
    }

    .my-sm-n9 {
        margin-top: -20px!important;
        margin-bottom: -20px!important
    }

    .mt-sm-n1 {
        margin-top: -0.25rem!important
    }

    .mt-sm-n2 {
        margin-top: -0.5rem!important
    }

    .mt-sm-n3 {
        margin-top: -1rem!important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem!important
    }

    .mt-sm-n5 {
        margin-top: -3rem!important
    }

    .mt-sm-n6 {
        margin-top: -12px!important
    }

    .mt-sm-n7 {
        margin-top: -30px!important
    }

    .mt-sm-n8 {
        margin-top: -10px!important
    }

    .mt-sm-n9 {
        margin-top: -20px!important
    }

    .me-sm-n1 {
        margin-right: -0.25rem!important
    }

    .me-sm-n2 {
        margin-right: -0.5rem!important
    }

    .me-sm-n3 {
        margin-right: -1rem!important
    }

    .me-sm-n4 {
        margin-right: -1.5rem!important
    }

    .me-sm-n5 {
        margin-right: -3rem!important
    }

    .me-sm-n6 {
        margin-right: -12px!important
    }

    .me-sm-n7 {
        margin-right: -30px!important
    }

    .me-sm-n8 {
        margin-right: -10px!important
    }

    .me-sm-n9 {
        margin-right: -20px!important
    }

    .mb-sm-n1 {
        margin-bottom: -0.25rem!important
    }

    .mb-sm-n2 {
        margin-bottom: -0.5rem!important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem!important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-sm-n5 {
        margin-bottom: -3rem!important
    }

    .mb-sm-n6 {
        margin-bottom: -12px!important
    }

    .mb-sm-n7 {
        margin-bottom: -30px!important
    }

    .mb-sm-n8 {
        margin-bottom: -10px!important
    }

    .mb-sm-n9 {
        margin-bottom: -20px!important
    }

    .ms-sm-n1 {
        margin-left: -0.25rem!important
    }

    .ms-sm-n2 {
        margin-left: -0.5rem!important
    }

    .ms-sm-n3 {
        margin-left: -1rem!important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem!important
    }

    .ms-sm-n5 {
        margin-left: -3rem!important
    }

    .ms-sm-n6 {
        margin-left: -12px!important
    }

    .ms-sm-n7 {
        margin-left: -30px!important
    }

    .ms-sm-n8 {
        margin-left: -10px!important
    }

    .ms-sm-n9 {
        margin-left: -20px!important
    }

    .p-sm-0 {
        padding: 0!important
    }

    .p-sm-1 {
        padding: 0.25rem!important
    }

    .p-sm-2 {
        padding: 0.5rem!important
    }

    .p-sm-3 {
        padding: 1rem!important
    }

    .p-sm-4 {
        padding: 1.5rem!important
    }

    .p-sm-5 {
        padding: 3rem!important
    }

    .p-sm-6 {
        padding: 12px!important
    }

    .p-sm-7 {
        padding: 30px!important
    }

    .p-sm-8 {
        padding: 10px!important
    }

    .p-sm-9 {
        padding: 20px!important
    }

    .px-sm-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-sm-1 {
        padding-right: 0.25rem!important;
        padding-left: 0.25rem!important
    }

    .px-sm-2 {
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important
    }

    .px-sm-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-sm-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-sm-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-sm-6 {
        padding-right: 12px!important;
        padding-left: 12px!important
    }

    .px-sm-7 {
        padding-right: 30px!important;
        padding-left: 30px!important
    }

    .px-sm-8 {
        padding-right: 10px!important;
        padding-left: 10px!important
    }

    .px-sm-9 {
        padding-right: 20px!important;
        padding-left: 20px!important
    }

    .py-sm-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-sm-1 {
        padding-top: 0.25rem!important;
        padding-bottom: 0.25rem!important
    }

    .py-sm-2 {
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important
    }

    .py-sm-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-sm-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-sm-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-sm-6 {
        padding-top: 12px!important;
        padding-bottom: 12px!important
    }

    .py-sm-7 {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }

    .py-sm-8 {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }

    .py-sm-9 {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }

    .pt-sm-0 {
        padding-top: 0!important
    }

    .pt-sm-1 {
        padding-top: 0.25rem!important
    }

    .pt-sm-2 {
        padding-top: 0.5rem!important
    }

    .pt-sm-3 {
        padding-top: 1rem!important
    }

    .pt-sm-4 {
        padding-top: 1.5rem!important
    }

    .pt-sm-5 {
        padding-top: 3rem!important
    }

    .pt-sm-6 {
        padding-top: 12px!important
    }

    .pt-sm-7 {
        padding-top: 30px!important
    }

    .pt-sm-8 {
        padding-top: 10px!important
    }

    .pt-sm-9 {
        padding-top: 20px!important
    }

    .pe-sm-0 {
        padding-right: 0!important
    }

    .pe-sm-1 {
        padding-right: 0.25rem!important
    }

    .pe-sm-2 {
        padding-right: 0.5rem!important
    }

    .pe-sm-3 {
        padding-right: 1rem!important
    }

    .pe-sm-4 {
        padding-right: 1.5rem!important
    }

    .pe-sm-5 {
        padding-right: 3rem!important
    }

    .pe-sm-6 {
        padding-right: 12px!important
    }

    .pe-sm-7 {
        padding-right: 30px!important
    }

    .pe-sm-8 {
        padding-right: 10px!important
    }

    .pe-sm-9 {
        padding-right: 20px!important
    }

    .pb-sm-0 {
        padding-bottom: 0!important
    }

    .pb-sm-1 {
        padding-bottom: 0.25rem!important
    }

    .pb-sm-2 {
        padding-bottom: 0.5rem!important
    }

    .pb-sm-3 {
        padding-bottom: 1rem!important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-sm-5 {
        padding-bottom: 3rem!important
    }

    .pb-sm-6 {
        padding-bottom: 12px!important
    }

    .pb-sm-7 {
        padding-bottom: 30px!important
    }

    .pb-sm-8 {
        padding-bottom: 10px!important
    }

    .pb-sm-9 {
        padding-bottom: 20px!important
    }

    .ps-sm-0 {
        padding-left: 0!important
    }

    .ps-sm-1 {
        padding-left: 0.25rem!important
    }

    .ps-sm-2 {
        padding-left: 0.5rem!important
    }

    .ps-sm-3 {
        padding-left: 1rem!important
    }

    .ps-sm-4 {
        padding-left: 1.5rem!important
    }

    .ps-sm-5 {
        padding-left: 3rem!important
    }

    .ps-sm-6 {
        padding-left: 12px!important
    }

    .ps-sm-7 {
        padding-left: 30px!important
    }

    .ps-sm-8 {
        padding-left: 10px!important
    }

    .ps-sm-9 {
        padding-left: 20px!important
    }

    .gap-sm-0 {
        gap: 0!important
    }

    .gap-sm-1 {
        gap: 0.25rem!important
    }

    .gap-sm-2 {
        gap: 0.5rem!important
    }

    .gap-sm-3 {
        gap: 1rem!important
    }

    .gap-sm-4 {
        gap: 1.5rem!important
    }

    .gap-sm-5 {
        gap: 3rem!important
    }

    .gap-sm-6 {
        gap: 12px!important
    }

    .gap-sm-7 {
        gap: 30px!important
    }

    .gap-sm-8 {
        gap: 10px!important
    }

    .gap-sm-9 {
        gap: 20px!important
    }

    .row-gap-sm-0 {
        row-gap: 0!important
    }

    .row-gap-sm-1 {
        row-gap: 0.25rem!important
    }

    .row-gap-sm-2 {
        row-gap: 0.5rem!important
    }

    .row-gap-sm-3 {
        row-gap: 1rem!important
    }

    .row-gap-sm-4 {
        row-gap: 1.5rem!important
    }

    .row-gap-sm-5 {
        row-gap: 3rem!important
    }

    .row-gap-sm-6 {
        row-gap: 12px!important
    }

    .row-gap-sm-7 {
        row-gap: 30px!important
    }

    .row-gap-sm-8 {
        row-gap: 10px!important
    }

    .row-gap-sm-9 {
        row-gap: 20px!important
    }

    .column-gap-sm-0 {
        -webkit-column-gap: 0!important;
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-sm-1 {
        -webkit-column-gap: 0.25rem!important;
        -moz-column-gap: 0.25rem!important;
        column-gap: 0.25rem!important
    }

    .column-gap-sm-2 {
        -webkit-column-gap: 0.5rem!important;
        -moz-column-gap: 0.5rem!important;
        column-gap: 0.5rem!important
    }

    .column-gap-sm-3 {
        -webkit-column-gap: 1rem!important;
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-sm-4 {
        -webkit-column-gap: 1.5rem!important;
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-sm-5 {
        -webkit-column-gap: 3rem!important;
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-sm-6 {
        -webkit-column-gap: 12px!important;
        -moz-column-gap: 12px!important;
        column-gap: 12px!important
    }

    .column-gap-sm-7 {
        -webkit-column-gap: 30px!important;
        -moz-column-gap: 30px!important;
        column-gap: 30px!important
    }

    .column-gap-sm-8 {
        -webkit-column-gap: 10px!important;
        -moz-column-gap: 10px!important;
        column-gap: 10px!important
    }

    .column-gap-sm-9 {
        -webkit-column-gap: 20px!important;
        -moz-column-gap: 20px!important;
        column-gap: 20px!important
    }

    .text-sm-start {
        text-align: left!important
    }

    .text-sm-end {
        text-align: right!important
    }

    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width: 768px) {
    .float-md-start {
        float:left!important
    }

    .float-md-end {
        float: right!important
    }

    .float-md-none {
        float: none!important
    }

    .object-fit-md-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-md-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-md-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-md-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-md-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-grid {
        display: -ms-grid!important;
        display: grid!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-md-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-md-none {
        display: none!important
    }

    .flex-md-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-md-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-md-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-md-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-md-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-md-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-md-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-md-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-md-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-md-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-md-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-md-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-md-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto!important;
        -ms-grid-row-align: auto!important;
        align-self: auto!important
    }

    .align-self-md-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-md-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-md-center {
        -ms-flex-item-align: center!important;
        -ms-grid-row-align: center!important;
        align-self: center!important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch!important;
        -ms-grid-row-align: stretch!important;
        align-self: stretch!important
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-md-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-md-0 {
        margin: 0!important
    }

    .m-md-1 {
        margin: 0.25rem!important
    }

    .m-md-2 {
        margin: 0.5rem!important
    }

    .m-md-3 {
        margin: 1rem!important
    }

    .m-md-4 {
        margin: 1.5rem!important
    }

    .m-md-5 {
        margin: 3rem!important
    }

    .m-md-6 {
        margin: 12px!important
    }

    .m-md-7 {
        margin: 30px!important
    }

    .m-md-8 {
        margin: 10px!important
    }

    .m-md-9 {
        margin: 20px!important
    }

    .m-md-auto {
        margin: auto!important
    }

    .mx-md-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-md-1 {
        margin-right: 0.25rem!important;
        margin-left: 0.25rem!important
    }

    .mx-md-2 {
        margin-right: 0.5rem!important;
        margin-left: 0.5rem!important
    }

    .mx-md-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-md-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-md-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-md-6 {
        margin-right: 12px!important;
        margin-left: 12px!important
    }

    .mx-md-7 {
        margin-right: 30px!important;
        margin-left: 30px!important
    }

    .mx-md-8 {
        margin-right: 10px!important;
        margin-left: 10px!important
    }

    .mx-md-9 {
        margin-right: 20px!important;
        margin-left: 20px!important
    }

    .mx-md-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-md-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-md-1 {
        margin-top: 0.25rem!important;
        margin-bottom: 0.25rem!important
    }

    .my-md-2 {
        margin-top: 0.5rem!important;
        margin-bottom: 0.5rem!important
    }

    .my-md-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-md-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-md-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-md-6 {
        margin-top: 12px!important;
        margin-bottom: 12px!important
    }

    .my-md-7 {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }

    .my-md-8 {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }

    .my-md-9 {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }

    .my-md-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-md-0 {
        margin-top: 0!important
    }

    .mt-md-1 {
        margin-top: 0.25rem!important
    }

    .mt-md-2 {
        margin-top: 0.5rem!important
    }

    .mt-md-3 {
        margin-top: 1rem!important
    }

    .mt-md-4 {
        margin-top: 1.5rem!important
    }

    .mt-md-5 {
        margin-top: 3rem!important
    }

    .mt-md-6 {
        margin-top: 12px!important
    }

    .mt-md-7 {
        margin-top: 30px!important
    }

    .mt-md-8 {
        margin-top: 10px!important
    }

    .mt-md-9 {
        margin-top: 20px!important
    }

    .mt-md-auto {
        margin-top: auto!important
    }

    .me-md-0 {
        margin-right: 0!important
    }

    .me-md-1 {
        margin-right: 0.25rem!important
    }

    .me-md-2 {
        margin-right: 0.5rem!important
    }

    .me-md-3 {
        margin-right: 1rem!important
    }

    .me-md-4 {
        margin-right: 1.5rem!important
    }

    .me-md-5 {
        margin-right: 3rem!important
    }

    .me-md-6 {
        margin-right: 12px!important
    }

    .me-md-7 {
        margin-right: 30px!important
    }

    .me-md-8 {
        margin-right: 10px!important
    }

    .me-md-9 {
        margin-right: 20px!important
    }

    .me-md-auto {
        margin-right: auto!important
    }

    .mb-md-0 {
        margin-bottom: 0!important
    }

    .mb-md-1 {
        margin-bottom: 0.25rem!important
    }

    .mb-md-2 {
        margin-bottom: 0.5rem!important
    }

    .mb-md-3 {
        margin-bottom: 1rem!important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-md-5 {
        margin-bottom: 3rem!important
    }

    .mb-md-6 {
        margin-bottom: 12px!important
    }

    .mb-md-7 {
        margin-bottom: 30px!important
    }

    .mb-md-8 {
        margin-bottom: 10px!important
    }

    .mb-md-9 {
        margin-bottom: 20px!important
    }

    .mb-md-auto {
        margin-bottom: auto!important
    }

    .ms-md-0 {
        margin-left: 0!important
    }

    .ms-md-1 {
        margin-left: 0.25rem!important
    }

    .ms-md-2 {
        margin-left: 0.5rem!important
    }

    .ms-md-3 {
        margin-left: 1rem!important
    }

    .ms-md-4 {
        margin-left: 1.5rem!important
    }

    .ms-md-5 {
        margin-left: 3rem!important
    }

    .ms-md-6 {
        margin-left: 12px!important
    }

    .ms-md-7 {
        margin-left: 30px!important
    }

    .ms-md-8 {
        margin-left: 10px!important
    }

    .ms-md-9 {
        margin-left: 20px!important
    }

    .ms-md-auto {
        margin-left: auto!important
    }

    .m-md-n1 {
        margin: -0.25rem!important
    }

    .m-md-n2 {
        margin: -0.5rem!important
    }

    .m-md-n3 {
        margin: -1rem!important
    }

    .m-md-n4 {
        margin: -1.5rem!important
    }

    .m-md-n5 {
        margin: -3rem!important
    }

    .m-md-n6 {
        margin: -12px!important
    }

    .m-md-n7 {
        margin: -30px!important
    }

    .m-md-n8 {
        margin: -10px!important
    }

    .m-md-n9 {
        margin: -20px!important
    }

    .mx-md-n1 {
        margin-right: -0.25rem!important;
        margin-left: -0.25rem!important
    }

    .mx-md-n2 {
        margin-right: -0.5rem!important;
        margin-left: -0.5rem!important
    }

    .mx-md-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-md-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-md-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-md-n6 {
        margin-right: -12px!important;
        margin-left: -12px!important
    }

    .mx-md-n7 {
        margin-right: -30px!important;
        margin-left: -30px!important
    }

    .mx-md-n8 {
        margin-right: -10px!important;
        margin-left: -10px!important
    }

    .mx-md-n9 {
        margin-right: -20px!important;
        margin-left: -20px!important
    }

    .my-md-n1 {
        margin-top: -0.25rem!important;
        margin-bottom: -0.25rem!important
    }

    .my-md-n2 {
        margin-top: -0.5rem!important;
        margin-bottom: -0.5rem!important
    }

    .my-md-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-md-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-md-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-md-n6 {
        margin-top: -12px!important;
        margin-bottom: -12px!important
    }

    .my-md-n7 {
        margin-top: -30px!important;
        margin-bottom: -30px!important
    }

    .my-md-n8 {
        margin-top: -10px!important;
        margin-bottom: -10px!important
    }

    .my-md-n9 {
        margin-top: -20px!important;
        margin-bottom: -20px!important
    }

    .mt-md-n1 {
        margin-top: -0.25rem!important
    }

    .mt-md-n2 {
        margin-top: -0.5rem!important
    }

    .mt-md-n3 {
        margin-top: -1rem!important
    }

    .mt-md-n4 {
        margin-top: -1.5rem!important
    }

    .mt-md-n5 {
        margin-top: -3rem!important
    }

    .mt-md-n6 {
        margin-top: -12px!important
    }

    .mt-md-n7 {
        margin-top: -30px!important
    }

    .mt-md-n8 {
        margin-top: -10px!important
    }

    .mt-md-n9 {
        margin-top: -20px!important
    }

    .me-md-n1 {
        margin-right: -0.25rem!important
    }

    .me-md-n2 {
        margin-right: -0.5rem!important
    }

    .me-md-n3 {
        margin-right: -1rem!important
    }

    .me-md-n4 {
        margin-right: -1.5rem!important
    }

    .me-md-n5 {
        margin-right: -3rem!important
    }

    .me-md-n6 {
        margin-right: -12px!important
    }

    .me-md-n7 {
        margin-right: -30px!important
    }

    .me-md-n8 {
        margin-right: -10px!important
    }

    .me-md-n9 {
        margin-right: -20px!important
    }

    .mb-md-n1 {
        margin-bottom: -0.25rem!important
    }

    .mb-md-n2 {
        margin-bottom: -0.5rem!important
    }

    .mb-md-n3 {
        margin-bottom: -1rem!important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-md-n5 {
        margin-bottom: -3rem!important
    }

    .mb-md-n6 {
        margin-bottom: -12px!important
    }

    .mb-md-n7 {
        margin-bottom: -30px!important
    }

    .mb-md-n8 {
        margin-bottom: -10px!important
    }

    .mb-md-n9 {
        margin-bottom: -20px!important
    }

    .ms-md-n1 {
        margin-left: -0.25rem!important
    }

    .ms-md-n2 {
        margin-left: -0.5rem!important
    }

    .ms-md-n3 {
        margin-left: -1rem!important
    }

    .ms-md-n4 {
        margin-left: -1.5rem!important
    }

    .ms-md-n5 {
        margin-left: -3rem!important
    }

    .ms-md-n6 {
        margin-left: -12px!important
    }

    .ms-md-n7 {
        margin-left: -30px!important
    }

    .ms-md-n8 {
        margin-left: -10px!important
    }

    .ms-md-n9 {
        margin-left: -20px!important
    }

    .p-md-0 {
        padding: 0!important
    }

    .p-md-1 {
        padding: 0.25rem!important
    }

    .p-md-2 {
        padding: 0.5rem!important
    }

    .p-md-3 {
        padding: 1rem!important
    }

    .p-md-4 {
        padding: 1.5rem!important
    }

    .p-md-5 {
        padding: 3rem!important
    }

    .p-md-6 {
        padding: 12px!important
    }

    .p-md-7 {
        padding: 30px!important
    }

    .p-md-8 {
        padding: 10px!important
    }

    .p-md-9 {
        padding: 20px!important
    }

    .px-md-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-md-1 {
        padding-right: 0.25rem!important;
        padding-left: 0.25rem!important
    }

    .px-md-2 {
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important
    }

    .px-md-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-md-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-md-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-md-6 {
        padding-right: 12px!important;
        padding-left: 12px!important
    }

    .px-md-7 {
        padding-right: 30px!important;
        padding-left: 30px!important
    }

    .px-md-8 {
        padding-right: 10px!important;
        padding-left: 10px!important
    }

    .px-md-9 {
        padding-right: 20px!important;
        padding-left: 20px!important
    }

    .py-md-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-md-1 {
        padding-top: 0.25rem!important;
        padding-bottom: 0.25rem!important
    }

    .py-md-2 {
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important
    }

    .py-md-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-md-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-md-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-md-6 {
        padding-top: 12px!important;
        padding-bottom: 12px!important
    }

    .py-md-7 {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }

    .py-md-8 {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }

    .py-md-9 {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }

    .pt-md-0 {
        padding-top: 0!important
    }

    .pt-md-1 {
        padding-top: 0.25rem!important
    }

    .pt-md-2 {
        padding-top: 0.5rem!important
    }

    .pt-md-3 {
        padding-top: 1rem!important
    }

    .pt-md-4 {
        padding-top: 1.5rem!important
    }

    .pt-md-5 {
        padding-top: 3rem!important
    }

    .pt-md-6 {
        padding-top: 12px!important
    }

    .pt-md-7 {
        padding-top: 30px!important
    }

    .pt-md-8 {
        padding-top: 10px!important
    }

    .pt-md-9 {
        padding-top: 20px!important
    }

    .pe-md-0 {
        padding-right: 0!important
    }

    .pe-md-1 {
        padding-right: 0.25rem!important
    }

    .pe-md-2 {
        padding-right: 0.5rem!important
    }

    .pe-md-3 {
        padding-right: 1rem!important
    }

    .pe-md-4 {
        padding-right: 1.5rem!important
    }

    .pe-md-5 {
        padding-right: 3rem!important
    }

    .pe-md-6 {
        padding-right: 12px!important
    }

    .pe-md-7 {
        padding-right: 30px!important
    }

    .pe-md-8 {
        padding-right: 10px!important
    }

    .pe-md-9 {
        padding-right: 20px!important
    }

    .pb-md-0 {
        padding-bottom: 0!important
    }

    .pb-md-1 {
        padding-bottom: 0.25rem!important
    }

    .pb-md-2 {
        padding-bottom: 0.5rem!important
    }

    .pb-md-3 {
        padding-bottom: 1rem!important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-md-5 {
        padding-bottom: 3rem!important
    }

    .pb-md-6 {
        padding-bottom: 12px!important
    }

    .pb-md-7 {
        padding-bottom: 30px!important
    }

    .pb-md-8 {
        padding-bottom: 10px!important
    }

    .pb-md-9 {
        padding-bottom: 20px!important
    }

    .ps-md-0 {
        padding-left: 0!important
    }

    .ps-md-1 {
        padding-left: 0.25rem!important
    }

    .ps-md-2 {
        padding-left: 0.5rem!important
    }

    .ps-md-3 {
        padding-left: 1rem!important
    }

    .ps-md-4 {
        padding-left: 1.5rem!important
    }

    .ps-md-5 {
        padding-left: 3rem!important
    }

    .ps-md-6 {
        padding-left: 12px!important
    }

    .ps-md-7 {
        padding-left: 30px!important
    }

    .ps-md-8 {
        padding-left: 10px!important
    }

    .ps-md-9 {
        padding-left: 20px!important
    }

    .gap-md-0 {
        gap: 0!important
    }

    .gap-md-1 {
        gap: 0.25rem!important
    }

    .gap-md-2 {
        gap: 0.5rem!important
    }

    .gap-md-3 {
        gap: 1rem!important
    }

    .gap-md-4 {
        gap: 1.5rem!important
    }

    .gap-md-5 {
        gap: 3rem!important
    }

    .gap-md-6 {
        gap: 12px!important
    }

    .gap-md-7 {
        gap: 30px!important
    }

    .gap-md-8 {
        gap: 10px!important
    }

    .gap-md-9 {
        gap: 20px!important
    }

    .row-gap-md-0 {
        row-gap: 0!important
    }

    .row-gap-md-1 {
        row-gap: 0.25rem!important
    }

    .row-gap-md-2 {
        row-gap: 0.5rem!important
    }

    .row-gap-md-3 {
        row-gap: 1rem!important
    }

    .row-gap-md-4 {
        row-gap: 1.5rem!important
    }

    .row-gap-md-5 {
        row-gap: 3rem!important
    }

    .row-gap-md-6 {
        row-gap: 12px!important
    }

    .row-gap-md-7 {
        row-gap: 30px!important
    }

    .row-gap-md-8 {
        row-gap: 10px!important
    }

    .row-gap-md-9 {
        row-gap: 20px!important
    }

    .column-gap-md-0 {
        -webkit-column-gap: 0!important;
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-md-1 {
        -webkit-column-gap: 0.25rem!important;
        -moz-column-gap: 0.25rem!important;
        column-gap: 0.25rem!important
    }

    .column-gap-md-2 {
        -webkit-column-gap: 0.5rem!important;
        -moz-column-gap: 0.5rem!important;
        column-gap: 0.5rem!important
    }

    .column-gap-md-3 {
        -webkit-column-gap: 1rem!important;
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-md-4 {
        -webkit-column-gap: 1.5rem!important;
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-md-5 {
        -webkit-column-gap: 3rem!important;
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-md-6 {
        -webkit-column-gap: 12px!important;
        -moz-column-gap: 12px!important;
        column-gap: 12px!important
    }

    .column-gap-md-7 {
        -webkit-column-gap: 30px!important;
        -moz-column-gap: 30px!important;
        column-gap: 30px!important
    }

    .column-gap-md-8 {
        -webkit-column-gap: 10px!important;
        -moz-column-gap: 10px!important;
        column-gap: 10px!important
    }

    .column-gap-md-9 {
        -webkit-column-gap: 20px!important;
        -moz-column-gap: 20px!important;
        column-gap: 20px!important
    }

    .text-md-start {
        text-align: left!important
    }

    .text-md-end {
        text-align: right!important
    }

    .text-md-center {
        text-align: center!important
    }
}

@media (min-width: 992px) {
    .float-lg-start {
        float:left!important
    }

    .float-lg-end {
        float: right!important
    }

    .float-lg-none {
        float: none!important
    }

    .object-fit-lg-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-lg-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-lg-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-lg-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-lg-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-grid {
        display: -ms-grid!important;
        display: grid!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-lg-none {
        display: none!important
    }

    .flex-lg-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-lg-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-lg-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-lg-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-lg-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-lg-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-lg-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-lg-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-lg-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-lg-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-lg-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-lg-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto!important;
        -ms-grid-row-align: auto!important;
        align-self: auto!important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center!important;
        -ms-grid-row-align: center!important;
        align-self: center!important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch!important;
        -ms-grid-row-align: stretch!important;
        align-self: stretch!important
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-lg-0 {
        margin: 0!important
    }

    .m-lg-1 {
        margin: 0.25rem!important
    }

    .m-lg-2 {
        margin: 0.5rem!important
    }

    .m-lg-3 {
        margin: 1rem!important
    }

    .m-lg-4 {
        margin: 1.5rem!important
    }

    .m-lg-5 {
        margin: 3rem!important
    }

    .m-lg-6 {
        margin: 12px!important
    }

    .m-lg-7 {
        margin: 30px!important
    }

    .m-lg-8 {
        margin: 10px!important
    }

    .m-lg-9 {
        margin: 20px!important
    }

    .m-lg-auto {
        margin: auto!important
    }

    .mx-lg-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-lg-1 {
        margin-right: 0.25rem!important;
        margin-left: 0.25rem!important
    }

    .mx-lg-2 {
        margin-right: 0.5rem!important;
        margin-left: 0.5rem!important
    }

    .mx-lg-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-lg-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-lg-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-lg-6 {
        margin-right: 12px!important;
        margin-left: 12px!important
    }

    .mx-lg-7 {
        margin-right: 30px!important;
        margin-left: 30px!important
    }

    .mx-lg-8 {
        margin-right: 10px!important;
        margin-left: 10px!important
    }

    .mx-lg-9 {
        margin-right: 20px!important;
        margin-left: 20px!important
    }

    .mx-lg-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-lg-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-lg-1 {
        margin-top: 0.25rem!important;
        margin-bottom: 0.25rem!important
    }

    .my-lg-2 {
        margin-top: 0.5rem!important;
        margin-bottom: 0.5rem!important
    }

    .my-lg-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-lg-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-lg-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-lg-6 {
        margin-top: 12px!important;
        margin-bottom: 12px!important
    }

    .my-lg-7 {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }

    .my-lg-8 {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }

    .my-lg-9 {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }

    .my-lg-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-lg-0 {
        margin-top: 0!important
    }

    .mt-lg-1 {
        margin-top: 0.25rem!important
    }

    .mt-lg-2 {
        margin-top: 0.5rem!important
    }

    .mt-lg-3 {
        margin-top: 1rem!important
    }

    .mt-lg-4 {
        margin-top: 1.5rem!important
    }

    .mt-lg-5 {
        margin-top: 3rem!important
    }

    .mt-lg-6 {
        margin-top: 12px!important
    }

    .mt-lg-7 {
        margin-top: 30px!important
    }

    .mt-lg-8 {
        margin-top: 10px!important
    }

    .mt-lg-9 {
        margin-top: 20px!important
    }

    .mt-lg-auto {
        margin-top: auto!important
    }

    .me-lg-0 {
        margin-right: 0!important
    }

    .me-lg-1 {
        margin-right: 0.25rem!important
    }

    .me-lg-2 {
        margin-right: 0.5rem!important
    }

    .me-lg-3 {
        margin-right: 1rem!important
    }

    .me-lg-4 {
        margin-right: 1.5rem!important
    }

    .me-lg-5 {
        margin-right: 3rem!important
    }

    .me-lg-6 {
        margin-right: 12px!important
    }

    .me-lg-7 {
        margin-right: 30px!important
    }

    .me-lg-8 {
        margin-right: 10px!important
    }

    .me-lg-9 {
        margin-right: 20px!important
    }

    .me-lg-auto {
        margin-right: auto!important
    }

    .mb-lg-0 {
        margin-bottom: 0!important
    }

    .mb-lg-1 {
        margin-bottom: 0.25rem!important
    }

    .mb-lg-2 {
        margin-bottom: 0.5rem!important
    }

    .mb-lg-3 {
        margin-bottom: 1rem!important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-lg-5 {
        margin-bottom: 3rem!important
    }

    .mb-lg-6 {
        margin-bottom: 12px!important
    }

    .mb-lg-7 {
        margin-bottom: 30px!important
    }

    .mb-lg-8 {
        margin-bottom: 10px!important
    }

    .mb-lg-9 {
        margin-bottom: 20px!important
    }

    .mb-lg-auto {
        margin-bottom: auto!important
    }

    .ms-lg-0 {
        margin-left: 0!important
    }

    .ms-lg-1 {
        margin-left: 0.25rem!important
    }

    .ms-lg-2 {
        margin-left: 0.5rem!important
    }

    .ms-lg-3 {
        margin-left: 1rem!important
    }

    .ms-lg-4 {
        margin-left: 1.5rem!important
    }

    .ms-lg-5 {
        margin-left: 3rem!important
    }

    .ms-lg-6 {
        margin-left: 12px!important
    }

    .ms-lg-7 {
        margin-left: 30px!important
    }

    .ms-lg-8 {
        margin-left: 10px!important
    }

    .ms-lg-9 {
        margin-left: 20px!important
    }

    .ms-lg-auto {
        margin-left: auto!important
    }

    .m-lg-n1 {
        margin: -0.25rem!important
    }

    .m-lg-n2 {
        margin: -0.5rem!important
    }

    .m-lg-n3 {
        margin: -1rem!important
    }

    .m-lg-n4 {
        margin: -1.5rem!important
    }

    .m-lg-n5 {
        margin: -3rem!important
    }

    .m-lg-n6 {
        margin: -12px!important
    }

    .m-lg-n7 {
        margin: -30px!important
    }

    .m-lg-n8 {
        margin: -10px!important
    }

    .m-lg-n9 {
        margin: -20px!important
    }

    .mx-lg-n1 {
        margin-right: -0.25rem!important;
        margin-left: -0.25rem!important
    }

    .mx-lg-n2 {
        margin-right: -0.5rem!important;
        margin-left: -0.5rem!important
    }

    .mx-lg-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-lg-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-lg-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-lg-n6 {
        margin-right: -12px!important;
        margin-left: -12px!important
    }

    .mx-lg-n7 {
        margin-right: -30px!important;
        margin-left: -30px!important
    }

    .mx-lg-n8 {
        margin-right: -10px!important;
        margin-left: -10px!important
    }

    .mx-lg-n9 {
        margin-right: -20px!important;
        margin-left: -20px!important
    }

    .my-lg-n1 {
        margin-top: -0.25rem!important;
        margin-bottom: -0.25rem!important
    }

    .my-lg-n2 {
        margin-top: -0.5rem!important;
        margin-bottom: -0.5rem!important
    }

    .my-lg-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-lg-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-lg-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-lg-n6 {
        margin-top: -12px!important;
        margin-bottom: -12px!important
    }

    .my-lg-n7 {
        margin-top: -30px!important;
        margin-bottom: -30px!important
    }

    .my-lg-n8 {
        margin-top: -10px!important;
        margin-bottom: -10px!important
    }

    .my-lg-n9 {
        margin-top: -20px!important;
        margin-bottom: -20px!important
    }

    .mt-lg-n1 {
        margin-top: -0.25rem!important
    }

    .mt-lg-n2 {
        margin-top: -0.5rem!important
    }

    .mt-lg-n3 {
        margin-top: -1rem!important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem!important
    }

    .mt-lg-n5 {
        margin-top: -3rem!important
    }

    .mt-lg-n6 {
        margin-top: -12px!important
    }

    .mt-lg-n7 {
        margin-top: -30px!important
    }

    .mt-lg-n8 {
        margin-top: -10px!important
    }

    .mt-lg-n9 {
        margin-top: -20px!important
    }

    .me-lg-n1 {
        margin-right: -0.25rem!important
    }

    .me-lg-n2 {
        margin-right: -0.5rem!important
    }

    .me-lg-n3 {
        margin-right: -1rem!important
    }

    .me-lg-n4 {
        margin-right: -1.5rem!important
    }

    .me-lg-n5 {
        margin-right: -3rem!important
    }

    .me-lg-n6 {
        margin-right: -12px!important
    }

    .me-lg-n7 {
        margin-right: -30px!important
    }

    .me-lg-n8 {
        margin-right: -10px!important
    }

    .me-lg-n9 {
        margin-right: -20px!important
    }

    .mb-lg-n1 {
        margin-bottom: -0.25rem!important
    }

    .mb-lg-n2 {
        margin-bottom: -0.5rem!important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem!important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-lg-n5 {
        margin-bottom: -3rem!important
    }

    .mb-lg-n6 {
        margin-bottom: -12px!important
    }

    .mb-lg-n7 {
        margin-bottom: -30px!important
    }

    .mb-lg-n8 {
        margin-bottom: -10px!important
    }

    .mb-lg-n9 {
        margin-bottom: -20px!important
    }

    .ms-lg-n1 {
        margin-left: -0.25rem!important
    }

    .ms-lg-n2 {
        margin-left: -0.5rem!important
    }

    .ms-lg-n3 {
        margin-left: -1rem!important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem!important
    }

    .ms-lg-n5 {
        margin-left: -3rem!important
    }

    .ms-lg-n6 {
        margin-left: -12px!important
    }

    .ms-lg-n7 {
        margin-left: -30px!important
    }

    .ms-lg-n8 {
        margin-left: -10px!important
    }

    .ms-lg-n9 {
        margin-left: -20px!important
    }

    .p-lg-0 {
        padding: 0!important
    }

    .p-lg-1 {
        padding: 0.25rem!important
    }

    .p-lg-2 {
        padding: 0.5rem!important
    }

    .p-lg-3 {
        padding: 1rem!important
    }

    .p-lg-4 {
        padding: 1.5rem!important
    }

    .p-lg-5 {
        padding: 3rem!important
    }

    .p-lg-6 {
        padding: 12px!important
    }

    .p-lg-7 {
        padding: 30px!important
    }

    .p-lg-8 {
        padding: 10px!important
    }

    .p-lg-9 {
        padding: 20px!important
    }

    .px-lg-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-lg-1 {
        padding-right: 0.25rem!important;
        padding-left: 0.25rem!important
    }

    .px-lg-2 {
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important
    }

    .px-lg-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-lg-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-lg-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-lg-6 {
        padding-right: 12px!important;
        padding-left: 12px!important
    }

    .px-lg-7 {
        padding-right: 30px!important;
        padding-left: 30px!important
    }

    .px-lg-8 {
        padding-right: 10px!important;
        padding-left: 10px!important
    }

    .px-lg-9 {
        padding-right: 20px!important;
        padding-left: 20px!important
    }

    .py-lg-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-lg-1 {
        padding-top: 0.25rem!important;
        padding-bottom: 0.25rem!important
    }

    .py-lg-2 {
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important
    }

    .py-lg-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-lg-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-lg-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-lg-6 {
        padding-top: 12px!important;
        padding-bottom: 12px!important
    }

    .py-lg-7 {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }

    .py-lg-8 {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }

    .py-lg-9 {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }

    .pt-lg-0 {
        padding-top: 0!important
    }

    .pt-lg-1 {
        padding-top: 0.25rem!important
    }

    .pt-lg-2 {
        padding-top: 0.5rem!important
    }

    .pt-lg-3 {
        padding-top: 1rem!important
    }

    .pt-lg-4 {
        padding-top: 1.5rem!important
    }

    .pt-lg-5 {
        padding-top: 3rem!important
    }

    .pt-lg-6 {
        padding-top: 12px!important
    }

    .pt-lg-7 {
        padding-top: 30px!important
    }

    .pt-lg-8 {
        padding-top: 10px!important
    }

    .pt-lg-9 {
        padding-top: 20px!important
    }

    .pe-lg-0 {
        padding-right: 0!important
    }

    .pe-lg-1 {
        padding-right: 0.25rem!important
    }

    .pe-lg-2 {
        padding-right: 0.5rem!important
    }

    .pe-lg-3 {
        padding-right: 1rem!important
    }

    .pe-lg-4 {
        padding-right: 1.5rem!important
    }

    .pe-lg-5 {
        padding-right: 3rem!important
    }

    .pe-lg-6 {
        padding-right: 12px!important
    }

    .pe-lg-7 {
        padding-right: 30px!important
    }

    .pe-lg-8 {
        padding-right: 10px!important
    }

    .pe-lg-9 {
        padding-right: 20px!important
    }

    .pb-lg-0 {
        padding-bottom: 0!important
    }

    .pb-lg-1 {
        padding-bottom: 0.25rem!important
    }

    .pb-lg-2 {
        padding-bottom: 0.5rem!important
    }

    .pb-lg-3 {
        padding-bottom: 1rem!important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-lg-5 {
        padding-bottom: 3rem!important
    }

    .pb-lg-6 {
        padding-bottom: 12px!important
    }

    .pb-lg-7 {
        padding-bottom: 30px!important
    }

    .pb-lg-8 {
        padding-bottom: 10px!important
    }

    .pb-lg-9 {
        padding-bottom: 20px!important
    }

    .ps-lg-0 {
        padding-left: 0!important
    }

    .ps-lg-1 {
        padding-left: 0.25rem!important
    }

    .ps-lg-2 {
        padding-left: 0.5rem!important
    }

    .ps-lg-3 {
        padding-left: 1rem!important
    }

    .ps-lg-4 {
        padding-left: 1.5rem!important
    }

    .ps-lg-5 {
        padding-left: 3rem!important
    }

    .ps-lg-6 {
        padding-left: 12px!important
    }

    .ps-lg-7 {
        padding-left: 30px!important
    }

    .ps-lg-8 {
        padding-left: 10px!important
    }

    .ps-lg-9 {
        padding-left: 20px!important
    }

    .gap-lg-0 {
        gap: 0!important
    }

    .gap-lg-1 {
        gap: 0.25rem!important
    }

    .gap-lg-2 {
        gap: 0.5rem!important
    }

    .gap-lg-3 {
        gap: 1rem!important
    }

    .gap-lg-4 {
        gap: 1.5rem!important
    }

    .gap-lg-5 {
        gap: 3rem!important
    }

    .gap-lg-6 {
        gap: 12px!important
    }

    .gap-lg-7 {
        gap: 30px!important
    }

    .gap-lg-8 {
        gap: 10px!important
    }

    .gap-lg-9 {
        gap: 20px!important
    }

    .row-gap-lg-0 {
        row-gap: 0!important
    }

    .row-gap-lg-1 {
        row-gap: 0.25rem!important
    }

    .row-gap-lg-2 {
        row-gap: 0.5rem!important
    }

    .row-gap-lg-3 {
        row-gap: 1rem!important
    }

    .row-gap-lg-4 {
        row-gap: 1.5rem!important
    }

    .row-gap-lg-5 {
        row-gap: 3rem!important
    }

    .row-gap-lg-6 {
        row-gap: 12px!important
    }

    .row-gap-lg-7 {
        row-gap: 30px!important
    }

    .row-gap-lg-8 {
        row-gap: 10px!important
    }

    .row-gap-lg-9 {
        row-gap: 20px!important
    }

    .column-gap-lg-0 {
        -webkit-column-gap: 0!important;
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-lg-1 {
        -webkit-column-gap: 0.25rem!important;
        -moz-column-gap: 0.25rem!important;
        column-gap: 0.25rem!important
    }

    .column-gap-lg-2 {
        -webkit-column-gap: 0.5rem!important;
        -moz-column-gap: 0.5rem!important;
        column-gap: 0.5rem!important
    }

    .column-gap-lg-3 {
        -webkit-column-gap: 1rem!important;
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-lg-4 {
        -webkit-column-gap: 1.5rem!important;
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-lg-5 {
        -webkit-column-gap: 3rem!important;
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-lg-6 {
        -webkit-column-gap: 12px!important;
        -moz-column-gap: 12px!important;
        column-gap: 12px!important
    }

    .column-gap-lg-7 {
        -webkit-column-gap: 30px!important;
        -moz-column-gap: 30px!important;
        column-gap: 30px!important
    }

    .column-gap-lg-8 {
        -webkit-column-gap: 10px!important;
        -moz-column-gap: 10px!important;
        column-gap: 10px!important
    }

    .column-gap-lg-9 {
        -webkit-column-gap: 20px!important;
        -moz-column-gap: 20px!important;
        column-gap: 20px!important
    }

    .text-lg-start {
        text-align: left!important
    }

    .text-lg-end {
        text-align: right!important
    }

    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width: 1200px) {
    .float-xl-start {
        float:left!important
    }

    .float-xl-end {
        float: right!important
    }

    .float-xl-none {
        float: none!important
    }

    .object-fit-xl-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-xl-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-xl-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-xl-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-xl-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-grid {
        display: -ms-grid!important;
        display: grid!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-xl-none {
        display: none!important
    }

    .flex-xl-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-xl-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xl-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-xl-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xl-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xl-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xl-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-xl-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-xl-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xl-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xl-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto!important;
        -ms-grid-row-align: auto!important;
        align-self: auto!important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center!important;
        -ms-grid-row-align: center!important;
        align-self: center!important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch!important;
        -ms-grid-row-align: stretch!important;
        align-self: stretch!important
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-xl-0 {
        margin: 0!important
    }

    .m-xl-1 {
        margin: 0.25rem!important
    }

    .m-xl-2 {
        margin: 0.5rem!important
    }

    .m-xl-3 {
        margin: 1rem!important
    }

    .m-xl-4 {
        margin: 1.5rem!important
    }

    .m-xl-5 {
        margin: 3rem!important
    }

    .m-xl-6 {
        margin: 12px!important
    }

    .m-xl-7 {
        margin: 30px!important
    }

    .m-xl-8 {
        margin: 10px!important
    }

    .m-xl-9 {
        margin: 20px!important
    }

    .m-xl-auto {
        margin: auto!important
    }

    .mx-xl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xl-1 {
        margin-right: 0.25rem!important;
        margin-left: 0.25rem!important
    }

    .mx-xl-2 {
        margin-right: 0.5rem!important;
        margin-left: 0.5rem!important
    }

    .mx-xl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xl-6 {
        margin-right: 12px!important;
        margin-left: 12px!important
    }

    .mx-xl-7 {
        margin-right: 30px!important;
        margin-left: 30px!important
    }

    .mx-xl-8 {
        margin-right: 10px!important;
        margin-left: 10px!important
    }

    .mx-xl-9 {
        margin-right: 20px!important;
        margin-left: 20px!important
    }

    .mx-xl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xl-1 {
        margin-top: 0.25rem!important;
        margin-bottom: 0.25rem!important
    }

    .my-xl-2 {
        margin-top: 0.5rem!important;
        margin-bottom: 0.5rem!important
    }

    .my-xl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xl-6 {
        margin-top: 12px!important;
        margin-bottom: 12px!important
    }

    .my-xl-7 {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }

    .my-xl-8 {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }

    .my-xl-9 {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }

    .my-xl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xl-0 {
        margin-top: 0!important
    }

    .mt-xl-1 {
        margin-top: 0.25rem!important
    }

    .mt-xl-2 {
        margin-top: 0.5rem!important
    }

    .mt-xl-3 {
        margin-top: 1rem!important
    }

    .mt-xl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xl-5 {
        margin-top: 3rem!important
    }

    .mt-xl-6 {
        margin-top: 12px!important
    }

    .mt-xl-7 {
        margin-top: 30px!important
    }

    .mt-xl-8 {
        margin-top: 10px!important
    }

    .mt-xl-9 {
        margin-top: 20px!important
    }

    .mt-xl-auto {
        margin-top: auto!important
    }

    .me-xl-0 {
        margin-right: 0!important
    }

    .me-xl-1 {
        margin-right: 0.25rem!important
    }

    .me-xl-2 {
        margin-right: 0.5rem!important
    }

    .me-xl-3 {
        margin-right: 1rem!important
    }

    .me-xl-4 {
        margin-right: 1.5rem!important
    }

    .me-xl-5 {
        margin-right: 3rem!important
    }

    .me-xl-6 {
        margin-right: 12px!important
    }

    .me-xl-7 {
        margin-right: 30px!important
    }

    .me-xl-8 {
        margin-right: 10px!important
    }

    .me-xl-9 {
        margin-right: 20px!important
    }

    .me-xl-auto {
        margin-right: auto!important
    }

    .mb-xl-0 {
        margin-bottom: 0!important
    }

    .mb-xl-1 {
        margin-bottom: 0.25rem!important
    }

    .mb-xl-2 {
        margin-bottom: 0.5rem!important
    }

    .mb-xl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xl-6 {
        margin-bottom: 12px!important
    }

    .mb-xl-7 {
        margin-bottom: 30px!important
    }

    .mb-xl-8 {
        margin-bottom: 10px!important
    }

    .mb-xl-9 {
        margin-bottom: 20px!important
    }

    .mb-xl-auto {
        margin-bottom: auto!important
    }

    .ms-xl-0 {
        margin-left: 0!important
    }

    .ms-xl-1 {
        margin-left: 0.25rem!important
    }

    .ms-xl-2 {
        margin-left: 0.5rem!important
    }

    .ms-xl-3 {
        margin-left: 1rem!important
    }

    .ms-xl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xl-5 {
        margin-left: 3rem!important
    }

    .ms-xl-6 {
        margin-left: 12px!important
    }

    .ms-xl-7 {
        margin-left: 30px!important
    }

    .ms-xl-8 {
        margin-left: 10px!important
    }

    .ms-xl-9 {
        margin-left: 20px!important
    }

    .ms-xl-auto {
        margin-left: auto!important
    }

    .m-xl-n1 {
        margin: -0.25rem!important
    }

    .m-xl-n2 {
        margin: -0.5rem!important
    }

    .m-xl-n3 {
        margin: -1rem!important
    }

    .m-xl-n4 {
        margin: -1.5rem!important
    }

    .m-xl-n5 {
        margin: -3rem!important
    }

    .m-xl-n6 {
        margin: -12px!important
    }

    .m-xl-n7 {
        margin: -30px!important
    }

    .m-xl-n8 {
        margin: -10px!important
    }

    .m-xl-n9 {
        margin: -20px!important
    }

    .mx-xl-n1 {
        margin-right: -0.25rem!important;
        margin-left: -0.25rem!important
    }

    .mx-xl-n2 {
        margin-right: -0.5rem!important;
        margin-left: -0.5rem!important
    }

    .mx-xl-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-xl-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-xl-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-xl-n6 {
        margin-right: -12px!important;
        margin-left: -12px!important
    }

    .mx-xl-n7 {
        margin-right: -30px!important;
        margin-left: -30px!important
    }

    .mx-xl-n8 {
        margin-right: -10px!important;
        margin-left: -10px!important
    }

    .mx-xl-n9 {
        margin-right: -20px!important;
        margin-left: -20px!important
    }

    .my-xl-n1 {
        margin-top: -0.25rem!important;
        margin-bottom: -0.25rem!important
    }

    .my-xl-n2 {
        margin-top: -0.5rem!important;
        margin-bottom: -0.5rem!important
    }

    .my-xl-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-xl-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-xl-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-xl-n6 {
        margin-top: -12px!important;
        margin-bottom: -12px!important
    }

    .my-xl-n7 {
        margin-top: -30px!important;
        margin-bottom: -30px!important
    }

    .my-xl-n8 {
        margin-top: -10px!important;
        margin-bottom: -10px!important
    }

    .my-xl-n9 {
        margin-top: -20px!important;
        margin-bottom: -20px!important
    }

    .mt-xl-n1 {
        margin-top: -0.25rem!important
    }

    .mt-xl-n2 {
        margin-top: -0.5rem!important
    }

    .mt-xl-n3 {
        margin-top: -1rem!important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem!important
    }

    .mt-xl-n5 {
        margin-top: -3rem!important
    }

    .mt-xl-n6 {
        margin-top: -12px!important
    }

    .mt-xl-n7 {
        margin-top: -30px!important
    }

    .mt-xl-n8 {
        margin-top: -10px!important
    }

    .mt-xl-n9 {
        margin-top: -20px!important
    }

    .me-xl-n1 {
        margin-right: -0.25rem!important
    }

    .me-xl-n2 {
        margin-right: -0.5rem!important
    }

    .me-xl-n3 {
        margin-right: -1rem!important
    }

    .me-xl-n4 {
        margin-right: -1.5rem!important
    }

    .me-xl-n5 {
        margin-right: -3rem!important
    }

    .me-xl-n6 {
        margin-right: -12px!important
    }

    .me-xl-n7 {
        margin-right: -30px!important
    }

    .me-xl-n8 {
        margin-right: -10px!important
    }

    .me-xl-n9 {
        margin-right: -20px!important
    }

    .mb-xl-n1 {
        margin-bottom: -0.25rem!important
    }

    .mb-xl-n2 {
        margin-bottom: -0.5rem!important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem!important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-xl-n5 {
        margin-bottom: -3rem!important
    }

    .mb-xl-n6 {
        margin-bottom: -12px!important
    }

    .mb-xl-n7 {
        margin-bottom: -30px!important
    }

    .mb-xl-n8 {
        margin-bottom: -10px!important
    }

    .mb-xl-n9 {
        margin-bottom: -20px!important
    }

    .ms-xl-n1 {
        margin-left: -0.25rem!important
    }

    .ms-xl-n2 {
        margin-left: -0.5rem!important
    }

    .ms-xl-n3 {
        margin-left: -1rem!important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem!important
    }

    .ms-xl-n5 {
        margin-left: -3rem!important
    }

    .ms-xl-n6 {
        margin-left: -12px!important
    }

    .ms-xl-n7 {
        margin-left: -30px!important
    }

    .ms-xl-n8 {
        margin-left: -10px!important
    }

    .ms-xl-n9 {
        margin-left: -20px!important
    }

    .p-xl-0 {
        padding: 0!important
    }

    .p-xl-1 {
        padding: 0.25rem!important
    }

    .p-xl-2 {
        padding: 0.5rem!important
    }

    .p-xl-3 {
        padding: 1rem!important
    }

    .p-xl-4 {
        padding: 1.5rem!important
    }

    .p-xl-5 {
        padding: 3rem!important
    }

    .p-xl-6 {
        padding: 12px!important
    }

    .p-xl-7 {
        padding: 30px!important
    }

    .p-xl-8 {
        padding: 10px!important
    }

    .p-xl-9 {
        padding: 20px!important
    }

    .px-xl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xl-1 {
        padding-right: 0.25rem!important;
        padding-left: 0.25rem!important
    }

    .px-xl-2 {
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important
    }

    .px-xl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-xl-6 {
        padding-right: 12px!important;
        padding-left: 12px!important
    }

    .px-xl-7 {
        padding-right: 30px!important;
        padding-left: 30px!important
    }

    .px-xl-8 {
        padding-right: 10px!important;
        padding-left: 10px!important
    }

    .px-xl-9 {
        padding-right: 20px!important;
        padding-left: 20px!important
    }

    .py-xl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xl-1 {
        padding-top: 0.25rem!important;
        padding-bottom: 0.25rem!important
    }

    .py-xl-2 {
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important
    }

    .py-xl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-xl-6 {
        padding-top: 12px!important;
        padding-bottom: 12px!important
    }

    .py-xl-7 {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }

    .py-xl-8 {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }

    .py-xl-9 {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }

    .pt-xl-0 {
        padding-top: 0!important
    }

    .pt-xl-1 {
        padding-top: 0.25rem!important
    }

    .pt-xl-2 {
        padding-top: 0.5rem!important
    }

    .pt-xl-3 {
        padding-top: 1rem!important
    }

    .pt-xl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xl-5 {
        padding-top: 3rem!important
    }

    .pt-xl-6 {
        padding-top: 12px!important
    }

    .pt-xl-7 {
        padding-top: 30px!important
    }

    .pt-xl-8 {
        padding-top: 10px!important
    }

    .pt-xl-9 {
        padding-top: 20px!important
    }

    .pe-xl-0 {
        padding-right: 0!important
    }

    .pe-xl-1 {
        padding-right: 0.25rem!important
    }

    .pe-xl-2 {
        padding-right: 0.5rem!important
    }

    .pe-xl-3 {
        padding-right: 1rem!important
    }

    .pe-xl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xl-5 {
        padding-right: 3rem!important
    }

    .pe-xl-6 {
        padding-right: 12px!important
    }

    .pe-xl-7 {
        padding-right: 30px!important
    }

    .pe-xl-8 {
        padding-right: 10px!important
    }

    .pe-xl-9 {
        padding-right: 20px!important
    }

    .pb-xl-0 {
        padding-bottom: 0!important
    }

    .pb-xl-1 {
        padding-bottom: 0.25rem!important
    }

    .pb-xl-2 {
        padding-bottom: 0.5rem!important
    }

    .pb-xl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xl-5 {
        padding-bottom: 3rem!important
    }

    .pb-xl-6 {
        padding-bottom: 12px!important
    }

    .pb-xl-7 {
        padding-bottom: 30px!important
    }

    .pb-xl-8 {
        padding-bottom: 10px!important
    }

    .pb-xl-9 {
        padding-bottom: 20px!important
    }

    .ps-xl-0 {
        padding-left: 0!important
    }

    .ps-xl-1 {
        padding-left: 0.25rem!important
    }

    .ps-xl-2 {
        padding-left: 0.5rem!important
    }

    .ps-xl-3 {
        padding-left: 1rem!important
    }

    .ps-xl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xl-5 {
        padding-left: 3rem!important
    }

    .ps-xl-6 {
        padding-left: 12px!important
    }

    .ps-xl-7 {
        padding-left: 30px!important
    }

    .ps-xl-8 {
        padding-left: 10px!important
    }

    .ps-xl-9 {
        padding-left: 20px!important
    }

    .gap-xl-0 {
        gap: 0!important
    }

    .gap-xl-1 {
        gap: 0.25rem!important
    }

    .gap-xl-2 {
        gap: 0.5rem!important
    }

    .gap-xl-3 {
        gap: 1rem!important
    }

    .gap-xl-4 {
        gap: 1.5rem!important
    }

    .gap-xl-5 {
        gap: 3rem!important
    }

    .gap-xl-6 {
        gap: 12px!important
    }

    .gap-xl-7 {
        gap: 30px!important
    }

    .gap-xl-8 {
        gap: 10px!important
    }

    .gap-xl-9 {
        gap: 20px!important
    }

    .row-gap-xl-0 {
        row-gap: 0!important
    }

    .row-gap-xl-1 {
        row-gap: 0.25rem!important
    }

    .row-gap-xl-2 {
        row-gap: 0.5rem!important
    }

    .row-gap-xl-3 {
        row-gap: 1rem!important
    }

    .row-gap-xl-4 {
        row-gap: 1.5rem!important
    }

    .row-gap-xl-5 {
        row-gap: 3rem!important
    }

    .row-gap-xl-6 {
        row-gap: 12px!important
    }

    .row-gap-xl-7 {
        row-gap: 30px!important
    }

    .row-gap-xl-8 {
        row-gap: 10px!important
    }

    .row-gap-xl-9 {
        row-gap: 20px!important
    }

    .column-gap-xl-0 {
        -webkit-column-gap: 0!important;
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-xl-1 {
        -webkit-column-gap: 0.25rem!important;
        -moz-column-gap: 0.25rem!important;
        column-gap: 0.25rem!important
    }

    .column-gap-xl-2 {
        -webkit-column-gap: 0.5rem!important;
        -moz-column-gap: 0.5rem!important;
        column-gap: 0.5rem!important
    }

    .column-gap-xl-3 {
        -webkit-column-gap: 1rem!important;
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-xl-4 {
        -webkit-column-gap: 1.5rem!important;
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-xl-5 {
        -webkit-column-gap: 3rem!important;
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-xl-6 {
        -webkit-column-gap: 12px!important;
        -moz-column-gap: 12px!important;
        column-gap: 12px!important
    }

    .column-gap-xl-7 {
        -webkit-column-gap: 30px!important;
        -moz-column-gap: 30px!important;
        column-gap: 30px!important
    }

    .column-gap-xl-8 {
        -webkit-column-gap: 10px!important;
        -moz-column-gap: 10px!important;
        column-gap: 10px!important
    }

    .column-gap-xl-9 {
        -webkit-column-gap: 20px!important;
        -moz-column-gap: 20px!important;
        column-gap: 20px!important
    }

    .text-xl-start {
        text-align: left!important
    }

    .text-xl-end {
        text-align: right!important
    }

    .text-xl-center {
        text-align: center!important
    }
}

@media (min-width: 1400px) {
    .float-xxl-start {
        float:left!important
    }

    .float-xxl-end {
        float: right!important
    }

    .float-xxl-none {
        float: none!important
    }

    .object-fit-xxl-contain {
        -o-object-fit: contain!important;
        object-fit: contain!important
    }

    .object-fit-xxl-cover {
        -o-object-fit: cover!important;
        object-fit: cover!important
    }

    .object-fit-xxl-fill {
        -o-object-fit: fill!important;
        object-fit: fill!important
    }

    .object-fit-xxl-scale {
        -o-object-fit: scale-down!important;
        object-fit: scale-down!important
    }

    .object-fit-xxl-none {
        -o-object-fit: none!important;
        object-fit: none!important
    }

    .d-xxl-inline {
        display: inline!important
    }

    .d-xxl-inline-block {
        display: inline-block!important
    }

    .d-xxl-block {
        display: block!important
    }

    .d-xxl-grid {
        display: -ms-grid!important;
        display: grid!important
    }

    .d-xxl-table {
        display: table!important
    }

    .d-xxl-table-row {
        display: table-row!important
    }

    .d-xxl-table-cell {
        display: table-cell!important
    }

    .d-xxl-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xxl-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-xxl-none {
        display: none!important
    }

    .flex-xxl-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-xxl-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xxl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xxl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xxl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xxl-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-xxl-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-xxl-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-xxl-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xxl-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xxl-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xxl-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xxl-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-xxl-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-xxl-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xxl-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xxl-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xxl-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xxl-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto!important;
        -ms-grid-row-align: auto!important;
        align-self: auto!important
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center!important;
        -ms-grid-row-align: center!important;
        align-self: center!important
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch!important;
        -ms-grid-row-align: stretch!important;
        align-self: stretch!important
    }

    .order-xxl-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-xxl-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-xxl-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-xxl-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-xxl-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-xxl-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-xxl-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-xxl-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-xxl-0 {
        margin: 0!important
    }

    .m-xxl-1 {
        margin: 0.25rem!important
    }

    .m-xxl-2 {
        margin: 0.5rem!important
    }

    .m-xxl-3 {
        margin: 1rem!important
    }

    .m-xxl-4 {
        margin: 1.5rem!important
    }

    .m-xxl-5 {
        margin: 3rem!important
    }

    .m-xxl-6 {
        margin: 12px!important
    }

    .m-xxl-7 {
        margin: 30px!important
    }

    .m-xxl-8 {
        margin: 10px!important
    }

    .m-xxl-9 {
        margin: 20px!important
    }

    .m-xxl-auto {
        margin: auto!important
    }

    .mx-xxl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xxl-1 {
        margin-right: 0.25rem!important;
        margin-left: 0.25rem!important
    }

    .mx-xxl-2 {
        margin-right: 0.5rem!important;
        margin-left: 0.5rem!important
    }

    .mx-xxl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xxl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xxl-6 {
        margin-right: 12px!important;
        margin-left: 12px!important
    }

    .mx-xxl-7 {
        margin-right: 30px!important;
        margin-left: 30px!important
    }

    .mx-xxl-8 {
        margin-right: 10px!important;
        margin-left: 10px!important
    }

    .mx-xxl-9 {
        margin-right: 20px!important;
        margin-left: 20px!important
    }

    .mx-xxl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xxl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xxl-1 {
        margin-top: 0.25rem!important;
        margin-bottom: 0.25rem!important
    }

    .my-xxl-2 {
        margin-top: 0.5rem!important;
        margin-bottom: 0.5rem!important
    }

    .my-xxl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xxl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xxl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xxl-6 {
        margin-top: 12px!important;
        margin-bottom: 12px!important
    }

    .my-xxl-7 {
        margin-top: 30px!important;
        margin-bottom: 30px!important
    }

    .my-xxl-8 {
        margin-top: 10px!important;
        margin-bottom: 10px!important
    }

    .my-xxl-9 {
        margin-top: 20px!important;
        margin-bottom: 20px!important
    }

    .my-xxl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xxl-0 {
        margin-top: 0!important
    }

    .mt-xxl-1 {
        margin-top: 0.25rem!important
    }

    .mt-xxl-2 {
        margin-top: 0.5rem!important
    }

    .mt-xxl-3 {
        margin-top: 1rem!important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xxl-5 {
        margin-top: 3rem!important
    }

    .mt-xxl-6 {
        margin-top: 12px!important
    }

    .mt-xxl-7 {
        margin-top: 30px!important
    }

    .mt-xxl-8 {
        margin-top: 10px!important
    }

    .mt-xxl-9 {
        margin-top: 20px!important
    }

    .mt-xxl-auto {
        margin-top: auto!important
    }

    .me-xxl-0 {
        margin-right: 0!important
    }

    .me-xxl-1 {
        margin-right: 0.25rem!important
    }

    .me-xxl-2 {
        margin-right: 0.5rem!important
    }

    .me-xxl-3 {
        margin-right: 1rem!important
    }

    .me-xxl-4 {
        margin-right: 1.5rem!important
    }

    .me-xxl-5 {
        margin-right: 3rem!important
    }

    .me-xxl-6 {
        margin-right: 12px!important
    }

    .me-xxl-7 {
        margin-right: 30px!important
    }

    .me-xxl-8 {
        margin-right: 10px!important
    }

    .me-xxl-9 {
        margin-right: 20px!important
    }

    .me-xxl-auto {
        margin-right: auto!important
    }

    .mb-xxl-0 {
        margin-bottom: 0!important
    }

    .mb-xxl-1 {
        margin-bottom: 0.25rem!important
    }

    .mb-xxl-2 {
        margin-bottom: 0.5rem!important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xxl-6 {
        margin-bottom: 12px!important
    }

    .mb-xxl-7 {
        margin-bottom: 30px!important
    }

    .mb-xxl-8 {
        margin-bottom: 10px!important
    }

    .mb-xxl-9 {
        margin-bottom: 20px!important
    }

    .mb-xxl-auto {
        margin-bottom: auto!important
    }

    .ms-xxl-0 {
        margin-left: 0!important
    }

    .ms-xxl-1 {
        margin-left: 0.25rem!important
    }

    .ms-xxl-2 {
        margin-left: 0.5rem!important
    }

    .ms-xxl-3 {
        margin-left: 1rem!important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xxl-5 {
        margin-left: 3rem!important
    }

    .ms-xxl-6 {
        margin-left: 12px!important
    }

    .ms-xxl-7 {
        margin-left: 30px!important
    }

    .ms-xxl-8 {
        margin-left: 10px!important
    }

    .ms-xxl-9 {
        margin-left: 20px!important
    }

    .ms-xxl-auto {
        margin-left: auto!important
    }

    .m-xxl-n1 {
        margin: -0.25rem!important
    }

    .m-xxl-n2 {
        margin: -0.5rem!important
    }

    .m-xxl-n3 {
        margin: -1rem!important
    }

    .m-xxl-n4 {
        margin: -1.5rem!important
    }

    .m-xxl-n5 {
        margin: -3rem!important
    }

    .m-xxl-n6 {
        margin: -12px!important
    }

    .m-xxl-n7 {
        margin: -30px!important
    }

    .m-xxl-n8 {
        margin: -10px!important
    }

    .m-xxl-n9 {
        margin: -20px!important
    }

    .mx-xxl-n1 {
        margin-right: -0.25rem!important;
        margin-left: -0.25rem!important
    }

    .mx-xxl-n2 {
        margin-right: -0.5rem!important;
        margin-left: -0.5rem!important
    }

    .mx-xxl-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-xxl-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-xxl-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-xxl-n6 {
        margin-right: -12px!important;
        margin-left: -12px!important
    }

    .mx-xxl-n7 {
        margin-right: -30px!important;
        margin-left: -30px!important
    }

    .mx-xxl-n8 {
        margin-right: -10px!important;
        margin-left: -10px!important
    }

    .mx-xxl-n9 {
        margin-right: -20px!important;
        margin-left: -20px!important
    }

    .my-xxl-n1 {
        margin-top: -0.25rem!important;
        margin-bottom: -0.25rem!important
    }

    .my-xxl-n2 {
        margin-top: -0.5rem!important;
        margin-bottom: -0.5rem!important
    }

    .my-xxl-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-xxl-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-xxl-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-xxl-n6 {
        margin-top: -12px!important;
        margin-bottom: -12px!important
    }

    .my-xxl-n7 {
        margin-top: -30px!important;
        margin-bottom: -30px!important
    }

    .my-xxl-n8 {
        margin-top: -10px!important;
        margin-bottom: -10px!important
    }

    .my-xxl-n9 {
        margin-top: -20px!important;
        margin-bottom: -20px!important
    }

    .mt-xxl-n1 {
        margin-top: -0.25rem!important
    }

    .mt-xxl-n2 {
        margin-top: -0.5rem!important
    }

    .mt-xxl-n3 {
        margin-top: -1rem!important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem!important
    }

    .mt-xxl-n5 {
        margin-top: -3rem!important
    }

    .mt-xxl-n6 {
        margin-top: -12px!important
    }

    .mt-xxl-n7 {
        margin-top: -30px!important
    }

    .mt-xxl-n8 {
        margin-top: -10px!important
    }

    .mt-xxl-n9 {
        margin-top: -20px!important
    }

    .me-xxl-n1 {
        margin-right: -0.25rem!important
    }

    .me-xxl-n2 {
        margin-right: -0.5rem!important
    }

    .me-xxl-n3 {
        margin-right: -1rem!important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem!important
    }

    .me-xxl-n5 {
        margin-right: -3rem!important
    }

    .me-xxl-n6 {
        margin-right: -12px!important
    }

    .me-xxl-n7 {
        margin-right: -30px!important
    }

    .me-xxl-n8 {
        margin-right: -10px!important
    }

    .me-xxl-n9 {
        margin-right: -20px!important
    }

    .mb-xxl-n1 {
        margin-bottom: -0.25rem!important
    }

    .mb-xxl-n2 {
        margin-bottom: -0.5rem!important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem!important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-xxl-n5 {
        margin-bottom: -3rem!important
    }

    .mb-xxl-n6 {
        margin-bottom: -12px!important
    }

    .mb-xxl-n7 {
        margin-bottom: -30px!important
    }

    .mb-xxl-n8 {
        margin-bottom: -10px!important
    }

    .mb-xxl-n9 {
        margin-bottom: -20px!important
    }

    .ms-xxl-n1 {
        margin-left: -0.25rem!important
    }

    .ms-xxl-n2 {
        margin-left: -0.5rem!important
    }

    .ms-xxl-n3 {
        margin-left: -1rem!important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem!important
    }

    .ms-xxl-n5 {
        margin-left: -3rem!important
    }

    .ms-xxl-n6 {
        margin-left: -12px!important
    }

    .ms-xxl-n7 {
        margin-left: -30px!important
    }

    .ms-xxl-n8 {
        margin-left: -10px!important
    }

    .ms-xxl-n9 {
        margin-left: -20px!important
    }

    .p-xxl-0 {
        padding: 0!important
    }

    .p-xxl-1 {
        padding: 0.25rem!important
    }

    .p-xxl-2 {
        padding: 0.5rem!important
    }

    .p-xxl-3 {
        padding: 1rem!important
    }

    .p-xxl-4 {
        padding: 1.5rem!important
    }

    .p-xxl-5 {
        padding: 3rem!important
    }

    .p-xxl-6 {
        padding: 12px!important
    }

    .p-xxl-7 {
        padding: 30px!important
    }

    .p-xxl-8 {
        padding: 10px!important
    }

    .p-xxl-9 {
        padding: 20px!important
    }

    .px-xxl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xxl-1 {
        padding-right: 0.25rem!important;
        padding-left: 0.25rem!important
    }

    .px-xxl-2 {
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important
    }

    .px-xxl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xxl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xxl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-xxl-6 {
        padding-right: 12px!important;
        padding-left: 12px!important
    }

    .px-xxl-7 {
        padding-right: 30px!important;
        padding-left: 30px!important
    }

    .px-xxl-8 {
        padding-right: 10px!important;
        padding-left: 10px!important
    }

    .px-xxl-9 {
        padding-right: 20px!important;
        padding-left: 20px!important
    }

    .py-xxl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xxl-1 {
        padding-top: 0.25rem!important;
        padding-bottom: 0.25rem!important
    }

    .py-xxl-2 {
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important
    }

    .py-xxl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xxl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xxl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-xxl-6 {
        padding-top: 12px!important;
        padding-bottom: 12px!important
    }

    .py-xxl-7 {
        padding-top: 30px!important;
        padding-bottom: 30px!important
    }

    .py-xxl-8 {
        padding-top: 10px!important;
        padding-bottom: 10px!important
    }

    .py-xxl-9 {
        padding-top: 20px!important;
        padding-bottom: 20px!important
    }

    .pt-xxl-0 {
        padding-top: 0!important
    }

    .pt-xxl-1 {
        padding-top: 0.25rem!important
    }

    .pt-xxl-2 {
        padding-top: 0.5rem!important
    }

    .pt-xxl-3 {
        padding-top: 1rem!important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xxl-5 {
        padding-top: 3rem!important
    }

    .pt-xxl-6 {
        padding-top: 12px!important
    }

    .pt-xxl-7 {
        padding-top: 30px!important
    }

    .pt-xxl-8 {
        padding-top: 10px!important
    }

    .pt-xxl-9 {
        padding-top: 20px!important
    }

    .pe-xxl-0 {
        padding-right: 0!important
    }

    .pe-xxl-1 {
        padding-right: 0.25rem!important
    }

    .pe-xxl-2 {
        padding-right: 0.5rem!important
    }

    .pe-xxl-3 {
        padding-right: 1rem!important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xxl-5 {
        padding-right: 3rem!important
    }

    .pe-xxl-6 {
        padding-right: 12px!important
    }

    .pe-xxl-7 {
        padding-right: 30px!important
    }

    .pe-xxl-8 {
        padding-right: 10px!important
    }

    .pe-xxl-9 {
        padding-right: 20px!important
    }

    .pb-xxl-0 {
        padding-bottom: 0!important
    }

    .pb-xxl-1 {
        padding-bottom: 0.25rem!important
    }

    .pb-xxl-2 {
        padding-bottom: 0.5rem!important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem!important
    }

    .pb-xxl-6 {
        padding-bottom: 12px!important
    }

    .pb-xxl-7 {
        padding-bottom: 30px!important
    }

    .pb-xxl-8 {
        padding-bottom: 10px!important
    }

    .pb-xxl-9 {
        padding-bottom: 20px!important
    }

    .ps-xxl-0 {
        padding-left: 0!important
    }

    .ps-xxl-1 {
        padding-left: 0.25rem!important
    }

    .ps-xxl-2 {
        padding-left: 0.5rem!important
    }

    .ps-xxl-3 {
        padding-left: 1rem!important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xxl-5 {
        padding-left: 3rem!important
    }

    .ps-xxl-6 {
        padding-left: 12px!important
    }

    .ps-xxl-7 {
        padding-left: 30px!important
    }

    .ps-xxl-8 {
        padding-left: 10px!important
    }

    .ps-xxl-9 {
        padding-left: 20px!important
    }

    .gap-xxl-0 {
        gap: 0!important
    }

    .gap-xxl-1 {
        gap: 0.25rem!important
    }

    .gap-xxl-2 {
        gap: 0.5rem!important
    }

    .gap-xxl-3 {
        gap: 1rem!important
    }

    .gap-xxl-4 {
        gap: 1.5rem!important
    }

    .gap-xxl-5 {
        gap: 3rem!important
    }

    .gap-xxl-6 {
        gap: 12px!important
    }

    .gap-xxl-7 {
        gap: 30px!important
    }

    .gap-xxl-8 {
        gap: 10px!important
    }

    .gap-xxl-9 {
        gap: 20px!important
    }

    .row-gap-xxl-0 {
        row-gap: 0!important
    }

    .row-gap-xxl-1 {
        row-gap: 0.25rem!important
    }

    .row-gap-xxl-2 {
        row-gap: 0.5rem!important
    }

    .row-gap-xxl-3 {
        row-gap: 1rem!important
    }

    .row-gap-xxl-4 {
        row-gap: 1.5rem!important
    }

    .row-gap-xxl-5 {
        row-gap: 3rem!important
    }

    .row-gap-xxl-6 {
        row-gap: 12px!important
    }

    .row-gap-xxl-7 {
        row-gap: 30px!important
    }

    .row-gap-xxl-8 {
        row-gap: 10px!important
    }

    .row-gap-xxl-9 {
        row-gap: 20px!important
    }

    .column-gap-xxl-0 {
        -webkit-column-gap: 0!important;
        -moz-column-gap: 0!important;
        column-gap: 0!important
    }

    .column-gap-xxl-1 {
        -webkit-column-gap: 0.25rem!important;
        -moz-column-gap: 0.25rem!important;
        column-gap: 0.25rem!important
    }

    .column-gap-xxl-2 {
        -webkit-column-gap: 0.5rem!important;
        -moz-column-gap: 0.5rem!important;
        column-gap: 0.5rem!important
    }

    .column-gap-xxl-3 {
        -webkit-column-gap: 1rem!important;
        -moz-column-gap: 1rem!important;
        column-gap: 1rem!important
    }

    .column-gap-xxl-4 {
        -webkit-column-gap: 1.5rem!important;
        -moz-column-gap: 1.5rem!important;
        column-gap: 1.5rem!important
    }

    .column-gap-xxl-5 {
        -webkit-column-gap: 3rem!important;
        -moz-column-gap: 3rem!important;
        column-gap: 3rem!important
    }

    .column-gap-xxl-6 {
        -webkit-column-gap: 12px!important;
        -moz-column-gap: 12px!important;
        column-gap: 12px!important
    }

    .column-gap-xxl-7 {
        -webkit-column-gap: 30px!important;
        -moz-column-gap: 30px!important;
        column-gap: 30px!important
    }

    .column-gap-xxl-8 {
        -webkit-column-gap: 10px!important;
        -moz-column-gap: 10px!important;
        column-gap: 10px!important
    }

    .column-gap-xxl-9 {
        -webkit-column-gap: 20px!important;
        -moz-column-gap: 20px!important;
        column-gap: 20px!important
    }

    .text-xxl-start {
        text-align: left!important
    }

    .text-xxl-end {
        text-align: right!important
    }

    .text-xxl-center {
        text-align: center!important
    }
}

@media (min-width: 1200px) {
    .fs-8 {
        font-size:1.875rem!important
    }
}

@media print {
    .d-print-inline {
        display: inline!important
    }

    .d-print-inline-block {
        display: inline-block!important
    }

    .d-print-block {
        display: block!important
    }

    .d-print-grid {
        display: -ms-grid!important;
        display: grid!important
    }

    .d-print-table {
        display: table!important
    }

    .d-print-table-row {
        display: table-row!important
    }

    .d-print-table-cell {
        display: table-cell!important
    }

    .d-print-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-print-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-print-none {
        display: none!important
    }
}

.left-sidebar {
    width: 270px;
    background-color: #fff;
    position: absolute;
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    height: 100%;
    z-index: 11;
    border-right: 1px solid rgb(229,234,239)
}

.left-sidebar .scroll-sidebar {
    overflow-y: auto;
    padding: 0 24px;
    height: calc(100vh - 80px);
    border-radius: 7px
}

.left-sidebar .scroll-sidebar .simplebar-track.simplebar-horizontal {
    visibility: hidden!important
}

.brand-logo {
    min-height: 70px;
    padding: 0 24px
}

.unlimited-access {
    padding: 18px 22px 25px;
    margin: 0 -5px
}

.unlimited-access .unlimited-access-img {
    margin: -35px -5px 0 -43px
}

.nav-small-cap {
    margin-top: 24px;
    color: #2A3547;
    font-size: 12px;
    font-weight: 700;
    padding: 3px 12px;
    line-height: 26px;
    text-transform: uppercase
}

.nav-small-cap .nav-small-cap-icon {
    display: none
}

.sidebar-nav ul .sidebar-item .sidebar-link {
    color: #2A3547;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 25px;
    position: relative;
    margin: 0px 0px 2px;
    padding: 10px;
    border-radius: 7px;
    gap: 15px;
    font-weight: 400
}

.sidebar-nav ul .sidebar-item .sidebar-link span:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.sidebar-nav ul .sidebar-item .sidebar-link .ti {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 21px
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover {
    background-color: rgba(93,135,255,0.1);
    color: #5D87FF
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover.has-arrow:after {
    border-color: #5D87FF
}

.sidebar-nav ul .sidebar-item .sidebar-link.active:hover.has-arrow:after {
    border-color: #fff
}

.sidebar-nav ul .sidebar-item .link-disabled {
    opacity: 0.38
}

.sidebar-nav ul .sidebar-item.selected>.sidebar-link,.sidebar-nav ul .sidebar-item.selected>.sidebar-link.active,.sidebar-nav ul .sidebar-item>.sidebar-link.active {
    background-color: #5D87FF;
    color: #fff
}

.sidebar-nav .sidebar-list .sidebar-list-item {
    padding: 8px 0
}

.collapse.in {
    display: block
}

.app-header {
    position: relative;
    z-index: 50;
    width: 100%;
    background: #fff;
    padding: 0 25px
}

.app-header .container-fluid,.app-header .container-lg,.app-header .container-md,.app-header .container-sm,.app-header .container-xl,.app-header .container-xxl {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px
}

.app-header .navbar {
    min-height: 70px;
    padding: 0
}

.app-header .navbar .navbar-nav .nav-item .nav-link {
    padding: 8px 16px;
    line-height: 70px;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 20px;
    z-index: 2
}

.app-header .navbar .navbar-nav.quick-links .nav-item .nav-link {
    font-size: 0.875rem;
    position: relative;
    z-index: 2
}

.app-header .navbar .navbar-nav.quick-links .nav-item:hover .nav-link {
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    color: #5D87FF!important
}

.app-header .navbar .navbar-nav.quick-links .nav-item:hover .nav-link:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 36px;
    width: 100%;
    border-radius: 7px;
    background: #ECF2FF;
    z-index: -1
}

.nav-icon-hover {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.nav-icon-hover:hover:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    height: 40px;
    width: 40px;
    z-index: -1;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ECF2FF
}

.navbar-nav .dropdown-menu {
    position: absolute;
    min-width: 200px
}

.navbar-nav .dropdown-menu .dropdown-item {
    border-radius: 8px
}

.notification {
    content: "";
    position: absolute;
    top: 22px;
    right: 9px;
    width: 8px;
    height: 8px
}

@media (max-width: 991.98px) {
    .app-header .navbar {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap
    }

    .app-header .navbar .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

@media (max-width: 767.98px) {
    .navbar-nav .dropdown-menu {
        position:absolute;
        width: 100%
    }

    .navbar-nav .nav-item.dropdown {
        position: static
    }
}

@-webkit-keyframes animation-dropdown-menu-move-up-scroll {
    0% {
        top: 71px
    }

    to {
        top: 70px
    }
}

@keyframes animation-dropdown-menu-move-up-scroll {
    0% {
        top: 71px
    }

    to {
        top: 70px
    }
}

@-webkit-keyframes animation-dropdown-menu-fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes animation-dropdown-menu-fade-in {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.dropdown-menu-animate-up {
    -webkit-animation: animation-dropdown-menu-fade-in 0.5s ease 1,animation-dropdown-menu-move-up 0.5s ease-out 1;
    animation: animation-dropdown-menu-fade-in 0.5s ease 1,animation-dropdown-menu-move-up 0.5s ease-out 1
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@media (max-width: 991.98px) {
    .w-xs-100 {
        width:100%!important
    }
}

.page-wrapper {
    position: relative
}

.body-wrapper {
    position: relative;
}

.body-wrapper>.container-fluid,.body-wrapper>.container-lg,.body-wrapper>.container-md,.body-wrapper>.container-sm,.body-wrapper>.container-xl,.body-wrapper>.container-xxl {
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

@media (max-width: 767.98px) {
    .body-wrapper>.container-fluid,.body-wrapper>.container-lg,.body-wrapper>.container-md,.body-wrapper>.container-sm,.body-wrapper>.container-xl,.body-wrapper>.container-xxl {
        padding:30px 20px
    }
}

.simplebar-scrollbar:before {
    background: rgba(0,0,0,0.5)!important
}

#main-wrapper[data-layout=vertical][data-sidebar-position=fixed] .left-sidebar {
    position: fixed;
    top: 0
}

#main-wrapper[data-layout=vertical][data-header-position=fixed] .app-header {
    position: fixed;
    z-index: 10
}

#main-wrapper[data-layout=vertical][data-header-position=fixed] .body-wrapper>.container-fluid,#main-wrapper[data-layout=vertical][data-header-position=fixed] .body-wrapper>.container-lg,#main-wrapper[data-layout=vertical][data-header-position=fixed] .body-wrapper>.container-md,#main-wrapper[data-layout=vertical][data-header-position=fixed] .body-wrapper>.container-sm,#main-wrapper[data-layout=vertical][data-header-position=fixed] .body-wrapper>.container-xl,#main-wrapper[data-layout=vertical][data-header-position=fixed] .body-wrapper>.container-xxl {
    padding-top: calc(70px + 15px)
}

@media (min-width: 1200px) {
    #main-wrapper[data-layout=vertical][data-header-position=fixed][data-sidebartype=mini-sidebar] .app-header {
        width:100%
    }

    #main-wrapper[data-layout=vertical][data-header-position=fixed] .app-header {
        width: calc(100% - 270px)
    }

    #main-wrapper[data-layout=vertical][data-sidebartype=full] .body-wrapper {
        margin-left: 270px
    }
}

@media (max-width: 1199px) {
    #main-wrapper[data-layout=vertical][data-sidebartype=full] .left-sidebar,#main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar {
        left:-270px
    }

    #main-wrapper[data-layout=vertical][data-sidebartype=full].show-sidebar .left-sidebar,#main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar].show-sidebar .left-sidebar {
        left: 0
    }
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

a {
    text-decoration: none
}

a:hover {
    color: #5D87FF
}

ul {
    list-style: none;
    padding-left: 0
}

:focus {
    outline: 0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}

.cursor-pointer {
    cursor: pointer
}

.calendar-events {
    cursor: move
}

.object-fit-cover {
    -o-object-fit: cover;
    object-fit: cover
}

.card-title {
    font-size: 18px
}

.card-subtitle {
    font-size: 14px
}

.card {
    margin-bottom: 30px
}

.card-hover {
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in
}

.card-hover:hover {
    -webkit-transform: translate3d(0px,-5px,0px);
    transform: translate3d(0px,-5px,0px)
}

.round-8 {
    width: 8px;
    height: 8px
}

.round-20 {
    width: 20px!important;
    height: 20px!important
}

.radial-gradient {
    position: relative
}

.radial-gradient:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background: radial-gradient(rgb(210,241,223),rgb(211,215,250),rgb(186,216,244)) 0% 0%/400% 400%;
    -webkit-animation: 15s ease 0s infinite normal none running gradient;
    animation: 15s ease 0s infinite normal none running gradient
}

.timeline-widget .timeline-item {
    min-height: 70px
}

.timeline-widget .timeline-item .timeline-time {
    padding: 6px 16px 6px 0;
    min-width: 90px
}

.timeline-widget .timeline-item .timeline-badge-wrap .timeline-badge {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: transparent
}

.timeline-widget .timeline-item .timeline-badge-wrap .timeline-badge-border {
    width: 1px;
    height: 100%;
    background-color: #ebf1f6
}

.timeline-widget .timeline-item .timeline-desc {
    padding: 6px 16px
}

.timeline-widget .timeline-item .timeline-badge.badge-primary {
    border: 2px solid #5D87FF
}

/*Now the CSS*/
* {margin: 0; padding: 0;}

.tree ul {
	padding-top: 20px; position: relative;
	
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

.tree li {
	float: left; text-align: center;
	list-style-type: none;
	position: relative;
	padding: 20px 5px 0 5px;
	
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before, .tree li::after{
	content: '';
	position: absolute; top: 0; right: 50%;
	border-top: 1px solid #ccc;
	width: 50%; height: 20px;
}
.tree li::after{
	right: auto; left: 50%;
	border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after, .tree li:only-child::before {
	display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before, .tree li:last-child::after{
	border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before{
	border-right: 1px solid #ccc;
	border-radius: 0 5px 0 0;
	-webkit-border-radius: 0 5px 0 0;
	-moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after{
	border-radius: 5px 0 0 0;
	-webkit-border-radius: 5px 0 0 0;
	-moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before{
	content: '';
	position: absolute; top: 0; left: 50%;
	border-left: 1px solid #ccc;
	width: 0; height: 20px;
}

.tree li a{
	border: 1px solid #ccc;
	padding: 5px 10px;
	text-decoration: none;
	color: #666;
	font-family: arial, verdana, tahoma;
	font-size: 11px;
	display: inline-block;
	
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover, .tree li a:hover+ul li a {
	background: #c8e4f8; color: #000; border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.tree li a:hover+ul li::after, 
.tree li a:hover+ul li::before, 
.tree li a:hover+ul::before, 
.tree li a:hover+ul ul::before{
	border-color:  #94a0b4;
}

/*Thats all. I hope you enjoyed it.
Thanks :)*/
